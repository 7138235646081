import { LoginNewPassword } from "./NewPassword";
import { LoginDisplays } from "./Panel";
import { LoginSuccess } from "./Success";
import { useNavigate } from "react-router-dom";
import { requestForgotPassword } from "src/services/auth/operations";
import { LoginContext } from "src/shared/context/login";

import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

const TIMER_DURATION_IN_SECONDES = 15;
let interval: ReturnType<typeof setInterval> | undefined;

type Props = {
    formToDisplay: LoginDisplays;
    handleSetFormToDisplay: (switchName: LoginDisplays) => () => void;
};

export function LoginPasswordReset({ formToDisplay, handleSetFormToDisplay }: Readonly<Props>) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { identifier, setIdentifier } = useContext(LoginContext);

    const [seconds, setSeconds] = useState(TIMER_DURATION_IN_SECONDES);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | undefined>();
    const [isIdentifierButtonDisabled, setIsIdentifierButtonDisabled] = useState(false);

    useEffect(() => {
        setIsIdentifierButtonDisabled(!identifier);
    }, [identifier]);

    useEffect(() => {
        if (formToDisplay === "code" && seconds === TIMER_DURATION_IN_SECONDES) {
            interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds((seconds) => (seconds > 0 ? seconds - 1 : seconds));
                }
            }, 1000);
        }
    }, [formToDisplay, seconds]);

    useEffect(() => {
        return () => interval && clearInterval(interval);
    }, []);

    const handleSetIdentifier = (newValue: string) => {
        setIdentifier(newValue ? newValue : "");
    };

    const handlePasswordForgot = () => {
        if (!identifier) {
            setError(t("CommonUse.please-fill-field").toString());
        } else {
            setLoading(true);
            setError(undefined);
            requestForgotPassword({ identifier })
                .then(() => handleSetFormToDisplay("successSendMailNewPassword")())
                .catch((error) => error && setError(error.message))
                .finally(() => setLoading(false));
        }
    };

    const handleRedirectCancel = () => {
        handleSetFormToDisplay("login")();
        navigate("/");
    };

    return (
        <>
            {formToDisplay === "newPassword" ? (
                <LoginNewPassword redirectCancel={handleRedirectCancel} />
            ) : formToDisplay === "successSendMailNewPassword" ? (
                <LoginSuccess
                    title={"LoginPasswordReset.reinitialiser-votre-mot-de-passe"}
                    description={"LoginPasswordReset.mail-send"}
                    successAction={handleSetFormToDisplay("login")}
                    successLabel={"LoginPasswordReset.go-back-to-login"}
                />
            ) : formToDisplay === "forgot" ? (
                <div className='forgot-password-form'>
                    <img
                        src={require("src/shared/images/logo/julaya_pro.png")}
                        alt='julaya logo'
                        className='julaya-logo'
                    />
                    <div className='title-part'>
                        <Typography
                            variant='h1'
                            message={t("LoginPasswordReset.reinitialiser-votre-mot-de-passe")}
                            className='fw-bold'
                        />
                        <Typography
                            message={t("LoginPasswordReset.fill-id-to-receive-code")}
                            className='color-neutral-500 fw-bold'
                        />
                    </div>
                    <Input
                        placeholder={t("LoginPasswordReset.n-de-telephone-ou-identifiant")}
                        type='classic'
                        onChange={handleSetIdentifier}
                        value={identifier}
                        variant={error ? "error" : "primary"}
                        underMessage={error ? t<string>(error) : ""}
                        testId='forgot-password-identifier-input'
                    />
                    <div className='button-part'>
                        <Button
                            disabled={loading}
                            variant='tertiary'
                            className='w-50'
                            onClick={handleSetFormToDisplay("login")}
                            label={t("CommonUse.cancel")}
                        />
                        <Button
                            disabled={loading || isIdentifierButtonDisabled}
                            className='w-50'
                            onClick={handlePasswordForgot}
                            label={t("CommonUse.validate")}
                            testId='forgot-password-continue-button'
                        />
                    </div>
                </div>
            ) : null}
        </>
    );
}
