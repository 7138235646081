import { useTranslation } from "react-i18next";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Typography } from "src/shared/atoms/Typography/Typography";

const successImage = require("src/shared/images/user/success.png");
const failedImage = require("src/shared/images/user/error.png");
const julayaImage = require("src/shared/images/logo/julaya_pro.png");

type Props = {
    variant?: "success" | "error";
    title: string;
    description: string;
    successLabel: string;
    successAction: () => void;
};

export function LoginSuccess({ variant, title, description, successLabel, successAction }: Props) {
    const { t } = useTranslation();

    return (
        <div className='d-flex flex-column gap-3'>
            <img src={julayaImage} alt='julaya logo' className='julaya-logo' />
            <Typography variant={"h2"} message={t(title)} className='login-title-happy' />
            <Typography message={t(description)} className='color-neutral-500 fw-bold' />
            <div className='d-flex justify-content-center'>
                <img
                    className='success-login-image'
                    src={variant === "error" ? failedImage : successImage}
                    alt={variant === "error" ? "error" : "success"}
                />
            </div>
            <Button className='w-100' label={t(successLabel)} onClick={successAction} />
        </div>
    );
}
