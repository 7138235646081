import { useNavigate } from "react-router-dom";
import { parseCreateTaskFile } from "src/utils/xlsx/parseTask";
import * as XLSX from "xlsx";

import { ChangeEvent, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import { getTaskToEdit } from "src/modules/tasks/selectors";
import { fetchTaskToEdit } from "src/modules/tasks/thunkActions";
import { handleCreateTaskitems } from "src/modules/tasksItems/thunkActions";
import { SubscriptionRestrictions, useSubscriptionRestrictions } from "src/shared/hooks/useSubscriptionRestrictions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";

export const BulkTransfertFileForm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [disableForm, setDisableForm] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [freemiumError, setFreemiumError] = useState<boolean>(false);
    const isNbTransactionsRestricted = useSubscriptionRestrictions(SubscriptionRestrictions.LIMIT_15_TRANSACTION);

    const taskId = useAppSelector((state) => getTaskToEdit(state)?.id);

    const onFileLoaded = async (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        setError(null);
        setFreemiumError(false);

        if (selectedFile && taskId && !disableForm) {
            let reader = new FileReader();
            reader.onload = async function (e) {
                setIsLoading(true);
                let data = e?.target?.result;
                if (data) {
                    try {
                        if (isNbTransactionsRestricted) {
                            const readedData = XLSX.read(data, { type: "binary" });

                            const firstSheet = readedData.Sheets[readedData.SheetNames[0]];
                            const dataParseFirstSheet = XLSX.utils.sheet_to_json<string[]>(firstSheet, { header: 1 });
                            const nonEmptyRows = dataParseFirstSheet.filter((row) =>
                                row.some((cell) => cell !== null && cell !== undefined && cell !== "")
                            );

                            if (nonEmptyRows.length - 1 > 15) {
                                setFreemiumError(true);
                                setIsLoading(false);
                                return;
                            }
                        }

                        const fileData = parseCreateTaskFile(t, data);

                        if (typeof fileData === "string") {
                            alert(fileData);
                        } else if (fileData) {
                            navigate(`/tasks/${taskId}/edit`);
                            dispatch(handleCreateTaskitems({ taskId, taskItems: fileData }))
                                .unwrap()
                                .then(() => dispatch(fetchTaskToEdit({ taskId })))
                                .catch((error) => {
                                    setError(error.message);
                                });
                        } else {
                            if (fileData === null) {
                                setError("Tasks.empty-file");
                            }
                        }
                    } catch (e) {
                        setError("Tasks.file-is-not-standart-choose-another");
                        setDisableForm(true);
                    }
                    setIsLoading(false);
                }
            };
            reader.readAsBinaryString(selectedFile);
            event.target.value = "";
            setIsLoading(false);
        }
    };

    const handleClickInput = () => {
        fileInputRef.current?.click();
    };

    const handleDownloadModel = () => {
        const link = document.createElement("a");
        link.href = "/models/bulk_transfert_modele_julaya.xlsx";
        link.download = "bulk_transfert_modele_julaya.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className='bulk-transfert-file-form-container'>
            <div className='upload-text-part' onClick={handleClickInput}>
                <Typography message={t("BulkTransferPage.import-a-file")} className='fw-bold' size='sm' />
                <Typography message={t("BulkTransferPage.use-filled-file")} className='color-neutral-500' size='sm' />
            </div>
            <div className='upload-file-container' onClick={handleClickInput}>
                <div className='upload-file-content'>
                    <Avatar color='lilas-900' backgroundColor='lilas-50' icon='document' size='sm' />
                    <div className='download-sentence'>
                        <Typography
                            className='fw-bold color-lilas-900'
                            size='sm'
                            message={t("BulkTransferPage.choose-in-my-gallery")}
                        />
                        &nbsp;
                        <Typography
                            className='fw-bold mr-1'
                            size='sm'
                            message={t("FileUploader.ou-glissez-puis-deposez")}
                        />
                    </div>
                </div>
            </div>
            {error && (
                <div className='error-message'>
                    <Icon color='error' name='x-circle-filled' />
                    <Typography className='color-error-500' message={t(error)} />
                </div>
            )}
            {isNbTransactionsRestricted && (
                <div className='subscription-message'>
                    <Icon
                        name={freemiumError ? "x-circle-filled" : "exclamation-triangle-filled"}
                        color={freemiumError ? "error" : "warning"}
                    />
                    <Typography
                        className={freemiumError ? "color-error" : "color-warning"}
                        message={
                            freemiumError ? t("WalletSelector.free-plan-error") : t("WalletSelector.free-plan-warning")
                        }
                    />
                </div>
            )}
            <Alert
                icon='arrow-down-on-square'
                color='warning'
                message={t("BulkTransferPage.you-dont-have-model")}
                subMessage={t<string>("BulkTransferPage.download-a-model")}
                buttons={{
                    label: t("BulkTransferPage.download-model"),
                    color: "warning",
                    onClick: handleDownloadModel,
                }}
            />
            <input
                ref={fileInputRef}
                onChange={onFileLoaded}
                type='file'
                disabled={isLoading}
                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                className='file-input-display-none'
            />
        </div>
    );
};
