import classnames from "classnames";
import { CreateUserContext } from "src/shared/context/createUser";

import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAdminWalletsAsSelectOptions } from "src/modules/admin/adminWallets/selectors";
import { fetchAdminWallets } from "src/modules/admin/adminWallets/thunkActions";
import { getSelectedCompanyCountryCode, getSelectedCompanyCountryTag } from "src/modules/auth/selectors";
import { useAppDispatch, useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { InputPhone, InputPhoneValue } from "src/shared/atoms/Inputs/Phone/Phone";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { SelectMultipleOptions, SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";
import { SimpleSelect } from "src/shared/components/SimpleSelect/SimpleSelect";

export const userCollectorPartners: { [key: string]: SelectOption[] } = {
    CIV: [
        { id: "momo-deposit-collect-wave", label: "Wave" },
        { id: "momo-deposit-collect-ecobank", label: "Xpress" },
        { id: "momo-deposit-collect-yelemoney", label: "Yélé Money" },
    ],
    SEN: [
        { id: "momo-deposit-collect-wave", label: "Wave" },
        { id: "momo-deposit-collect-ecobank", label: "Xpress" },
        { id: "momo-deposit-collect-mixx", label: "Mixx by Yas" },
    ],
    BEN: [{ id: "momo-deposit-collect-mtn", label: "MTN" }],
};

export const AdminUserCreateDrawerSectionsCollector = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { userValues, setUserValues, selectedPartners, setSelectedPartners } = useContext(CreateUserContext);
    const walletOptions = useAppSelector((state) => getAdminWalletsAsSelectOptions(state));

    const [selectedWallet, setSelectedWallet] = useState<SelectOption | undefined>();
    const [customPhone, setCustomPhone] = useState<InputPhoneValue>();
    const [partnerSelectionError, setPartnerSelectionError] = useState<string | null>(null);
    const companyCountryTag = useAppSelector((state) => getSelectedCompanyCountryTag(state));
    const companyCountryCode = useAppSelector((state) => getSelectedCompanyCountryCode(state));

    useEffect(() => {
        dispatch(
            fetchAdminWallets({
                page: -1,
                archived: false,
            })
        );
    }, [dispatch]);

    const handleChangePartnerSelection = (partners: SelectOption[]) => {
        setSelectedPartners(partners);
    };

    useEffect(() => {
        setPartnerSelectionError(selectedPartners.length > 0 ? null : t("Users.choose-at-least-one-partner"));
    }, [selectedPartners]);

    const handleSelectWallet = (wallet: SelectOption) => {
        setSelectedWallet(wallet);
        setUserValues({ ...userValues, waveWalletId: wallet.id });
    };

    const handleChangePhone = (newPhone: InputPhoneValue) => {
        setCustomPhone(newPhone);
        setUserValues({ ...userValues, waveCountryCode: newPhone.prefix, customWavePhone: newPhone.value });
    };

    const isComplete =
        userValues?.waveCountryCode && (userValues.customWavePhone ?? "")?.trim().length > 0 && userValues.waveWalletId;

    return (
        <div className='drawer-content-section'>
            <div className='drawer-content-section-header'>
                <Typography className='fw-bold' message={t("Users.mobile-money")} />
                <Typography
                    message={isComplete ? t("CommonUse.done") : t("CommonUse.to-complete")}
                    className={classnames("fw-bold", isComplete ? "color-success" : "color-warning-500")}
                />
            </div>
            <div className='drawer-content-section-body'>
                <div className='user-drawer-roles-form'>
                    <div className='collector-role-form'>
                        <div className='wave-text'>
                            <Avatar icon='question-mark-circle' backgroundColor='lilas-50' />
                            <Typography message={t("Roles.collector-access")} />
                        </div>
                        <SelectMultipleOptions
                            color='grey'
                            label={t<string>("Users.choose-partner")}
                            onSelectionChange={handleChangePartnerSelection}
                            showSelectedItems={true}
                            selected={selectedPartners}
                            items={companyCountryTag ? userCollectorPartners[companyCountryTag] : []}
                            name={t<string>("Users.choose-partner-placeholder")}
                            underMessage={partnerSelectionError ?? undefined}
                            variant={partnerSelectionError ? "error" : "primary"}
                        />
                        <InputPhone
                            label={t<string>("CommonUse.phone-number")}
                            placeholder={t("Users.enter-phone-number")}
                            onChange={handleChangePhone}
                            prefix={companyCountryCode}
                            value={customPhone?.value}
                        />
                        <Alert
                            color='warning'
                            icon='exclamation-triangle'
                            message={t<string>("Users.choose-collector-phone-warning")}
                        />
                        <SimpleSelect
                            color='grey'
                            useRadio
                            placeholder={t<string>("CommonUse.select-account")}
                            label={t<string>("TopUp.mobile-money-wallet-deposit")}
                            options={walletOptions}
                            selected={selectedWallet}
                            onSelectionChange={handleSelectWallet}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
