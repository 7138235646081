import { ExternalAccountForm, createBeneficiaryExternalAccounts } from "../utils";
import { default as classNames, default as classnames } from "classnames";
import { transactionTypesMap } from "src/shared/const/transactions";
import { isValidIban } from "src/shared/utils/isValidIban";
import { isValidPhone } from "src/shared/utils/isValidPhone";
import { v4 as uuidv4 } from "uuid";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";
import { SimpleSelect } from "src/shared/components/SimpleSelect/SimpleSelect";

import "./styles.scss";

type CreateBeneficiaryAddFormProps = {
    disabled: boolean;
    isFormValidated: boolean;
    setExternalAccounts: (value: ExternalAccountForm[]) => void;
    setIsFormValidated: (value: boolean) => void;
    externalAccounts: ExternalAccountForm[];
};

export const CreateBeneficiaryAddForm = ({
    disabled,
    isFormValidated,
    setIsFormValidated,
    setExternalAccounts,
    externalAccounts,
}: CreateBeneficiaryAddFormProps) => {
    const { t } = useTranslation();

    const [showAddAccountForm, setShowAddAccountForm] = useState(false);
    const [editingAccountIndex, setEditingAccountIndex] = useState<number | null>(null);

    const [externalReference, setExternalReference] = useState("");
    const [typeSlug, setTypeSlug] = useState("");
    const beneficiaryExternalAccounts = createBeneficiaryExternalAccounts();

    const selectedTypeSlugOption = beneficiaryExternalAccounts.find((option) => option.id === typeSlug);
    const isPhone = selectedTypeSlugOption && transactionTypesMap[selectedTypeSlugOption.id]?.phoneFormat;

    useEffect(() => {
        setIsFormValidated(externalAccounts.length > 0);
    }, [externalAccounts]);

    const handleOpenPaymentMethodForm = () => {
        setShowAddAccountForm(!showAddAccountForm);
        setExternalReference("");
        setTypeSlug("");
        setEditingAccountIndex(null);
    };

    const handleCloseEditForm = () => {
        setEditingAccountIndex(null);
        setExternalReference("");
        setTypeSlug("");
    };

    const handleAddAccount = () => {
        const sanitizedExternalReference = externalReference.replace(/\s/g, "");

        const params = {
            externalReference: sanitizedExternalReference,
            typeSlug: typeSlug,
            uuid: uuidv4(),
        };

        if (editingAccountIndex !== null) {
            const updatedExternalAccounts = [...externalAccounts];
            updatedExternalAccounts[editingAccountIndex] = params;
            setExternalAccounts(updatedExternalAccounts);
        } else {
            setExternalAccounts([...externalAccounts, params]);
        }

        setEditingAccountIndex(null);
        handleOpenPaymentMethodForm();
        handleCloseEditForm();
    };

    const handleDeleteAccount = (index: number) => () => {
        const newExternalAccounts = [...externalAccounts];
        newExternalAccounts.splice(index, 1);
        setExternalAccounts(newExternalAccounts);
    };

    const handleEditAccount = (index: number) => () => {
        const account = externalAccounts[index];
        setExternalReference(account.externalReference);
        setTypeSlug(account.typeSlug);
        setEditingAccountIndex(index);
    };

    const renderBeneficiaryExternalAccountsName = (typeSlug: string) => {
        const account = beneficiaryExternalAccounts.find((account) => account.id === typeSlug);
        return account ? account.label : "";
    };

    const handleChangeExternalReference = (value: string) => {
        setExternalReference(value);
    };

    const handleChangeTypeSlug = (option: SelectOption) => {
        setExternalReference("");
        setTypeSlug(option.id);
    };

    const checkExternalAccountError = (account: ExternalAccountForm) => {
        if (account.typeSlug === "bank-transfer") {
            return !isValidIban(account.externalReference);
        }
        if (
            account.typeSlug === "wave-transfer" ||
            account.typeSlug === "cb-transfer" ||
            account.typeSlug === "disposal"
        ) {
            return !isValidPhone(account.externalReference);
        }

        return !account.externalReference;
    };

    return (
        <div className={classnames("drawer-content-section", { disabled })}>
            <div className='drawer-content-section-header'>
                <Typography className='fw-bold' message={t("CreateBeneficiaryAddForm.moyens-de-paiements")} />
                <Typography
                    className={`${!isFormValidated ? "color-warning" : "color-success"} fw-bold`}
                    message={!isFormValidated ? t("CommonUse.to-complete") : t("CommonUse.done")}
                />
            </div>

            <div className='beneficiary-informations-content drawer-content-section-body'>
                {!showAddAccountForm && externalAccounts.length === 0 && (
                    <div className='no-account-added'>
                        <Typography
                            className='fw-bold'
                            message={t("CreateBeneficiaryAddForm.aucun-compte-enregistre")}
                        />
                        <Typography
                            message={t(
                                "CreateBeneficiaryAddForm.renseignez-des-comptes-pour-payer-plus-rapidement-un-beneficiaire"
                            )}
                        />
                    </div>
                )}
                {externalAccounts.length > 0 && (
                    <div className='account-list'>
                        {externalAccounts.map((account, index) =>
                            editingAccountIndex === index ? (
                                <div className='add-account-form' key={account.uuid}>
                                    <div className='form-part'>
                                        <SimpleSelect
                                            useRadio
                                            headerClassname='beneficiary-service-select'
                                            image={selectedTypeSlugOption?.image}
                                            icon={selectedTypeSlugOption?.icon?.name}
                                            iconProps={selectedTypeSlugOption?.icon}
                                            selected={selectedTypeSlugOption}
                                            onSelectionChange={handleChangeTypeSlug}
                                            options={beneficiaryExternalAccounts}
                                            placeholder={t<string>("CreateBeneficiaryAddForm.choix-du-service")}
                                        />
                                        <Input
                                            label={
                                                isPhone
                                                    ? t("CommonUse.phone-number").toString()
                                                    : t("CommonUse.reference").toString()
                                            }
                                            placeholder={
                                                isPhone
                                                    ? t("Users.enter-phone-number").toString()
                                                    : "Ex : CIXX XXXX XXXX XXXX"
                                            }
                                            onChange={handleChangeExternalReference}
                                            value={externalReference}
                                            allowedChar={isPhone ? /^[0-9 ]*$/ : /^[a-zA-Z0-9 ]*$/}
                                            underMessage={
                                                selectedTypeSlugOption?.id === "bank-transfer"
                                                    ? t<string>("CommonUse.do-not-overpass-char")
                                                    : undefined
                                            }
                                            maxLength={selectedTypeSlugOption?.id === "bank-transfer" ? 30 : undefined}
                                        />
                                    </div>
                                    <div className='buttons-part'>
                                        <Button
                                            label={t("CommonUse.cancel")}
                                            variant='tertiary'
                                            className='flex-grow-1'
                                            onClick={handleCloseEditForm}
                                        />
                                        <Button
                                            icon='pencil'
                                            className='flex-grow-1'
                                            label={t("CommonUse.modify")}
                                            disabled={selectedTypeSlugOption === null || externalReference === ""}
                                            onClick={handleAddAccount}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className={classNames("account-item", {
                                        error: checkExternalAccountError(account),
                                    })}
                                    key={index}
                                >
                                    <div className='account-item-content'>
                                        {transactionTypesMap[account.typeSlug].type === "image" ? (
                                            <Avatar
                                                key={account.uuid}
                                                avatar={transactionTypesMap[account.typeSlug].img}
                                            />
                                        ) : (
                                            <Icon
                                                variant='squared'
                                                color='lilas'
                                                name={transactionTypesMap[account.typeSlug].img as IconListType}
                                            />
                                        )}
                                        <div className='text-part'>
                                            <Typography
                                                className='fw-bold'
                                                message={renderBeneficiaryExternalAccountsName(account.typeSlug)}
                                            />
                                            <Typography message={account.externalReference} />
                                        </div>
                                    </div>
                                    <div className='account-item-actions'>
                                        <Button
                                            variant='tertiary'
                                            icon='pencil-square'
                                            onClick={handleEditAccount(index)}
                                        />
                                        <Button variant='tertiary' icon='thrash' onClick={handleDeleteAccount(index)} />
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                )}
                {showAddAccountForm && editingAccountIndex === null && (
                    <div className='add-account-form'>
                        <div className='form-part'>
                            <SimpleSelect
                                useRadio
                                headerClassname='beneficiary-service-select'
                                image={selectedTypeSlugOption?.image}
                                icon={selectedTypeSlugOption?.icon?.name}
                                iconProps={selectedTypeSlugOption?.icon}
                                selected={selectedTypeSlugOption}
                                onSelectionChange={handleChangeTypeSlug}
                                options={beneficiaryExternalAccounts}
                                placeholder={t<string>("CreateBeneficiaryAddForm.choix-du-service")}
                            />
                            <Input
                                label={
                                    isPhone
                                        ? t("CommonUse.phone-number").toString()
                                        : t("CommonUse.reference").toString()
                                }
                                placeholder={
                                    isPhone ? t("Users.enter-phone-number").toString() : "Ex: CIXX XXXX XXXX XXXX"
                                }
                                onChange={handleChangeExternalReference}
                                value={externalReference}
                                allowedChar={isPhone ? /^[0-9 ]*$/ : /^[a-zA-Z0-9 ]*$/}
                                underMessage={
                                    selectedTypeSlugOption?.id === "bank-transfer"
                                        ? t<string>("CommonUse.do-not-overpass-char")
                                        : undefined
                                }
                                maxLength={selectedTypeSlugOption?.id === "bank-transfer" ? 30 : undefined}
                            />
                        </div>
                        <div className='buttons-part'>
                            <Button
                                label={t("CommonUse.cancel")}
                                variant='tertiary'
                                className='flex-grow-1'
                                onClick={handleOpenPaymentMethodForm}
                            />
                            <Button
                                icon='plus-circle'
                                className='flex-grow-1'
                                label={t("CreateBeneficiaryAddForm.ajouter")}
                                disabled={selectedTypeSlugOption === null || externalReference === ""}
                                onClick={handleAddAccount}
                            />
                        </div>
                    </div>
                )}
                {!showAddAccountForm && (
                    <div className='add-account-button'>
                        <Button
                            icon='plus-circle'
                            label={t("CreateBeneficiaryAddForm.ajouter-un-compte")}
                            onClick={handleOpenPaymentMethodForm}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
