import { LoginDisplays } from "./Panel";
import { LoginContext } from "src/shared/context/login";

import { Dispatch, SetStateAction, useContext, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUserError, getAuthUserLoading } from "src/modules/auth/selectors";
import { useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Input } from "src/shared/atoms/Inputs/Input";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";

type Props = {
    twoFactor: string;
    setTwoFactor: Dispatch<SetStateAction<string>>;
    handleSetFormToDisplay: (value: LoginDisplays) => () => void;
    handleConnection: () => void;
};

export function LoginTwoFactor({ twoFactor, setTwoFactor, handleSetFormToDisplay, handleConnection }: Readonly<Props>) {
    const { t } = useTranslation();
    const { setTfaCode } = useContext(LoginContext);
    const loading = useAppSelector((state) => getAuthUserLoading(state));
    const error = useAppSelector((state) => getAuthUserError(state) ?? "");

    const twoFactorAccepted = useMemo(() => {
        return twoFactor.match(new RegExp("^[0-9]{6}$"));
    }, [twoFactor]);

    const handleSetTwoFactor = (newValue: string) => {
        setTwoFactor(newValue ?? "");
        setTfaCode(newValue ?? "");
    };

    const handleCancel = () => {
        setTwoFactor("");
        handleSetFormToDisplay("login")();
    };

    return (
        <>
            <Typography
                variant={"h2"}
                message={t("LoginPanel.two-factor-authentication-title")}
                className='login-title-happy'
            />
            <div className='login-code-validation-container'>
                <Typography
                    message={t("LoginPanel.two-factor-authentication-description")}
                    className='login-subtitle'
                />
                <Input
                    placeholder={t("LoginPanel.temporary-code")}
                    label={t<string>("PasswordModal.auth-code")}
                    underMessage={t<string>(error)}
                    variant={error ? "error" : "primary"}
                    value={twoFactor}
                    onChange={handleSetTwoFactor}
                />
                <ToolTip
                    activation
                    content={
                        <div className='code-validation-tooltip'>
                            <Typography
                                className='fw-bold'
                                message={t("LoginPanel.trouble-to-use-two-factor-description")}
                            />
                        </div>
                    }
                >
                    <div className='d-flex align-items-center'>
                        <Icon size='sm' name='information-circle' color='lilas' />
                        <Typography
                            className='ml-2 color-lilas-900'
                            message={t("LoginPanel.two-factor-authentication-helper")}
                        />
                    </div>
                </ToolTip>
                <div className='d-flex mt-2'>
                    <Button
                        onClick={handleCancel}
                        label={t("CommonUse.cancel")}
                        variant='tertiary'
                        className='w-50 mr-2'
                    />
                    <Button
                        loading={loading}
                        disabled={loading || !twoFactorAccepted}
                        onClick={handleConnection}
                        className='login-button w-50'
                        label={t("CommonUse.validate")}
                    />
                </div>
            </div>
        </>
    );
}
