import { EditTaskInputCell } from "../InputCell";
import { CreateTaskItemContext } from "src/shared/context/createTaskItem";

import { useContext } from "react";

import { Typography } from "src/shared/atoms/Typography/Typography";

type Props = {
    uuid: string;
    taskId?: number;
};

export function TasksItemCreateFieldExternalName({ uuid, taskId }: Props) {
    const { loading, taskItems, setTaskItems } = useContext(CreateTaskItemContext);

    const externalReference = taskItems?.[uuid]?.externalReference;
    const externalFullname = taskItems?.[uuid]?.externalFullname;

    const handleUpdateExternalFullname = (newExternalFullname: string) => {
        if (taskId && taskItems && newExternalFullname !== taskItems?.[uuid]?.externalFullname) {
            setTaskItems({ ...taskItems, [uuid]: { ...taskItems[uuid], externalFullname: newExternalFullname } });
        }
    };

    return (
        <EditTaskInputCell
            onValidate={handleUpdateExternalFullname}
            editOpen={!!externalReference && !externalFullname}
            initialValue={taskItems?.[uuid]?.externalFullname ?? ""}
            disabled={loading}
            externalReference={{
                isExternalReference: true,
                isBankWire: taskItems?.[uuid]?.typeSlug === "bank-transfer",
            }}
        >
            <Typography className='create-task-table-cell-content' message={taskItems?.[uuid]?.externalFullname} />
        </EditTaskInputCell>
    );
}
