import { logoutResetStore } from "./Actions";
import {
    RequestGenerateAuthUserQrCodeReturn,
    RequestUpdateAuthUserPhotoReturn,
    RequestUpdateAuthUserReturn,
} from "src/services/auth/operations";
import { AuthUserExpensePolicy, UserAuthApiReturn } from "src/services/auth/types";
import { UserModel } from "src/shared/models/User";
import { setInStorageUserPreferences } from "src/shared/utils/storage";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type AuthPreferences = {
    selectedCompanyId: number | null;
    preferredCompanyId: number | null;
};

export interface AuthState {
    user?: UserAuthApiReturn;
    qrCode?: RequestGenerateAuthUserQrCodeReturn;
    loading: boolean;
    error: string | null;
    preferences: AuthPreferences;
    expensePolicies?: AuthUserExpensePolicy[];
    useBaseRouter: boolean;
}

const initialState = {
    preferences: {
        preferredCompanyId: null,
        selectedCompanyId: null,
    },
} as AuthState;

export const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(logoutResetStore, () => {
            return initialState;
        });
    },
    reducers: {
        requestLogin: (state) => {
            state.loading = true;
            state.error = "";
        },
        successLogin: (state) => {
            state.loading = false;
        },
        requestUserInfo: (state) => {
            state.loading = true;
            state.error = "";
        },
        requestUpdateCollectorSettings: (state) => {
            state.loading = true;
            state.error = "";
        },
        updateCollectorSettings: (state, { payload }: PayloadAction<UserModel["collectorInfo"]>) => {
            state.loading = false;
            if (state.user) {
                state.user.collectorInfo = payload;
            }
        },
        failedUpdateCollectorSettings: (state, action: PayloadAction<{ error: string }>) => {
            const {
                payload: { error },
            } = action;

            state.loading = false;
            state.error = error;
        },
        addAuthUser: (state, action: PayloadAction<{ user: UserAuthApiReturn }>) => {
            const {
                payload: { user },
            } = action;

            state.user = user;
            state.loading = false;
            state.error = "";
        },
        updateAuthUserInformation: (
            state,
            action: PayloadAction<RequestUpdateAuthUserReturn & { isEmailVerified?: boolean }>
        ) => {
            const { payload } = action;

            if (state.user) {
                state.user = {
                    ...state.user,
                    ...payload,
                };
            }
        },
        updateAuthUserPhoto: (state, action: PayloadAction<RequestUpdateAuthUserPhotoReturn>) => {
            const { payload } = action;

            if (state.user) {
                state.user = {
                    ...state.user,
                    ...payload,
                };
            }
        },
        updateAuthUserTwoFactor: (state, action: PayloadAction<{ tfaActive: boolean }>) => {
            const { payload } = action;

            if (state.user) {
                state.user = {
                    ...state.user,
                    ...payload,
                };
            }
        },
        setAuthUserGeneratedQrCode: (state, action: PayloadAction<RequestGenerateAuthUserQrCodeReturn>) => {
            const { payload } = action;

            state.qrCode = payload;
        },
        failedLogin: (state, action: PayloadAction<{ error: string; remainingConnection?: number }>) => {
            const {
                payload: { error },
            } = action;

            state.loading = false;
            state.error = error;
        },
        setAuthUserPreferenceSelectedCompanyId: (state, action: PayloadAction<{ companyId: number | null }>) => {
            const {
                payload: { companyId },
            } = action;

            state.preferences.selectedCompanyId = companyId;
        },
        setAuthUserPreferencePreferredCompanyId: (state, action: PayloadAction<{ companyId: number | null }>) => {
            const {
                payload: { companyId },
            } = action;

            state.preferences.preferredCompanyId = companyId;
        },
        setAuthUserExpensePolicies: (state, { payload }: PayloadAction<AuthUserExpensePolicy[]>) => {
            state.expensePolicies = payload;
        },
        setUseBaseRouter: (state, { payload }: PayloadAction<AuthState["useBaseRouter"]>) => {
            setInStorageUserPreferences("useBaseRouter", payload);
            state.useBaseRouter = payload;
        },
    },
});

export const {
    requestLogin,
    successLogin,
    requestUserInfo,
    requestUpdateCollectorSettings,
    updateCollectorSettings,
    failedUpdateCollectorSettings,
    addAuthUser,
    updateAuthUserInformation,
    updateAuthUserPhoto,
    updateAuthUserTwoFactor,
    setAuthUserGeneratedQrCode,
    failedLogin,
    setAuthUserPreferenceSelectedCompanyId,
    setAuthUserPreferencePreferredCompanyId,
    setAuthUserExpensePolicies,
    setUseBaseRouter,
} = authSlice.actions;

export const authSliceReducer = authSlice.reducer;
