import moment from "moment";
import { Link } from "react-router-dom";
import { TransactionApi } from "src/services/transactions/types";
import { LoanModel } from "src/shared/models/Loan";
import { CompanyRole } from "src/shared/models/UserCompany";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useTranslation } from "react-i18next";

import { useIsUserAuthorized } from "src/shared/hooks/useIsUserAuthorized";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Accordion } from "src/shared/components/Accordion/Accordion";
import { ArcGauge } from "src/shared/components/ArcGauge/ArcGauge";

import "./styles.scss";

type LoanDetailsDrawerTimeTableProps = {
    currentLoan: LoanModel;
    historyTransactions?: TransactionApi[];
};
export const LoanDetailsDrawerTimeTable = ({ currentLoan, historyTransactions }: LoanDetailsDrawerTimeTableProps) => {
    const { t } = useTranslation();

    const currentDate = moment();
    const createdAtDate = moment(currentLoan.createdAt);
    const diffInDays = currentDate.diff(createdAtDate, "days");

    const isUserUnauthorized = useIsUserAuthorized({ allowedRoles: [CompanyRole.ADMIN_ONLY] });

    return (
        <div className='timetable-container'>
            <div className='timetable-header'>
                <div className='left-part'>
                    <Avatar icon='calendar' />
                    <Typography className='fw-bold' message={t("Loans.schedule")} />
                </div>
                {isUserUnauthorized ? (
                    <Typography className='fw-bold payment-button disabled' message={t("Transactions.pay")} />
                ) : (
                    <Link to={`/transfert/loan/${currentLoan.id}`}>
                        <Typography className='fw-bold payment-button' message={t("Transactions.pay")} />
                    </Link>
                )}
            </div>
            <div className='timetable-content'>
                <div className='gauge-content'>
                    <div className='loan-gauge-section'>
                        <ArcGauge
                            value={diffInDays}
                            inactiveGray={false}
                            width={200}
                            height={100}
                            rangeValues={[20, 30, 60]}
                            dashedLine={true}
                            message={
                                <div className='loan-gauge-text'>
                                    {diffInDays === 0 && (
                                        <Typography message={t("CommonUse.first-day")} className='fw-bold' />
                                    )}
                                    {diffInDays > 0 && (
                                        <Typography
                                            message={`${diffInDays} ${t("CommonUse.day")}${diffInDays > 1 ? "s" : ""}`}
                                            className='fw-bold'
                                        />
                                    )}
                                    <Typography message={t("Loans.time-since-start")} size='xs' />
                                </div>
                            }
                        />
                    </div>
                    <div className='loan-rest-to-pay-section'>
                        <Typography message={t("Loans.left-to-pay")} />
                        <Typography
                            variant='h5'
                            className='fw-bold mt-0'
                            message={`${formatMoneyToString({
                                amount: currentLoan.amountToRepay - currentLoan.amountRepaid,
                            })}`}
                        />
                        <div className='loan-rest-to-pay-interests'>
                            <Icon name='bills' color='lilas-900' />
                            <Typography
                                message={`${formatMoneyToString({ amount: currentLoan.amountToRepay })}`}
                                className='color-lilas-900'
                            />
                        </div>
                    </div>
                </div>

                <Accordion title={t("Loans.why-reimburse-fast")} icon='bulb'>
                    <div className='fast-repayment-item success'>
                        <div className='gauge-part'>
                            <ArcGauge
                                value={20}
                                width={100}
                                rangeValues={[20, 30, 60]}
                                height={50}
                                message={
                                    <div className='loan-gauge-text'>
                                        <Typography message={t("Loans.first-period")} className='fw-bold' />
                                    </div>
                                }
                            />
                        </div>
                        <div className='text-part'>
                            <Typography message={t("Loans.pay-before-first-period")} className='fw-bold' />
                            <Typography
                                message={`${t("Loans.before")} ${moment(currentLoan.createdAt)
                                    .add(20, "days")
                                    .format("DD/MM/YYYY")}`}
                            />
                            <Typography className='mt-2' message={t("Loans.pay-before-first-period-description")} />
                        </div>
                    </div>
                    <div className='fast-repayment-item warning'>
                        <div className='gauge-part'>
                            <ArcGauge
                                value={30}
                                width={100}
                                rangeValues={[20, 30, 60]}
                                height={50}
                                message={
                                    <div className='loan-gauge-text'>
                                        <Typography message={t("Loans.second-period")} className='fw-bold' />
                                    </div>
                                }
                            />
                        </div>
                        <div className='text-part'>
                            <Typography message={t("Loans.pay-before-second-period")} className='fw-bold' />
                            <Typography
                                message={`${t("Loans.before")} ${moment(currentLoan.createdAt)
                                    .add(30, "days")
                                    .format("DD/MM/YYYY")}`}
                            />
                            <Typography className='mt-2' message={t("Loans.pay-before-second-period-description")} />
                        </div>
                    </div>
                    <div className='fast-repayment-item error'>
                        <div className='gauge-part'>
                            <ArcGauge
                                value={60}
                                width={100}
                                rangeValues={[20, 30, 60]}
                                height={50}
                                message={
                                    <div className='loan-gauge-text'>
                                        <Typography message={t("Loans.third-period")} className='fw-bold' />
                                    </div>
                                }
                            />
                        </div>
                        <div className='text-part'>
                            <Typography message={t("Loans.pay-before-third-period")} className='fw-bold' />
                            <Typography
                                message={`${t("Loans.before")} ${moment(currentLoan.createdAt)
                                    .add(60, "days")
                                    .format("DD/MM/YYYY")}`}
                            />
                            <Typography className='mt-2' message={t("Loans.pay-before-third-period-description")} />
                        </div>
                    </div>
                </Accordion>

                <div className='payment-history-section'>
                    <Accordion
                        title={t("Loans.payment-history")}
                        icon='list-bullet'
                        preSelectedOpen={false}
                        disabled={historyTransactions?.length === 0}
                    >
                        {historyTransactions &&
                            historyTransactions.map((item, index) => {
                                return (
                                    <div key={index} className='payment-history-item'>
                                        <div className='left-content'>
                                            <Avatar icon='bills' backgroundColor='lilas-50' />
                                            <div className='payment-history-amount-date'>
                                                <Typography
                                                    message={formatMoneyToString({ amount: item.amount })}
                                                    className='fw-bold'
                                                />
                                                <Typography message={moment(item.createdAt).format("DD/MM/YYYY")} />
                                            </div>
                                        </div>
                                        <Typography message={item.signatures ? item?.signatures[0]?.fullname : ""} />
                                    </div>
                                );
                            })}
                    </Accordion>
                </div>
            </div>
        </div>
    );
};
