import { LoginDisplays } from "../Panel";
import { useNavigate } from "react-router-dom";
import { requestVerifyEmail } from "src/services/auth/operations";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

const success = require("src/shared/images/login/email-verification-success.png");

type Props = {
    handleSetFormToDisplay: (value: LoginDisplays) => () => void;
};

export function EmailVerificationLinkSend({ handleSetFormToDisplay }: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [disabled, setDisabled] = useState(false);

    const handleSendValidationMail = async () => {
        setDisabled(true);
        await requestVerifyEmail().finally(() =>
            setTimeout(() => {
                setDisabled(false);
            }, 30000)
        );
    };

    const handleRedirect = () => {
        handleSetFormToDisplay("login")();
        navigate("/");
    };

    return (
        <>
            <Typography
                variant={"h2"}
                message={t("LoginPanel.verification-link-success")}
                className='login-title-happy'
            />
            <Typography
                message={t("LoginPanel.verification-link-success-description")}
                className='color-neutral-500 fw-bold'
            />
            <div className='link-image-container'>
                <img className='link-image-success' alt='success' src={success} />
            </div>
            <Button onClick={handleRedirect} className='w-100' label={t("LoginPanel.back-to-login")} />
            <div className='d-flex align-items-center mt-3'>
                <Typography message={t("LoginPanel.link-not-received")} className='color-neutral-500' />
                &nbsp;
                <div onClick={handleSendValidationMail} className='send-email-again-button'>
                    <Typography
                        className={disabled ? "color-neutral-500" : "color-lilas-900"}
                        message={t("LoginPanel.send-link-again")}
                    />
                </div>
            </div>
        </>
    );
}
