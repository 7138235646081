import { toast } from "react-toastify";
import { TaskItemModelToCreate } from "src/services/taskItems/types";
import { TaskItemTmpModel } from "src/shared/models/TaskItemTmp";
import { parseCreateTaskFile } from "src/utils/xlsx/parseTask";
import * as XLSX from "xlsx";

import { ChangeEvent } from "react";

import { useTranslation } from "react-i18next";

import { fetchTaskToEdit } from "src/modules/tasks/thunkActions";
import { getTaskItemsFileUploading } from "src/modules/tasksItems/selectors";
import { handleCreateTaskitems } from "src/modules/tasksItems/thunkActions";
import { SubscriptionRestrictions, useSubscriptionRestrictions } from "src/shared/hooks/useSubscriptionRestrictions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Select } from "src/shared/atoms/Select/Select";
import { Spinner } from "src/shared/atoms/Spinner/Spinner";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";

type Props = {
    open: boolean;
    taskItems: {
        [key: string]: TaskItemTmpModel;
    };
    createdItems?: {
        [key: string]: TaskItemModelToCreate;
    };
    taskId?: number;
    disabled?: boolean;
    handleOpenDrawerCreateTaskItem: () => void;
    showIcon?: boolean;
};

export function TasksEditSelect({
    handleOpenDrawerCreateTaskItem,
    open,
    createdItems,
    taskItems,
    disabled,
    showIcon = false,
    taskId,
}: Props) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const isNbTransactionsRestricted = useSubscriptionRestrictions(SubscriptionRestrictions.LIMIT_15_TRANSACTION);
    const taskItemsFileUploading = useAppSelector((state) => getTaskItemsFileUploading(state, { taskId }));

    const onFileLoaded = async (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];

        if (selectedFile) {
            let reader = new FileReader();
            reader.onload = async function (e) {
                let data = e?.target?.result;
                if (data && taskId) {
                    if (isNbTransactionsRestricted) {
                        const readedData = XLSX.read(data, { type: "binary" });

                        const firstSheet = readedData.Sheets[readedData.SheetNames[0]];
                        const dataParseFirstSheet = XLSX.utils.sheet_to_json<string[]>(firstSheet, { header: 1 });
                        const nonEmptyRows = dataParseFirstSheet.filter((row) =>
                            row.some((cell) => cell !== null && cell !== undefined && cell !== "")
                        );

                        if (nonEmptyRows.length - 1 + Object.keys(taskItems).length > 15) {
                            toast.error(t("WalletSelector.free-plan-warning-list"));
                            return;
                        }
                    }

                    try {
                        const fileData = parseCreateTaskFile(t, data);
                        if (typeof fileData === "string") {
                            alert(fileData);
                        } else if (fileData) {
                            dispatch(handleCreateTaskitems({ taskId, taskItems: fileData }))
                                .unwrap()
                                .then(() => dispatch(fetchTaskToEdit({ taskId })))
                                .catch((error) => {
                                    alert(t(error.message));
                                });
                        } else {
                            alert(t("Tasks.file-is-not-standart-choose-another"));
                        }
                    } catch {
                        alert(t("Tasks.file-is-not-standart-choose-another"));
                    }
                }
            };
            reader.readAsBinaryString(selectedFile);
            event.target.value = "";
        }
    };

    const freePlanLimit = isNbTransactionsRestricted && Object.keys(taskItems).length >= 15;
    const fileUploading = Object.keys(taskItemsFileUploading ?? {}).length !== 0;

    return (
        <div className='d-flex w-100 justify-content-end mb-4'>
            <ToolTip
                position='top'
                activation={freePlanLimit}
                content={
                    <div className='tooltip-limited'>
                        <Typography message={t("Subscription.operations-limit")} className='fw-bold' />
                        <Typography message={t("Subscription.unlock-unlimited-access")} />
                    </div>
                }
            >
                <div className='d-flex edit-task-select-button'>
                    <Button
                        label={t("EditTaskPanel.ajouter-une-operation")}
                        onClick={handleOpenDrawerCreateTaskItem}
                        testId='create-task-add-operation-button'
                        icon={showIcon ? "plus-circle" : undefined}
                    />
                    <div className='task-button-vertical-divider' />
                    <Select
                        forceClose={fileUploading || !!createdItems || open}
                        fitContent
                        contentClassName='task-button-select-content'
                        type='warning'
                        content={{
                            header: {
                                disabled: false,
                                className: "task-button-select-button pl-0 pr-2",
                            },
                            dropDownContent: {
                                body: (
                                    <div className='edit-task-select-container'>
                                        <div className='px-2 pt-1 pb-3'>
                                            <div
                                                className='select-item cursor-pointer'
                                                onClick={handleOpenDrawerCreateTaskItem}
                                            >
                                                <Icon name='plus-circle' size='sm' />
                                                <Typography
                                                    className='fw-bold ml-2'
                                                    message={t("EditTaskPanel.ajouter-une-operation")}
                                                />
                                            </div>
                                        </div>
                                        <div className='px-2 pb-3'>
                                            <label className='cursor-pointer' htmlFor='files'>
                                                <div className='d-flex justify-content-between'>
                                                    <div
                                                        className={`select-item ${
                                                            disabled || fileUploading ? "disabled" : ""
                                                        }`}
                                                    >
                                                        <Icon name='document' size='sm' />
                                                        <Typography
                                                            className='fw-bold ml-2'
                                                            message={t("EditTaskPanel.utiliser-un-modele")}
                                                        />
                                                    </div>
                                                    {fileUploading && <Spinner size='xs' />}
                                                </div>
                                            </label>
                                            <input
                                                type='file'
                                                disabled={fileUploading || disabled}
                                                id='files'
                                                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                                onChange={onFileLoaded}
                                                style={{ display: "none" }}
                                            />
                                        </div>
                                        <div className='px-2 pb-2'>
                                            <a
                                                href={`/models/bulk_transfert_modele_julaya.xlsx`}
                                                target='_blank'
                                                rel='noopener noreferrer'
                                                className='select-item'
                                            >
                                                <Icon name='arrow-down-on-square' size='sm' />
                                                <Typography
                                                    className='fw-bold ml-2'
                                                    message={t("EditTaskPanel.download-model")}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                ),
                            },
                        }}
                    />
                </div>
            </ToolTip>
        </div>
    );
}
