import { TasksSignatureDialog } from "../../Signature/Dialog";
import { EditTaskFooterStatus } from "./Status";
import { useNavigate } from "react-router-dom";
import { deleteTask } from "src/services/tasks/operations";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import { getTaskToEdit } from "src/modules/tasks/selectors";
import {
    getTaskItems,
    getTaskItemsErrorsCount,
    getTaskItemsFileUploading,
    getTaskItemsIsUpdating,
} from "src/modules/tasksItems/selectors";
import { SubscriptionRestrictions, useSubscriptionRestrictions } from "src/shared/hooks/useSubscriptionRestrictions";
import { useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Modal } from "src/shared/components/Modal/Modal";

import "./styles.scss";

type Props = {
    taskId: number | undefined;
    taskCreation: boolean;
};

export function EditTaskFooterSummary({ taskId, taskCreation }: Props) {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [exitModalOpen, setExitModalOpen] = useState(false);
    const { t } = useTranslation();

    const task = useAppSelector((state) => getTaskToEdit(state));
    const taskItems = useAppSelector((state) => getTaskItems(state, { taskId }));
    const errorsCount = useAppSelector((state) => getTaskItemsErrorsCount(state, { taskId }));
    const isUpdating = useAppSelector((state) => getTaskItemsIsUpdating(state, { taskId }));
    const taskItemsFileUploading = useAppSelector((state) => getTaskItemsFileUploading(state, { taskId }));
    const isNbTransactionsRestricted = useSubscriptionRestrictions(SubscriptionRestrictions.LIMIT_15_TRANSACTION);

    const handleSwitchOpen = () => {
        setOpen((oldOpen) => !oldOpen);
    };

    const handleOpenExitModal = () => {
        setExitModalOpen(true);
    };

    const handleRedirect = () => {
        setExitModalOpen(false);
        navigate("/tasks");
    };

    const handleCloseExitModal = async () => {
        if (task) {
            await deleteTask({ taskId: task.id });
        }
        handleRedirect();
    };

    const disabled =
        errorsCount !== 0 ||
        isUpdating ||
        Object.keys(taskItems).length === 0 ||
        Object.keys(taskItemsFileUploading ?? {}).length > 0 ||
        !!task?.errorCode ||
        taskCreation ||
        (isNbTransactionsRestricted && Object.keys(taskItems).length >= 15);

    const cancelButtonDisabled = Object.keys(taskItemsFileUploading ?? {}).length > 0 || isUpdating;

    return (
        <>
            <div className='edit-task-footer-container shadow'>
                <div className='edit-task-status-amount-container'>
                    <EditTaskFooterStatus taskId={taskId} taskCreation={taskCreation} />
                </div>
                <div className='d-flex align-items-center'>
                    <Button
                        variant='ghost'
                        disabled={cancelButtonDisabled}
                        className='mr-2'
                        onClick={handleOpenExitModal}
                        label={t("CommonUse.quit")}
                    />
                    <Button
                        className='btn px-4 btn-primary'
                        onClick={handleSwitchOpen}
                        disabled={disabled}
                        label={t("Tasks.sign")}
                    />
                    <TasksSignatureDialog open={open} handleSwitchOpen={handleSwitchOpen} taskId={taskId} />
                </div>
            </div>
            <Modal
                handleOnSubmit={handleRedirect}
                handleOnCancel={handleCloseExitModal}
                open={exitModalOpen}
                header={{
                    icon: "information-circle",
                    title: t("EditTaskPanel.info"),
                }}
                cancelLabel={t<string>("CommonUse.delete")}
                confirmLabel={t<string>("EditTaskPanel.keep-as-draft")}
            >
                <div className='edit-task-footer-modal-content'>
                    <Typography message={t("EditTaskPanel.are-your-sure-quit")} />
                </div>
            </Modal>
        </>
    );
}
