import { useNavigate } from "react-router-dom";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

export type StatCardProps = {
    icon: IconListType;
    label: string;
    value?: number;
    isLoading?: boolean;
    link?: string;
};

export function StatCard({ icon, label, value, isLoading = false, link }: StatCardProps) {
    const navigate = useNavigate();

    const randomClassIndex = Math.floor(Math.random() * 5) + 1;
    const randomClassName = `delay-${randomClassIndex}`;

    const handleNavigate = () => {
        if (link) {
            navigate(link);
        }
    };

    return (
        <div
            className={`stat-card-container ${randomClassName} ${value === 0 ? "disabled" : ""} ${
                isLoading ? "loading" : ""
            }`}
            onClick={handleNavigate}
            aria-hidden='true'
        >
            {!isLoading && (
                <>
                    <Avatar icon={icon} size='lg' />
                    <div className='stat-card-content'>
                        <Typography className='fw-bold' message={label} />
                        <Typography className='fw-bold color-lilas-900' message={value} size='lg' />
                    </div>
                </>
            )}
        </div>
    );
}
