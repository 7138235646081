import { TransactionSlug } from "../../services/transactions/types";

import { TFunction } from "i18next";

import { IconListType } from "../atoms/Icons/IconList";

const transactionStatuses = (t: TFunction<"translation", undefined, "translation">) => ({
    waiting: t("Transactions.waiting"),
    failed: t("Transactions.failed"),
    done: t("Tasks.success"),
    cancelled: t("CommonUse.canceled"),
});

const billingTransactionStatuses = (t: TFunction<"translation", undefined, "translation">) => ({
    waiting: t("Transactions.waiting"),
    failed: t("Transactions.failed"),
    done: t("Tasks.success"),
});

export const transactionStatusArchived = "archived";

interface TransactionTypes {
    [key: number]: string;
}

interface TransactionTypesSlug {
    [key: number]: string;
}

interface TransactionMap {
    type: "image" | "icon";
    title: string;
    img: IconListType;
    phoneFormat: boolean;
    creditCard?: boolean;
}

// TODO: translation

export const transactionTypes: TransactionTypes = {
    3: "Transfert Julaya",
    5: "Transfert Mobile Money",
    21: "Approvisionnement bancaire",
    28: "Transfert Wave",
    18: "Transfert carte bancaire",
    17: "Transfert bancaire",
    29: "Transfert international",
    31: "Décaissement crédit",
    32: "Remboursement crédit",
    19: "Paiement de factures",
    33: "Encaissement de factures",
    36: "Parrainage",
    30: "Paiement marchand",
    25: "Dépôt Cash",
    41: "Transfert Mixx By Yas",
    16: "Accès Pro",
};

export const transactionTypesSlug: TransactionTypesSlug = {
    3: "transfer",
    5: "disposal",
    21: "bank-disposal",
    28: "wave-transfer",
    41: "mixx-transfer",
    18: "cb-transfer",
    17: "bank-transfer",
    29: "international-transfer",
    31: "loan-disbursement",
    32: "loan-repayment",
    19: "bill-payment",
    33: "bill-repayment",
    36: "reward",
    30: "merchant-payment",
    25: "momo-deposit",
    16: "subscription",
};

export function getTransactionTypeSlugs(typeIds: string[]) {
    return typeIds.map((typeId) => transactionTypesSlug[typeId as any]);
}

export function computeSimpleObjectToSelectOptions(object: { [key: string]: string } | { [key: number]: string }) {
    let selectOptions = Object.entries(object).map(([key, value]) => ({
        id: key,
        label: value,
    }));

    selectOptions = selectOptions.sort((a, b) => a.label.localeCompare(b.label));
    return selectOptions;
}

//TODO: translations
export const moneyOutCancelReasons = {
    PROCESSING_TOO_LONG: "Délais de traitement trop long",
    WRONG_DESTINATION: "Mauvais destinataire",
    WRONG_AMOUNT: "Mauvais montant",
    WRONG_SERVICE: "Mauvais service",
    WRONG_WALLET: "Mauvais compte utilisé",
    OTHER: "Autre",
};

//TODO: translations
const bankDisposalCancelReasons = {
    PROCESSING_TOO_LONG: "Délais de traitement trop long",
    WRONG_AMOUNT: "Mauvais montant",
    WRONG_SERVICE: "Mauvais service",
    WRONG_WALLET: "Mauvais compte utilisé",
    OTHER: "Autre",
};

export const transactionTypesFilterOptions = computeSimpleObjectToSelectOptions(transactionTypes);
export const transactionStatusesFilterOptions = (t: TFunction<"translation", undefined, "translation">) =>
    computeSimpleObjectToSelectOptions(transactionStatuses(t));
export const billingtransactionStatusesFilterOptions = (t: TFunction<"translation", undefined, "translation">) =>
    computeSimpleObjectToSelectOptions(billingTransactionStatuses(t));
export const bankDisposalCancelReasonsOptions = computeSimpleObjectToSelectOptions(bankDisposalCancelReasons);
export const moneyOutCancelReasonsOptions = computeSimpleObjectToSelectOptions(moneyOutCancelReasons);

//TODO: translations in title
export const operationTypes: {
    [key in TransactionSlug]: {
        icon: IconListType;
        title: string;
        color?: "primary" | "success" | "error" | "neutral" | "warning" | "blue" | "lilas";
    };
} = {
    subscription: { icon: "arrows-right-left-filled", title: "Abonnement" },
    airtime: { icon: "phone-plus-circle", title: "Crédit Téléphonique", color: "blue" },
    referal: { icon: "arrows-right-left-filled", title: "Apporteur d'affaire" },
    reward: { icon: "arrows-right-left-filled", title: "Parrainage" },
    "bank-disposal": { icon: "wallet", title: "Approvisionnement", color: "lilas" },
    buy: { icon: "wallet", title: "Rechargement MoMo/CB" },
    "bank-transfer": { icon: "bank", title: "Virement Bancaire", color: "success" },
    "cb-transfer": { icon: "credit-card", title: "Transfert vers CB", color: "warning" },
    transfer: { icon: "julaya-circle", title: "Julaya", color: "warning" },
    purchase: { icon: "placeholder", title: "Achat de carte" },
    disposal: { icon: "arrows-right-left-filled", title: "Mobile Money", color: "warning" },
    "momo-deposit": { icon: "wallet", title: "Dépôt cash" },
    "merchant-payment": { icon: "wallet", title: "Lien de paiement" },
    "wave-transfer": { icon: "arrows-right-left-filled", title: "Transfert Wave", color: "blue" },
    "mixx-transfer": { icon: "arrows-right-left-filled", title: "Transfert Mixx", color: "blue" },
    "international-transfer": {
        icon: "arrows-right-left-filled",
        title: "Transfert international",
        color: "warning",
    },
    "loan-disbursement": { icon: "bills", title: "Décaissement crédit", color: "neutral" },
    "loan-repayment": { icon: "bills", title: "Remboursement crédit", color: "neutral" },
    "bill-payment": { icon: "bills", title: "Paiement de factures", color: "neutral" },
    "bill-repayment": { icon: "bills", title: "Encaissement de factures", color: "neutral" },
    "bill-payment-ext": { icon: "bills", title: "Paiement de factures", color: "neutral" },
    "bill-repayment-ext": { icon: "bills", title: "Encaissement de factures", color: "neutral" },
    load: { icon: "credit-card", title: "Crédit" },
    deduction: { icon: "credit-card", title: "Débit" },
    authorisation: { icon: "credit-card", title: "Autorisation" },
    task: { icon: "placeholder", title: "Tâche" },
    "deduct-card-ben": { icon: "credit-card", title: "Retour de fonds Carte JULAYA" },
    "deduct-card-sen": { icon: "credit-card", title: "Retour de fonds Carte JULAYA" },
    "deduct-card-civ": { icon: "credit-card", title: "Retour de fonds Carte JULAYA" },
} as const;

export const transactionTypesMap: { [key: string]: TransactionMap } = {
    transfer: {
        type: "image",
        title: "JULAYA",
        img: require("src/shared/images/operators/julaya.png"),
        phoneFormat: true,
    },
    "wave-transfer": {
        type: "image",
        title: "Wave",
        img: require("src/shared/images/operators/wave.png"),
        phoneFormat: true,
    },
    "mixx-transfer": {
        type: "image",
        title: "Mix by Yas",
        img: require("src/shared/images/operators/mixx.png"),
        phoneFormat: true,
    },
    disposal: {
        type: "icon",
        title: "Mobile Money",
        img: "arrows-right-left",
        phoneFormat: true,
    },
    "international-transfer": {
        type: "icon",
        title: "Mobile Money International",
        img: "globe",
        phoneFormat: true,
    },
    "bank-transfer": {
        type: "icon",
        title: "Compte bancaire",
        img: "bank",
        phoneFormat: false,
    },
    airtime: {
        type: "icon",
        title: "Crédit téléphonique",
        img: "device-phone-mobile",
        phoneFormat: true,
    },
    "cb-transfer": {
        type: "icon",
        title: "Carte bancaire",
        img: "credit-card",
        phoneFormat: true,
    },
    "bill-payment": {
        type: "icon",
        title: "Paiement de factures",
        img: "bills",
        phoneFormat: false,
    },
};

export const selectTransactionTypesMap: { [key: string]: TransactionMap } = {
    "wave-transfer": {
        type: "image",
        title: "Wave",
        img: require("src/shared/images/operators/wave.png"),
        phoneFormat: true,
    },
    "mixx-transfer": {
        type: "image",
        title: "Mixx by Yas",
        img: require("src/shared/images/operators/mixx.png"),
        phoneFormat: true,
    },
    disposal: {
        type: "icon",
        title: "Mobile Money",
        img: "arrows-right-left",
        phoneFormat: true,
    },
    "bank-transfer": {
        type: "icon",
        title: "Compte bancaire",
        img: "bank",
        phoneFormat: false,
    },
    "cb-transfer": {
        type: "icon",
        title: "Carte bancaire",
        img: "credit-card",
        phoneFormat: false,
    },
};

type TransactionServiceSlugImage = {
    [key: string]: any;
};

export const transactionServiceSlugImage: TransactionServiceSlugImage = {
    "disposal-mtn-civ": "https://cdn.julaya.co/logos/mtn-money.jpg",
    "disposal-mtn-ben": "https://cdn.julaya.co/logos/mtn-money.jpg",
    "disposal-moov-civ": "https://cdn.julaya.co/logos/moov-money.jpg",
    "disposal-moov-ben": "https://cdn.julaya.co/logos/moov-money.jpg",
    "momo-deposit-moov-civ": "https://cdn.julaya.co/logos/moov-money.jpg",
    "momo-deposit-collect-wave-civ": "https://cdn.julaya.co/logos/wave.png",
    "momo-deposit-collect-wave": "https://cdn.julaya.co/logos/wave.png",
    "momo-deposit-collect-wave-sen": "https://cdn.julaya.co/logos/wave.png",
    "momo-deposit-collect-ecobank-civ": "https://cdn.julaya.co/logos/xpress.png",
    "momo-deposit-collect-ecobank": "https://cdn.julaya.co/logos/xpress.png",
    "momo-deposit-collect-ecobank-sen": "https://cdn.julaya.co/logos/xpress.png",
    "momo-deposit-collect-ecobank-ben": "https://cdn.julaya.co/logos/xpress.png",
    "momo-deposit-collect-yelemoney-civ": "https://cdn.julaya.co/logos/yelemoney.jpg",
    "momo-deposit-collect-yelemoney": "https://cdn.julaya.co/logos/yelemoney.jpg",
    "momo-deposit-collect-mixx": "https://cdn.julaya.co/logos/mixx.png",
    "momo-deposit-collect-mixx-sen": "https://cdn.julaya.co/logos/mixx.png",
    "merchant-payment-wave-civ": "https://cdn.julaya.co/logos/wave.png",
    "merchant-payment-wave-sen": "https://cdn.julaya.co/logos/wave.png",
    "disposal-orange-civ": "https://cdn.julaya.co/logos/orange-money.jpg",
    "cb-transfer-djamo-civ": "https://cdn.julaya.co/logos/djamo.png",
    "disposal-orange-sen": "https://cdn.julaya.co/logos/orange-money.jpg",
    "disposal-emoney-sen": "https://cdn.julaya.co/logos/e-money.png",
    "disposal-free-sen": "https://cdn.julaya.co/logos/free-money.png",
    "disposal-celtiis-ben": "https://cdn.julaya.co/logos/celtiis.png",
    "airtime-mtn-civ": "https://cdn.julaya.co/logos/mtn.jpg",
    "airtime-moov-civ": "https://cdn.julaya.co/logos/moov.jpg",
    "airtime-orange-civ": "https://cdn.julaya.co/logos/orange.png",
    "bank-disposal-uba-civ": "https://cdn.julaya.co/logos/uba.png",
    "bank-disposal-ecobank-civ": "https://cdn.julaya.co/logos/ecobank.png",
    "bank-disposal-ecobank-sen": "https://cdn.julaya.co/logos/ecobank.png",
    "bank-disposal-ecobank-ben": "https://cdn.julaya.co/logos/ecobank.png",
    "bank-disposal-sgbs-sen": "https://cdn.julaya.co/logos/SGBCI.jpeg",
    "bank-disposal-boa-sen": "https://cdn.julaya.co/logos/boa.png",
    "bank-disposal-boa-ben": "https://cdn.julaya.co/logos/boa.png",
    "bank-disposal-uba-sen": "https://cdn.julaya.co/logos/uba.png",
    "bank-disposal-cbao-sen": "https://cdn.julaya.co/logos/sib.png",
    "bank-disposal-sgbci-civ": "https://cdn.julaya.co/logos/SGBCI.jpeg",
    "bank-disposal-bdu-civ": "https://cdn.julaya.co/logos/bdu.png",
    "bank-disposal-orabank-civ": "https://cdn.julaya.co/logos/orabank.png",
    "bank-disposal-orabank-sen": "https://cdn.julaya.co/logos/orabank.png",
    "bank-disposal-nsia-civ": "https://cdn.julaya.co/logos/nsia.png",
    "bank-disposal-bicici-civ": "https://cdn.julaya.co/logos/bicici.png",
    "bank-disposal-boa-civ": "https://cdn.julaya.co/logos/boa.png",
    "bank-disposal-gtb-civ": "https://cdn.julaya.co/logos/bicici.png",
    "bank-disposal-sib-civ": "https://cdn.julaya.co/logos/sib.png",
    "bank-disposal-dgtcp-civ": "https://cdn.julaya.co/logos/DGTCP.png",
    "bank-disposal-mansa-civ": "https://cdn.julaya.co/logos/mansabank.png",
    "bank-disposal-mansa-ben": "https://cdn.julaya.co/logos/mansabank.png",
    "bank-disposal-mansa-sen": "https://cdn.julaya.co/logos/mansabank.png",
    "bank-transfer-waemu-civ": "https://cdn.julaya.co/logos/bank.png",
    "bank-transfer-waemu-sen": "https://cdn.julaya.co/logos/bank.png",
    "bank-transfer-waemu-ben": "https://cdn.julaya.co/logos/bank.png",
    "transfer-julaya-civ": "https://cdn.julaya.co/logos/julaya.png",
    "transfer-julaya-ben": "https://cdn.julaya.co/logos/julaya.png",
    "transfer-julaya-internal-civ": "https://cdn.julaya.co/logos/julaya.png",
    "transfer-julaya-internal-sen": "https://cdn.julaya.co/logos/julaya.png",
    "transfer-julaya-internal-ben": "https://cdn.julaya.co/logos/julaya.png",
    "transfer-julaya-external-ben": "https://cdn.julaya.co/logos/julaya.png",
    "wave-transfer-civ": "https://cdn.julaya.co/logos/wave.png",
    "wave-transfer-sen": "https://cdn.julaya.co/logos/wave.png",
    "mixx-transfer-sen": "https://cdn.julaya.co/logos/mixx.png",
    load: "https://cdn.julaya.co/logos/cb-transfer.png",
    deduction: "https://cdn.julaya.co/logos/cb-transfer.png",
    "deduct-card-ben": "https://cdn.julaya.co/logos/cb-transfer.png",
    "deduct-card-civ": "https://cdn.julaya.co/logos/cb-transfer.png",
    "deduct-card-sen": "https://cdn.julaya.co/logos/cb-transfer.png",
    authorisation: "https://cdn.julaya.co/logos/cb-transfer.png",
    "cb-transfer-tutuka-civ": "https://cdn.julaya.co/logos/cb-transfer.png",
    "cb-transfer-tutuka-sen": "https://cdn.julaya.co/logos/cb-transfer.png",
    "cb-transfer-tutuka-ben": "https://cdn.julaya.co/logos/cb-transfer.png",
    "wizall-transfer-cash-civ": "https://cdn.julaya.co/logos/wizall.png",
    "international-transfer-mfs-civ": "https://cdn.julaya.co/logos/transfer-international.png",
    "international-transfer-mfs-sen": "https://cdn.julaya.co/logos/transfer-international.png",
    "international-transfer-mfs-ben": "https://cdn.julaya.co/logos/transfer-international.png",
    "international-transfer-mfs-tgo": "https://cdn.julaya.co/logos/transfer-international.png",
    "bank-transfer-civ": "https://cdn.julaya.co/logos/bank.png",
    "bank-transfer-sen": "https://cdn.julaya.co/logos/bank.png",
    "bank-transfer-ben": "https://cdn.julaya.co/logos/bank.png",
    "bank-transfer-ecobank-civ": "https://cdn.julaya.co/logos/bank.png",
    "loan-disbursement-civ": "https://cdn.julaya.co/logos/julaya.png",
    "loan-disbursement-sen": "https://cdn.julaya.co/logos/julaya.png",
    "bill-payment-ext-abjterm-civ": "https://cdn.julaya.co/logos/julaya.png",
    "bill-repayment-ext-abjterm-civ": "https://cdn.julaya.co/logos/julaya.png",
    "bill-payment-ext-hkb-civ": "https://cdn.julaya.co/logos/BIP+PASS.png",
    "bill-payment-ext-cie-civ": "https://cdn.julaya.co/logos/cie.png",
    "bill-payment-ext-sodeci-civ": "https://cdn.julaya.co/logos/sodeci.png",
    "bill-payment-ext-woyofal-sen": "https://cdn.julaya.co/logos/logo+woyofal.jpg",
    "bill-payment-ext-rapido-sen": "https://cdn.julaya.co/logos/RAPIDO.png",
    "bill-payment-ext-xeweul-sen": "https://cdn.julaya.co/logos/XEFUL.png",
    "reward-referrer-civ": "https://cdn.julaya.co/logos/julaya.png",
    "reward-referrer-sen": "https://cdn.julaya.co/logos/julaya.png",
    "reward-referrer-ben": "https://cdn.julaya.co/logos/julaya.png",
    "reward-referred-civ": "https://cdn.julaya.co/logos/julaya.png",
    "reward-referred-sen": "https://cdn.julaya.co/logos/julaya.png",
    "reward-referred-ben": "https://cdn.julaya.co/logos/julaya.png",
    "bank-disposal-ecobank-tgo": "https://cdn.julaya.co/logos/ecobank.png",
    "disposal-moov-tgo": "https://cdn.julaya.co/logos/moov-money.jpg",
    "disposal-yas-tgo": "https://cdn.julaya.co/logos/yas.jpg",
    "bank-transfer-tgo": "https://cdn.julaya.co/logos/bank.png",
    "transfer-julaya-internal-tgo": "https://cdn.julaya.co/logos/julaya.png",
    "transfer-in-internal-tgo": "https://cdn.julaya.co/logos/julaya.png",
    "bill-payment-default-tgo": "https://cdn.julaya.co/logos/julaya.png",
    "bill-repayment-default-tgo": "https://cdn.julaya.co/logos/julaya.png",
    "reward-referred-tgo": "https://cdn.julaya.co/logos/julaya.png",
    "reward-referrer-tgo": "https://cdn.julaya.co/logos/julaya.png",
    "refund-default-tgo": "https://cdn.julaya.co/logos/julaya.png",
    "subscription-default-tgo": "https://cdn.julaya.co/logos/julaya.png",
};

//TODO: translations
export const transactionsErrorReasons: { [key: string]: string } = {
    INVALID_PHONE_NUMBER: "Le numéro de téléphone saisi est incorrect. Veuillez le corriger et essayer à nouveau.",
    ERROR_OCCURED: "Une erreur est survenue. Veuillez ressayer ultérieurement.",
    ACCOUNT_NOT_AUTHORIZED: "Le compte choisi n'est pas autorisé à recevoir de l'argent. ",
    SUCCESSFUL: "La transaction a été traité avec succès.",
    ACCOUNT_SUSPENDED: "Le compte Mobile Money du destinataire est suspendu.",
    INVALID_AMOUNT: "Le montant de la transaction est superieur au montant maximum defini pour ce service.",
    ACCOUNT_DAILY_LIMIT_REACHED:
        "Le compte vers lequel vous essayez d'envoyer l'argent a atteint la limite mensuelle de transaction.",
    ACCOUNT_MONTHLY_LIMIT_REACHED:
        "Le compte vers lequel vous essayez d'envoyer l'argent a atteint la limite journalière de transaction.",
    ACCOUNT_WEEKLY_LIMIT_REACHED:
        "Le compte vers lequel vous essayez d'envoyer l'argent a atteint la limite hebdomadaire de transaction.",
    ACCOUNT_LIMIT_REACHED: "Le compte vers lequel vous essayez d'envoyer l'argent a atteint sa limite de solde. ",
    ACCOUNT_BLOCKED_OR_DISABLED: "Le compte du destinataire est désactivé ou bloqué.",
    IDENTICAL_DEPOSIT: "Vous venez de réaliser un dépôt identique.",
    ACCOUNT_NOT_FOUND: "Le destinataire ne dispose pas d'un compte Mobile Money.",
    BATCH_CHECKS_NOT_ENOUGHT_MONEY: "Vous n'avez pas assez d'argent sur votre compte.",
    BATCH_CHECKS_MAX_SOLDE: "Le compte vers lequel vous essayez d'envoyer l'argent a atteint sa limite de solde.",
    BATCH_CHECKS_MAX_CASHIN: "Le compte vers lequel vous essayez d'envoyer l'argent a atteint sa limite de solde.",
};

export const topupErrorReasons: { [key: string]: string } = {
    TOPUP_AMOUNT_DONT_MATCH: "TopupErrorReasons.amount-dont-match",
    TOPUP_RECEIPT_MISSING: "TopupErrorReasons.receipt-missing",
    TOPUP_BANK_DONT_MATCH: "TopupErrorReasons.bank-dont-match",
    TOPUP_TRANSFER_RECEIPT_NOT_SIGNED: "TopupErrorReasons.transfer-receipt-not-signed",
    TOPUP_CHEQUE_NOT_RECEIVED: "TopupErrorReasons.cheque-not-received",
    TOPUP_TRANSACTION_DUPLICATE: "TopupErrorReasons.transaction-duplicate",
    TOPUP_RECEIPT_IS_NOT_READABLE: "TopupErrorReasons.receipt-is-not-readable",
    TOPUP_REJECTION_CUSTOMER_REQUEST: "TopupErrorReasons.rejection-customer-request",
    TOPUP_SERVICE_NOT_CONFORM_TO_RECEIPT: "TopupErrorReasons.service-not-conform-to-recepit",
    TOPUP_RIB_MISMATCH: "TopupErrorReasons.rib-mismatch",
    TOPUP_TRANSACTION_ALREADY_CREDITED_VIA_IBAN: "TopupErrorReasons.transaction-already-credited-via-iban",
    TOPUP_DEPOSIT_SLIP_RECEIPT_NOT_STAMPED: "TopupErrorReasons.deposit-slib-receipt-not-stamped",
};
