import { DEMO_WARNING } from "../../services/utils/const";
import { LoginCreateCompanyPanel } from "./CreateCompany/Panel";
import { EmailValidationPanel } from "./EmailValidation/Panel";
import { LoginJoinPanel } from "./Join/Panel";
import { LoginPasswordReset } from "./PasswordReset";
import { LoginRegisterPanel } from "./Register/Panel";
import { LoginTwoFactor } from "./TwoFactor";
import { SliderButton } from "@typeform/embed-react";
import { useLocation } from "react-router-dom";
import { LoginContext } from "src/shared/context/login";
import LoginImage from "src/shared/images/login/login-image.svg";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUser, getAuthUserError, getAuthUserLoading } from "src/modules/auth/selectors";
import { isAuthLoginResponse, sendLoginRequest } from "src/modules/auth/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Input } from "../../shared/atoms/Inputs/Input";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

const julayaImage = require("src/shared/images/logo/julaya_pro.png");

export type LoginDisplays =
    | "login"
    | "twoFactor"
    | "forgot"
    | "code"
    | "newPassword"
    | "successSendMailNewPassword"
    | "emailVerification"
    | "create-user"
    | "add-company"
    | "join";

export const LoginPanel = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const location = useLocation();

    const [formToDisplay, setFormToDisplay] = useState<LoginDisplays>("login");
    const [identifier, setIdentifier] = useState("");
    const [twoFactor, setTwoFactor] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [password, setPassword] = useState("");
    const [tfaCode, setTfaCode] = useState("");
    const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(true);

    const authUser = useAppSelector((state) => getAuthUser(state));
    const error = useAppSelector((state) => getAuthUserError(state) ?? undefined);
    const loading = useAppSelector((state) => getAuthUserLoading(state));

    const isMobile = window.innerWidth < 768;

    useEffect(() => {
        if (!authUser?.tfaActive || (authUser?.tfaActive && tfaCode)) {
            if (authUser && !authUser?.isEmailVerified) {
                setFormToDisplay("emailVerification");
                setPassword("");
            }
        }
    }, [authUser]);

    useEffect(() => {
        setIsLoginButtonDisabled(!identifier || !password);
    }, [identifier, password]);

    useEffect(() => {
        if (location.pathname.includes("/create-account/") || location.pathname.includes("/create-company/")) {
            setFormToDisplay("create-user");
        } else if (location.pathname.includes("/join/")) {
            setFormToDisplay("join");
        } else if (location.pathname.includes("/reset-password/")) {
            setFormToDisplay("newPassword");
        } else if (location.pathname.includes("/add-company/")) {
            setFormToDisplay("add-company");
        }
    }, []);

    const handleSetIdentifier = (newValue: string) => {
        setIdentifier(newValue ? newValue : "");
    };

    const handleSetPassword = (newValue: string) => {
        setPassword(newValue ? newValue : "");
    };

    const handleConnection = () => {
        dispatch(sendLoginRequest({ identifier, password, ...(twoFactor ? { otp: twoFactor } : {}) }))
            .unwrap()
            .then((res) => {
                if (!isAuthLoginResponse(res) || (res?.user?.tfaActive && !tfaCode)) {
                    setFormToDisplay("twoFactor");
                }
            })
            .catch((err) => void err);
    };

    const handleSetFormToDisplay = (value: LoginDisplays) => () => {
        setFormToDisplay(value);
    };

    return (
        <LoginContext.Provider
            value={{ identifier, setIdentifier, password, setPassword, pinCode, setPinCode, tfaCode, setTfaCode }}
        >
            <div className={`login-panel-container ${DEMO_WARNING ? "demo-warning" : ""}`}>
                <div className='form-part'>
                    <div className={`login-form ${isMobile ? "mobile-form" : ""}`}>
                        {formToDisplay === "login" ? (
                            <>
                                <img src={julayaImage} alt='julaya logo' className='julaya-logo' />
                                <Typography
                                    variant={"h2"}
                                    message={t("LoginPanel.heureux-de-vous-revoir")}
                                    className='login-title-happy'
                                />
                                <Typography
                                    message={t("LoginPanel.check-your-payment-history")}
                                    className='login-subtitle'
                                />
                                <form className='form-container'>
                                    <Input
                                        placeholder={t("LoginPanel.nom-d-utilisateur")}
                                        type='classic'
                                        name='identifier'
                                        label={t<string>("CommonUse.identifier")}
                                        variant={error ? "error" : "primary"}
                                        value={identifier}
                                        onChange={handleSetIdentifier}
                                        testId='username-input'
                                    />
                                    <Input
                                        placeholder={t("LoginPanel.mot-de-passe")}
                                        value={password}
                                        label={t<string>("LoginPanel.mot-de-passe")}
                                        underMessage={error ? t<string>(error) : undefined}
                                        type='password'
                                        variant={error ? "error" : "primary"}
                                        onChange={handleSetPassword}
                                        autoComplete='current-password'
                                        testId='password-input'
                                    />
                                    <div
                                        onClick={handleSetFormToDisplay("forgot")}
                                        className='forgot-password-button'
                                        data-testid='password-reset-button'
                                    >
                                        <Typography
                                            className='color-lilas-900'
                                            message={t("LoginPanel.mot-de-passe-oublie-questionmark")}
                                        />
                                    </div>
                                </form>
                                <Button
                                    loading={loading}
                                    disabled={loading || isLoginButtonDisabled}
                                    onClick={handleConnection}
                                    className='login-button'
                                    label={t("LoginPanel.se-connecter")}
                                    testId='login-button'
                                />
                                <div className='no-account-sentence'>
                                    <Typography
                                        message={t("LoginPanel.no-account-yet-questionmark")}
                                        className='no-account'
                                    />
                                    <SliderButton id='z0u0K4YZ' className='typeform-slider-button-override'>
                                        <Typography message={t("LoginPanel.sign-up")} className='signup' />
                                    </SliderButton>
                                </div>
                            </>
                        ) : formToDisplay === "twoFactor" ? (
                            <LoginTwoFactor
                                twoFactor={twoFactor}
                                setTwoFactor={setTwoFactor}
                                handleSetFormToDisplay={handleSetFormToDisplay}
                                handleConnection={handleConnection}
                            />
                        ) : formToDisplay === "emailVerification" ? (
                            <EmailValidationPanel handleSetFormToDisplay={handleSetFormToDisplay} />
                        ) : formToDisplay === "create-user" ? (
                            <LoginRegisterPanel handleSetFormToDisplay={handleSetFormToDisplay} />
                        ) : formToDisplay === "join" ? (
                            <LoginJoinPanel handleSetFormToDisplay={handleSetFormToDisplay} />
                        ) : formToDisplay === "add-company" ? (
                            <LoginCreateCompanyPanel handleSetFormToDisplay={handleSetFormToDisplay} />
                        ) : (
                            <LoginPasswordReset
                                formToDisplay={formToDisplay}
                                handleSetFormToDisplay={handleSetFormToDisplay}
                            />
                        )}
                    </div>
                </div>
                {!isMobile && (
                    <div className='image-part'>
                        <div className='content'>
                            <Typography message={t("LoginPanel.img-title")} className='login-title-img' />
                            <img src={LoginImage} alt='julaya login page img' className='login-image' />
                        </div>
                    </div>
                )}
            </div>
        </LoginContext.Provider>
    );
};
