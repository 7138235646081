import { Icon } from "../Icons/Icon";
import { IconListType } from "../Icons/IconList";
import { Typography } from "../Typography/Typography";
import classNames from "classnames";

import "./styles.scss";

export type AvatarSize = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";

export type AvatarProps = {
    avatar?: string;
    firstname?: string;
    lastname?: string;
    icon?: IconListType;
    color?: string;
    backgroundColor?: string;
    size?: AvatarSize;
};

export const Avatar = ({
    avatar,
    firstname = "",
    lastname = "",
    icon,
    color = "lilas-900",
    size = "md",
    backgroundColor = "gradiant-pink-horizontal-1",
}: AvatarProps) => {
    let content;

    if (!avatar) {
        if (icon) {
            content = <Icon name={icon} className='avatar-icon' data-testid='avatar-icon' />;
        } else {
            content = (
                <Typography
                    className={classNames("avatar-initials", {
                        "color-initials-neutral ": color === "neutral",
                        "color-initials-primary": color !== "neutral",
                    })}
                    message={`${firstname[0] || ""}${lastname[0] || ""}`.toUpperCase()}
                    testId='avatar-initials'
                />
            );
        }
    }

    return (
        <div
            className={`avatar-container avatar-${size} color-${color} background-${backgroundColor}`}
            data-testid='avatar-container'
        >
            {avatar ? (
                <img src={avatar} data-testid='avatar-image' className='avatar-image' alt='avatar' />
            ) : (
                <Typography message={content} />
            )}
        </div>
    );
};
