import {
    failedCreateTasksUpload,
    failedRequestTaskItemUpdate,
    failedRequestTaskItems,
    failedRequestTaskItemsFileUploading,
    receiveCreateTasks,
    receiveTaskItems,
    receiveUpdateTaskItems,
    removeTaskItem,
    requestTaskItemUpdate,
    requestTaskItems,
    requestTaskItemsFileUploading,
} from "./slice";
import { splitArrayInSmallerObject } from "./utils";
import {
    CreateTaskApiPayload,
    createTaskItems,
    deleteTaskItems,
    requestGetTaskItems,
    requestUpdateTaskItems,
} from "src/services/taskItems/operations";
import { TaskItemModelToUpdate } from "src/services/taskItems/types";
import { TaskListApiReturnItem } from "src/services/tasks/types";
import { requestGetWalletTransactions } from "src/services/transactions/operations";
import { TransactionApi } from "src/services/transactions/types";
import { ApiFilters } from "src/services/types";
import { TaskStatus } from "src/shared/models/Task";
import { TaskItemTmpModel } from "src/shared/models/TaskItemTmp";
import { removeExtraSpaces } from "src/shared/utils/removeExtraSpaces";

import { AnyAction, ThunkDispatch, createAsyncThunk } from "@reduxjs/toolkit";

export type FetchTasksFilters = ApiFilters & {
    status?: TaskStatus[];
};

export const fetchTaskItems = createAsyncThunk(
    "fetchTasks",
    async ({ taskId }: { taskId: number | string }, { dispatch }) => {
        dispatch(requestTaskItems());
        try {
            const taskItems = await requestGetTaskItems(taskId);
            dispatch(receiveTaskItems({ taskItems, taskId }));
        } catch (error) {
            dispatch(failedRequestTaskItems());
            throw error;
        }
    }
);

export const handleUpdateTaskItem = createAsyncThunk(
    "handleUpdateTaskItem",
    async (
        {
            taskId,
            taskItem,
            update,
        }: { taskItem: TaskItemTmpModel; taskId: number; update: Partial<TaskItemModelToUpdate> },
        { dispatch }
    ) => {
        dispatch(requestTaskItemUpdate({ taskId, taskItemIds: [taskItem.id] }));
        try {
            const taskItems = await requestUpdateTaskItems({
                taskId,
                tasksItems: [
                    {
                        id: taskItem.id,
                        ...update,
                    },
                ],
            });
            dispatch(receiveUpdateTaskItems({ taskItems, taskId }));
        } catch (error) {
            dispatch(failedRequestTaskItemUpdate({ taskId, taskItemIds: [taskItem.id] }));
            throw error;
        }
    }
);

export const deleteTaskItem = createAsyncThunk(
    "deleteTaskItem",
    async ({ taskId, taskItemIds }: { taskItemIds: string[]; taskId: number }, { dispatch }) => {
        dispatch(requestTaskItemUpdate({ taskId, taskItemIds }));
        try {
            await deleteTaskItems({
                taskId,
                tasksItems: taskItemIds,
            });
            dispatch(removeTaskItem({ taskId, taskItemIds }));
        } catch (error) {
            dispatch(removeTaskItem({ taskId, taskItemIds }));
            dispatch(failedRequestTaskItemUpdate({ taskId, taskItemIds }));
            throw error;
        }
    }
);

const createTaskitemsFileUpload = async ({
    taskId,
    tasks,
    retry,
    dispatch,
}: {
    tasks: {
        [index: string]: CreateTaskApiPayload;
    };
    retry: number;
    taskId: number;
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>;
}) => {
    if (tasks) {
        try {
            if (window) {
                window.dispatchEvent(new CustomEvent("apiCall"));
            }
            const tasksWithFormattedNames = Object.fromEntries(
                Object.entries(tasks).map(([key, task]) => {
                    return [
                        key,
                        {
                            ...task,
                            externalFullname: removeExtraSpaces(task.externalFullname ? task.externalFullname : ""),
                        },
                    ];
                })
            );
            const taskItemsCreated = await createTaskItems({
                taskId,
                tasksItems: Object.values(tasksWithFormattedNames),
            });

            dispatch(
                receiveCreateTasks({
                    taskId,
                    taskItems: taskItemsCreated,
                    taskLoadingIds: Object.keys(tasks),
                })
            );
        } catch (e) {
            if (retry < 2) {
                createTaskitemsFileUpload({ taskId, tasks, retry: retry + 1, dispatch });
            } else {
                dispatch(failedCreateTasksUpload({ taskId, taskItems: tasks }));
                throw e;
            }
        }
    }
};

export const handleCreateTaskitems = createAsyncThunk(
    "handleCreateTaskItems",
    async ({ taskId, taskItems }: { taskItems: CreateTaskApiPayload[]; taskId: number }, { dispatch }) => {
        dispatch(requestTaskItemsFileUploading({ taskId, fileUploading: taskItems }));
        try {
            const taskItemsSplitted = splitArrayInSmallerObject(taskItems, 20);

            for (let i = 0; i < taskItemsSplitted.length; i += 1) {
                const tasks = taskItemsSplitted[i];

                if (tasks) {
                    await createTaskitemsFileUpload({ taskId, tasks, retry: 0, dispatch });
                }
            }
        } catch (error) {
            dispatch(failedRequestTaskItemsFileUploading({ taskId }));
            throw error;
        }
    }
);

export function isTaskItemTmpModel(item: TaskItemTmpModel | TransactionApi): item is TaskItemTmpModel {
    return !!(item as TaskItemTmpModel).serviceSlug;
}

export const handleDuplicateTaskitems = createAsyncThunk(
    "handleCreateTaskItems",
    async ({ taskId, taskToDuplicate }: { taskId: number; taskToDuplicate: TaskListApiReturnItem }, { dispatch }) => {
        try {
            let res;

            if (["draft", "to-validate"].includes(taskToDuplicate.status ?? "")) {
                res = await requestGetTaskItems(taskToDuplicate.id);
            } else {
                res = await requestGetWalletTransactions(String(taskToDuplicate.walletId), {
                    taskIds: [String(taskToDuplicate.id)],
                    page: 1,
                });
            }
            const taskItemsToDuplicate = res.data?.map<CreateTaskApiPayload>((taskItem) => ({
                amount: String(taskItem.amount),
                externalReference: taskItem.externalReference ?? "",
                comment: taskItem.comment,
                externalFullname: taskItem.externalFullname,
                serviceSlug: isTaskItemTmpModel(taskItem) ? taskItem.serviceSlug : taskItem.Service.slug,
                typeSlug: isTaskItemTmpModel(taskItem) ? taskItem.typeSlug : taskItem.TransactionType.slug,
            }));

            dispatch(handleCreateTaskitems({ taskId, taskItems: taskItemsToDuplicate }));
            return taskItemsToDuplicate;
        } catch (error) {
            return Promise.reject(error);
        }
    }
);
