import { TransactionApi } from "src/services/transactions/types";
import { formatPhone } from "src/shared/utils/formatPhone";

import { BillingTransactionsStateFilters } from "src/modules/billing/billingTransactions/slice";
import { WalletTransactionsStateFilters } from "src/modules/walletsTransactions/slice";

export const getTransactionReceiver = ({ externalReference, externalFullname, TransactionType }: TransactionApi) => {
    switch (TransactionType.slug.toLowerCase()) {
        case "cb-transfer":
        case "transfer":
        case "disposal":
        case "airtime":
        case "wave-transfer":
        case "mixx-transfer":
        case "momo-deposit":
        case "international-transfer":
            return [formatPhone(externalReference ?? ""), externalFullname];
        case "merchant-payment":
            return [formatPhone(externalReference ?? ""), externalFullname];
        case "bill-payment":
        case "bill-payment-ext":
        case "bill-repayment":
        case "bill-repayment-ext":
        case "bank-transfer":
            return [externalFullname, externalReference];
        case "deduction":
        case "load":
        case "authorisation":
            return [externalReference];
        case "bank-disposal":
        case "reward":
            return [`Julaya`];
        default:
            return ["Transaction Julaya"];
    }
};

export type HistoryFilterKeys = Exclude<
    keyof WalletTransactionsStateFilters | keyof BillingTransactionsStateFilters,
    "query"
>;

export const historyFiltersChipName: {
    [filterKey in HistoryFilterKeys]: string;
} = {
    status: "CommonUse.status",
    transactionType: "HistoryWalletFilters.type-de-transaction",
    startDate: "HistoryWalletFilters.date-de-debut",
    endDate: "HistoryWalletFilters.date-de-fin",
    archived: "HistoryWalletFilters.archive",
    tasks: "HistoryWalletFilters.tasks",
};
