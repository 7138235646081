import { EditTaskInputCell } from "./InputCell";
import classNames from "classnames";
import { TaskItemTmpModel } from "src/shared/models/TaskItemTmp";

import { useTranslation } from "react-i18next";

import { handleUpdateTaskItem } from "src/modules/tasksItems/thunkActions";
import { useAppDispatch } from "src/store";

import { Typography } from "src/shared/atoms/Typography/Typography";

type Props = {
    taskId?: number;
    taskItem: TaskItemTmpModel;
    loading: boolean;
};

export function TasksItemEditFieldComment({ taskItem, taskId, loading }: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const handleUpdateComment = (newComment: string) => {
        if (taskId && newComment !== taskItem.comment) {
            dispatch(
                handleUpdateTaskItem({
                    taskId,
                    taskItem: taskItem,
                    update: {
                        comment: newComment,
                    },
                })
            );
        }
    };

    const commentTrimmed = taskItem?.comment?.trim();

    return (
        <EditTaskInputCell
            disabled={loading}
            initialValue={taskItem.comment ?? ""}
            onValidate={handleUpdateComment}
            externalReference={{ isExternalReference: true, isBankWire: taskItem?.typeSlug === "bank-transfer" }}
        >
            <Typography
                className={classNames("edit-task-table-cell-content", {
                    "color-neutral-400": commentTrimmed === "" || !commentTrimmed,
                })}
                message={commentTrimmed === "" || !commentTrimmed ? t("Tasks.comment-placeholder") : taskItem.comment}
            />
        </EditTaskInputCell>
    );
}
