import { ApiFilters } from "../types";
import { middlewareRequest } from "../utils/request";
import { TaskListApiReturn, TaskListApiReturnItem } from "./types";
import { HandleSubmitPropsPasswordValidation } from "src/shared/common/Password/Modal";
import { TaskStatus } from "src/shared/models/Task";
import { TaskActivityModel } from "src/shared/models/TaskActivity";

export async function requestGetTasksList(
    filters?: ApiFilters & { wallets?: string[]; status?: TaskStatus[]; toSign?: boolean }
) {
    try {
        return await middlewareRequest<TaskListApiReturn>({ endpoint: "/tasks/list", method: "POST", params: filters });
    } catch (error) {
        return Promise.reject(error);
    }
}

type CreateTaskPayload = {
    walletId: string;
    title: string;
    description: string;
    includeReceiverFees: boolean;
    smsTemplate?: string;
};

export async function requestGetTask(taskId: number | string) {
    try {
        return await middlewareRequest<TaskListApiReturnItem>({ endpoint: `/tasks/${taskId}` });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestMarkTaskAsComplete(taskId: number | string) {
    try {
        return await middlewareRequest<TaskListApiReturnItem>({
            endpoint: `/tasks/${taskId}/mark-as-complete`,
            method: "PATCH",
        });
    } catch (error) {
        return Promise.reject();
    }
}

export async function requestRetryTaskTransactions({ taskId, password, twoFactor }: SignTaskProps) {
    try {
        return await middlewareRequest<TaskListApiReturnItem>({
            endpoint: `/tasks/${taskId}/retry`,
            method: "POST",
            params: {
                password,
                otp: twoFactor,
            },
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function createTask(params: CreateTaskPayload) {
    try {
        return await middlewareRequest<TaskListApiReturnItem>({
            endpoint: "/tasks/create",
            method: "POST",
            params: { ...params, way: "out" },
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function deleteTask({ taskId }: { taskId: number }) {
    try {
        return await middlewareRequest<TaskListApiReturnItem>({
            endpoint: `/tasks/${taskId}`,
            method: "DELETE",
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

type SignTaskProps = {
    taskId: number | string;
    uniqueId?: string;
} & HandleSubmitPropsPasswordValidation;

export async function signTask({ password, twoFactor, taskId, uniqueId }: SignTaskProps) {
    try {
        const response = await middlewareRequest({
            endpoint: `/tasks/${taskId}/sign`,
            method: "POST",
            params: {
                password,
                otp: twoFactor,
                signatureKey: uniqueId,
            },
        });
        return response;
    } catch (error) {
        return Promise.reject(error);
    }
}

type RequestCancelTaskProps = {
    taskId: string | number;
    comment?: string;
    reason?: string;
};

export async function requestCancelTask({ taskId, comment, reason }: RequestCancelTaskProps) {
    try {
        const response = await middlewareRequest({
            endpoint: `/tasks/${taskId}/cancel`,
            method: "POST",
            params: {
                comment,
                reason,
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export async function createTaskComment({ taskId, content }: { taskId: number; content: string }) {
    try {
        return await middlewareRequest<TaskActivityModel>({
            endpoint: `/tasks/${taskId}/activities`,
            method: "POST",
            params: { content },
        });
    } catch (error) {
        throw error;
    }
}

export async function fetchTaskComments({ taskId }: { taskId: number }) {
    try {
        return await middlewareRequest<{ data: TaskActivityModel[] }>({
            endpoint: `/tasks/${taskId}/activities/list`,
            method: "POST",
        });
    } catch (error) {
        throw error;
    }
}
