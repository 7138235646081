import { billingCountryLabel } from "src/shared/const/countryPhonePrefixes";
import { sectorIcon } from "src/shared/const/sectors";

import { useTranslation } from "react-i18next";

import { getAuthUserPreferenceSelectedCompany, getSelectedCompanyCountryTag } from "src/modules/auth/selectors";
import { getBillingEnterprise } from "src/modules/billing/enterprise/selectors";
import { getWalletByReference } from "src/modules/wallets/selectors";
import { useAppSelector } from "src/store";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Tag } from "src/shared/atoms/Tag/Tag";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type Props = {
    setOpen: () => void;
};

export function BillingGetPaidModuleEnterprise({ setOpen }: Readonly<Props>) {
    const { t } = useTranslation();

    const enterpriseData = useAppSelector((state) => getBillingEnterprise(state));
    const wallet = useAppSelector((state) =>
        getWalletByReference(state, { walletReference: enterpriseData?.billsWalletReference ?? "" })
    );
    const company = useAppSelector((state) => getAuthUserPreferenceSelectedCompany(state));
    const companyCountryTag = useAppSelector((state) => getSelectedCompanyCountryTag(state));

    return (
        <div className='bill-module-container'>
            <div className='bill-enterprise-title'>
                <div className='bill-module-content'>
                    {enterpriseData?.avatar ? (
                        <Avatar size='lg' avatar={enterpriseData.avatar} />
                    ) : (
                        <div className='avatar'>
                            <Icon name={sectorIcon[enterpriseData?.sector ?? "other"]} color='lilas' />
                        </div>
                    )}
                    <div className='d-flex flex-column'>
                        {enterpriseData?.isPublic ? (
                            <Tag icon='check-circle' color='success' label={t<string>("Bills.active")} type='accent' />
                        ) : (
                            <Tag
                                icon='x-circle'
                                color='error'
                                label={t<string>("Expense-policy.disabled")}
                                type='accent'
                            />
                        )}
                        <div className='d-flex'>
                            <Typography className='fw-bold' message={company?.name ?? ""} />
                            <div className='dot-container mr-2 ml-2'>
                                <div className='dot' />
                            </div>
                            <Typography message={`#ID : ${enterpriseData?.billerReference ?? ""}`} />
                        </div>
                    </div>
                </div>
                <Button onClick={setOpen} icon='gear' variant='secondary' label={t("Bills.modify-profile")} />
            </div>

            <div className='divider' />
            <div className='bill-module-footer'>
                <div className='bill-tag'>
                    <Icon className='color-neutral-500' name={sectorIcon[enterpriseData?.sector ?? "other"]} />
                    <Typography
                        className='fw-bold bill-background-primary'
                        message={t(`Bills.sector-${enterpriseData?.sector ?? "other"}`)}
                    />
                </div>
                <div className='bill-tag'>
                    <Icon className='color-neutral-500' name='wallet' />
                    <Typography
                        className='fw-bold'
                        message={`${wallet?.label ? `${wallet?.label} - ` : ""}${
                            enterpriseData?.billsWalletReference ?? ""
                        }`}
                    />
                </div>
                {companyCountryTag ? (
                    <div className='bill-tag'>
                        <Icon className='color-neutral-500' name='globe' />
                        <Typography className='fw-bold' message={t(billingCountryLabel[companyCountryTag ?? ""])} />
                    </div>
                ) : null}
            </div>
            <div className='bill-description'>
                <Icon className='color-neutral-500' name='information-circle' />
                <Typography message={enterpriseData?.description ?? "-"} />
            </div>
        </div>
    );
}
