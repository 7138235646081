import { CompanyRole, CountriesTag } from "../models/UserCompany";
import { Navigate, RouteProps } from "react-router-dom";

import { ComponentType, FC, useMemo } from "react";

import { getSelectedCompanyCountryTag } from "src/modules/auth/selectors";
import { useAuthUserSelectedCompany } from "src/modules/authCompanies/hooks";
import { useAppSelector } from "src/store";

type Props = {
    allowedRoles: CompanyRole[];
    disallowedCountries?: CountriesTag[];
    redirectUrl?: { [key in CompanyRole]: string };
    noRedirection?: boolean;
};

const defaultRedirection = {
    [CompanyRole.ADMIN]: "/dashboard",
    [CompanyRole.ADMIN_ONLY]: "/admin",
    [CompanyRole.COLLECTOR]: "/dashboard",
    [CompanyRole.READ]: "/dashboard",
    [CompanyRole.USER]: "/dashboard",
};

export const withAuthorization = ({
    allowedRoles,
    disallowedCountries,
    redirectUrl = defaultRedirection,
    noRedirection = false,
}: Props) =>
    function <T>(WrappedComponent: ComponentType<T>) {
        const WithAuthorizationWrapper: FC<RouteProps & T> = (props) => {
            const userRole = useAuthUserSelectedCompany()?.role;

            const companyCountryTag = useAppSelector((state) => getSelectedCompanyCountryTag(state));

            const isAuthorized = useMemo(
                () =>
                    allowedRoles.some((allowedRole) => userRole === allowedRole) &&
                    companyCountryTag &&
                    (!disallowedCountries || !disallowedCountries.includes(companyCountryTag)),
                [userRole, companyCountryTag]
            );

            return !isAuthorized && userRole ? (
                noRedirection ? (
                    <></>
                ) : (
                    <Navigate to={redirectUrl[userRole]} />
                )
            ) : (
                <WrappedComponent {...props} />
            );
        };
        return WithAuthorizationWrapper;
    };
