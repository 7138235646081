import { ReactNode, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { Typography } from "../../atoms/Typography/Typography";
import { Paginate, PaginateProps } from "src/shared/atoms/Pagination/Pagination";
import { PaginationDynamo, PaginationDynamoProps } from "src/shared/atoms/Pagination/PaginationDynamo";

import "./styles.scss";

const defaultLoadingRows = 20;

type TableProps = {
    header: (String | ReactNode)[];
    rows: ReactNode[];
    pagination?: PaginateProps;
    paginationDynamo?: PaginationDynamoProps;
    loadingRows?: number;
    isLoading?: boolean;
    clickable?: boolean;
    empty?: ReactNode;
};

export const Table = ({
    header,
    rows,
    loadingRows = defaultLoadingRows,
    pagination,
    paginationDynamo,
    isLoading = false,
    clickable,
    empty,
}: TableProps) => {
    const placeHolderArray = useMemo(() => Array.from(Array(loadingRows).keys()), [loadingRows]);
    const { t } = useTranslation();

    const handlePageChange = (page: number) => {
        if (pagination?.onPageChange) {
            pagination.onPageChange(page);
        }
    };

    const handlePageSizeChange = (newPage: number) => {
        if (pagination?.onPageSizeChange) {
            pagination.onPageSizeChange(newPage);
        }
    };

    return (
        <>
            <table className={`table-container ${clickable ? "clickable" : ""}`} data-testid='table'>
                <thead>
                    <tr>
                        {header.map((headerItem, index) => {
                            return (
                                <th key={index}>
                                    {typeof headerItem === "string" ? (
                                        <Typography variant={"span"} size={"sm"} message={headerItem} />
                                    ) : (
                                        headerItem
                                    )}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {isLoading
                        ? placeHolderArray.map((placeholderIndex) => {
                              return (
                                  <tr
                                      key={placeholderIndex}
                                      className={`table-placeholder skeleton-delay-${placeholderIndex}`}
                                      data-testid={`table-placeholder-${placeholderIndex}`}
                                  >
                                      {header.map((value, index) => (
                                          <td key={`${value}, ${index}`}></td>
                                      ))}
                                  </tr>
                              );
                          })
                        : rows}
                </tbody>
            </table>
            {!isLoading && rows.length === 0 ? (
                empty ? (
                    empty
                ) : (
                    <Typography
                        size='lg'
                        variant='span'
                        className='d-flex w-100 justify-content-center'
                        message={t("Table.aucun-resultat-trouve")}
                    />
                )
            ) : null}
            {pagination && (
                <Paginate
                    totalCount={pagination?.totalCount}
                    currentPage={pagination?.currentPage}
                    pageSize={pagination?.pageSize}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    isLoading={isLoading}
                />
            )}
            {paginationDynamo && (
                <PaginationDynamo
                    onPageChange={paginationDynamo.onPageChange}
                    hasMoreItems={paginationDynamo.hasMoreItems}
                    currentPage={paginationDynamo.currentPage}
                    canGoBack={paginationDynamo.canGoBack}
                    isLoading={isLoading}
                />
            )}
        </>
    );
};
