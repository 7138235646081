import { middlewareRequest } from "../utils/request";
import { AuthCompany } from "src/shared/models/AuthCompany";

export async function requestAuthUserCompanies() {
    try {
        return await middlewareRequest<AuthCompany[]>({ endpoint: "/me/companies" });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestCheckJoinCompanyToken(params: { token: string }) {
    try {
        return await middlewareRequest<{ name: string }>({
            endpoint: "/auth/token",
            method: "POST",
            params,
            noCredentials: true,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}
