import { useParams, useSearchParams } from "react-router-dom";
import { requestCompanyInfos } from "src/services/payments/operations";
import { CompanyInfos } from "src/services/payments/types";
import { API_URL } from "src/services/utils/const";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Input } from "src/shared/atoms/Inputs/Input";
import { InputPhone, InputPhoneValue } from "src/shared/atoms/Inputs/Phone/Phone";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

const successImage = require("src/shared/images/payment/payment-success.png");
const failureImage = require("src/shared/images/payment/payment-failure.png");

const ACCEPTED_PREFIXES = ["00221", "00225", "00228", "00229"];

function parsePhone(phone: string) {
    for (const prefix of ACCEPTED_PREFIXES) {
        if (phone.startsWith(prefix)) {
            return {
                prefix,
                localNumber: phone.slice(prefix.length),
            };
        }
    }
    return null;
}
interface FormData {
    fullname: string;
    comment: string;
    phone: string;
    amount: number;
}

const initializeFields = (
    searchParams: URLSearchParams
): { fields: FormData; isDisabled: Record<keyof FormData, boolean> } => {
    const initialFields: FormData = {
        fullname: searchParams.get("fullname") || "",
        comment: searchParams.get("comment") || "",
        phone: searchParams.get("phone") || "",
        amount: parseFloat(searchParams.get("amount") || ""),
    };

    const initialDisabled: Record<keyof FormData, boolean> = {
        fullname: searchParams.has("fullname"),
        comment: searchParams.has("comment"),
        phone: searchParams.has("phone"),
        amount: searchParams.has("amount"),
    };

    return { fields: initialFields, isDisabled: initialDisabled };
};

export const PaymentPanel = () => {
    const { t } = useTranslation();
    const { reference = "" } = useParams<{ type: string; reference: string }>();
    const [searchParams] = useSearchParams();

    const { fields: initialFields, isDisabled: initialDisabled } = initializeFields(searchParams);

    const [fields, setFields] = useState<FormData>(initialFields);
    const [errors, setErrors] = useState<Partial<Record<keyof FormData, string>>>({});
    const [isDisabled, setIsDisabled] = useState(initialDisabled);
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showFailure, setShowFailure] = useState(false);
    const [companyInfos, setCompanyInfos] = useState<CompanyInfos>();

    // We'll track the phone in InputPhoneValue form
    const [phoneValue, setPhoneValue] = useState<InputPhoneValue>({
        prefix: "",
        value: "",
    });

    useEffect(() => {
        if (reference) {
            requestCompanyInfos({ reference }).then((companyInfos) => {
                setCompanyInfos(companyInfos);
            });
        }
    }, [reference]);

    useEffect(() => {
        const loadScript = (src: string) => {
            return new Promise<void>((resolve, reject) => {
                if (document.querySelector(`script[src="${src}"]`)) {
                    resolve();
                    return;
                }
                const script = document.createElement("script");
                script.src = src;
                script.async = true;
                script.onload = () => resolve();
                script.onerror = () => reject();
                document.body.appendChild(script);
            });
        };

        const loadScripts = async () => {
            try {
                await loadScript("https://code.jquery.com/jquery.min.js");
                await loadScript("https://paydunya.com/assets/psr/js/psr.paydunya.min.js");
            } catch (error) {
                console.error("❌ Échec du chargement des scripts:", error);
            }
        };

        loadScripts();
    }, []);

    useEffect(() => {
        const { fields: newFields, isDisabled: newDisabled } = initializeFields(searchParams);
        const parsedPhone = parsePhone(newFields.phone);

        if (parsedPhone) {
            setPhoneValue({
                prefix: parsedPhone.prefix,
                value: parsedPhone.localNumber,
            });
            newFields.phone = parsedPhone.localNumber;
            newDisabled.phone = true;
        } else {
            setPhoneValue({
                prefix: "",
                value: newFields.phone,
            });
            newDisabled.phone = false;
        }

        setFields(newFields);
        setIsDisabled(newDisabled);
    }, [searchParams]);

    const handleChangePhone = (value: InputPhoneValue) => {
        setPhoneValue(value);
        setFields((prev) => ({
            ...prev,
            phone: value.value || "",
        }));
    };

    const handleChangeInput = (name: keyof Omit<FormData, "phone">) => (value: string) => {
        setFields((prev) => ({ ...prev, [name]: value }));
    };

    const validateFields = (): boolean => {
        const newErrors: Partial<Record<keyof FormData, string>> = {};

        if (!fields.fullname.trim()) {
            newErrors.fullname = "Le nom est requis";
        }
        if (!fields.phone.trim()) {
            newErrors.phone = "Le téléphone est requis";
        }
        if (fields.amount == null) {
            newErrors.amount = "Le montant est requis";
        }
        if (fields.amount <= 0) {
            newErrors.amount = "Le montant doit être supérieur à 0";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateFields()) return;

        setIsLoading(true); // Start loading

        try {
            if ((window as any).PayDunya && (window as any).$) {
                (window as any).PayDunya.setup({
                    selector: (window as any).$(".payment-button"),
                    url:
                        `${API_URL}/payment/token?amount=${fields.amount}&reference=${reference}&fullname=${fields.fullname}` +
                        (fields.comment ? `&comment=${fields.comment}` : ""),
                    method: "POST",
                    displayMode: (window as any).PayDunya.DISPLAY_IN_POPUP,
                    beforeRequest: () => {},
                    onSuccess: () => {},
                    onTerminate: () => {
                        setShowSuccess(true);
                        setIsLoading(false);
                    },
                    onError: () => {
                        setShowFailure(true);
                        setIsLoading(false);
                    },
                    onUnsuccessfulResponse: () => {
                        setShowFailure(true);
                        setIsLoading(false);
                    },
                    onClose: () => {
                        setIsLoading(false);
                    },
                }).requestToken();
            } else {
                throw new Error("PayDunya not loaded");
            }
        } catch (error) {
            console.error("Payment failed:", error);
            setIsLoading(false);
        }
    };

    return (
        <div className='payment-panel-container'>
            <div className='content-wrapper'>
                <img src={require("src/shared/images/logo/julaya_pro.png")} alt='julaya logo' className='julaya-logo' />
                <div className='form-card'>
                    {showSuccess ? (
                        <div className='result-content'>
                            <div className='d-flex justify-content-center'>
                                <img src={successImage} alt='success' />
                            </div>
                            <div className='d-flex flex-column align-items-center mt-4'>
                                <Typography
                                    variant='h5'
                                    size='sm'
                                    message={t("PaymentPanel.success-title")}
                                    className='fw-bold'
                                />
                                <Typography variant='p' message={t("PaymentPanel.success-subtitle")} />
                                <Typography variant='h2' message={fields.amount + " FCFA"} className='mt-4' />
                            </div>
                        </div>
                    ) : showFailure ? (
                        <div className='result-content'>
                            <div className='d-flex justify-content-center'>
                                <img src={failureImage} alt='failure' />
                            </div>
                            <div className='d-flex flex-column align-items-center mt-4'>
                                <Typography
                                    variant='h5'
                                    size='sm'
                                    message={t("PaymentPanel.failure-title")}
                                    className='fw-bold'
                                />

                                <Typography variant='p' message={t("PaymentPanel.failure-subtitle")} />

                                <Button
                                    icon='arrow-left-filled'
                                    onClick={() => {
                                        window.location.reload();
                                    }}
                                    className='mt-4'
                                    label={t("PaymentPanel.failure-button")}
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            <Typography
                                message={t("PaymentPanel.title", {
                                    name: companyInfos ? companyInfos.companyName : "",
                                })}
                                className='fw-bold'
                            />
                            {companyInfos?.collectorName && (
                                <Typography
                                    className='mt-2'
                                    message={t("PaymentPanel.subtitle", { name: companyInfos.collectorName })}
                                />
                            )}
                            <div className='mb-4'></div>
                            <div className='divider drawer-divider mb-4' />
                            <form className='form-container' onSubmit={handleSubmit}>
                                <Input
                                    type='text'
                                    name='fullname'
                                    label={t("CommonUse.name").toString()}
                                    placeholder={t("CommonUse.name-placeholder").toString()}
                                    value={fields.fullname}
                                    onChange={handleChangeInput("fullname")}
                                    required
                                    disabled={isDisabled.fullname}
                                    variant={errors.fullname ? "error" : "primary"}
                                    underMessage={errors.fullname}
                                />

                                <InputPhone
                                    label={t("CommonUse.phone-number").toString()}
                                    placeholder={t("CommonUse.phone-number-placeholder")}
                                    onChange={handleChangePhone}
                                    disablePhonePrefix={isDisabled.phone}
                                    value={phoneValue.value}
                                    prefix={phoneValue.prefix}
                                    disabled={isDisabled.phone}
                                    variant={errors.phone ? "error" : "primary"}
                                    underMessage={errors.phone}
                                />

                                <Input
                                    type='number'
                                    label={t("PaymentPanel.amount-to-pay").toString()}
                                    disabled={isDisabled.amount}
                                    name='amount'
                                    placeholder='0 FCFA'
                                    value={fields.amount}
                                    onChange={handleChangeInput("amount")}
                                    required
                                    variant={errors.amount ? "error" : "primary"}
                                    underMessage={errors.amount}
                                />

                                <Input
                                    type='text'
                                    name='comment'
                                    label={t("CommonUse.comment").toString()}
                                    placeholder={t("CommonUse.comment-placeholder").toString()}
                                    value={fields.comment}
                                    onChange={handleChangeInput("comment")}
                                    required
                                    disabled={isDisabled.comment}
                                    variant={errors.comment ? "error" : "primary"}
                                    underMessage={errors.comment}
                                />

                                <Button
                                    loading={isLoading}
                                    disabled={isLoading}
                                    onClick={handleSubmit}
                                    className='payment-button'
                                    label={t("PaymentPanel.button", {
                                        amount: formatMoneyToString({ amount: fields.amount, zeroValue: true }),
                                    })}
                                    testId='payment-button'
                                    data-ref={reference}
                                    data-amount={fields.amount ? fields.amount : ""}
                                    data-fullname={fields.fullname ? fields.fullname : ""}
                                    data-phone={fields.phone ? fields.phone : ""}
                                />
                            </form>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
