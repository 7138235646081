import { activityLogActionSlugs } from "src/services/admin/activityLogs/const";

import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useFetchAdminActivityLogs } from "src/modules/admin/activityLogs/hooks";
import {
    getAdminActivityLogIsFilterChanged,
    getAdminActivityLogsActionSlugFilter,
    getAdminActivityLogsDateFilter,
    getAdminActivityLogsQueryFilter,
    getAdminActivityLogsUserFilter,
} from "src/modules/admin/activityLogs/selectors";
import {
    clearAdminActivityLogs,
    resetAdminActivityLogsFilters,
    setAdminActivityDateFilter,
    setAdminActivityLogsActionSlugFilter,
    setAdminActivityLogsUserFilter,
    setAdminActivityQueryFilter,
} from "src/modules/admin/activityLogs/slice";
import { useAdminAccountsAsSelectOptions } from "src/modules/admin/usersList/hooks";
import { useAppDispatch, useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Select } from "src/shared/atoms/Select/Select";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { CustomDatePicker } from "src/shared/components/DatePicker/DatePicker";
import { SelectMultipleOptions, SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

export function AdminActivityLogsFilters() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [closeSelect, setCloseSelect] = useState(false);

    const actionSlugsFilter = useAppSelector((state) => getAdminActivityLogsActionSlugFilter(state));
    const usersFilter = useAppSelector((state) => getAdminActivityLogsUserFilter(state));
    const dateFilter = useAppSelector((state) => getAdminActivityLogsDateFilter(state));
    const query = useAppSelector((state) => getAdminActivityLogsQueryFilter(state) ?? "");
    const userOptions = useAdminAccountsAsSelectOptions(true)?.sort((a, b) => a.label.localeCompare(b.label));

    const isFilterChanged = useAppSelector((state) => getAdminActivityLogIsFilterChanged(state));

    const handleFetchActivityLog = useFetchAdminActivityLogs();

    useEffect(() => {
        if (closeSelect) {
            handleFetchActivityLog();
            setCloseSelect(false);
        }
    }, [closeSelect]);

    const handleSetQueryFilter = useCallback(
        (newQuery: string) => {
            dispatch(setAdminActivityQueryFilter(newQuery));
        },
        [dispatch]
    );

    const handleSetActionSlugsFilter = (value: SelectOption[]) => {
        dispatch(setAdminActivityLogsActionSlugFilter(value));
    };

    const handleSetUsersFilter = (value: SelectOption[]) => {
        dispatch(setAdminActivityLogsUserFilter(value));
    };

    const handleSetDateFilter = (value: Date | null) => {
        dispatch(setAdminActivityDateFilter(value));
    };

    const handleResetFilters = () => {
        dispatch(resetAdminActivityLogsFilters());
        dispatch(clearAdminActivityLogs());
        setCloseSelect(true);
    };

    const handleValidateFilters = () => {
        dispatch(clearAdminActivityLogs());
        setCloseSelect(true);
    };

    return (
        <>
            <Input
                placeholder={t("CommonUse.search")}
                inputIcon='magnifying-glass'
                onChange={handleSetQueryFilter}
                value={query}
                className='search-input'
            />
            <div className='select-filter'>
                <Select
                    forceClose={closeSelect}
                    color='white'
                    content={{
                        header: {
                            title: t<string>("AdminActivity.filter"),
                            icon: "adjustments-horizontal",
                            disabled: false,
                        },
                        dropDownContent: {
                            header: {
                                title: <Typography message={t("CommonUse.filters")} />,
                                label: (
                                    <Button
                                        variant='ghost'
                                        color='lilas'
                                        onClick={handleResetFilters}
                                        label={<Typography message={t("AdminWalletsFilters.reinitialiser")} />}
                                    />
                                ),
                            },
                            body: (
                                <div className='select-filter-content'>
                                    <SelectMultipleOptions
                                        scrollable
                                        showSelectedItems={false}
                                        name={`${t("AdminActivity.filter-by-action")} ${
                                            actionSlugsFilter?.length ? "(" + actionSlugsFilter?.length + ")" : ""
                                        }`}
                                        selected={actionSlugsFilter}
                                        items={activityLogActionSlugs(t)}
                                        onSelectionChange={handleSetActionSlugsFilter}
                                        icon='cursor-arrow-rays'
                                    />
                                    <SelectMultipleOptions
                                        scrollable
                                        showSelectedItems={false}
                                        name={`${t("AdminActivity.filter-by-user")} ${
                                            usersFilter?.length ? "(" + usersFilter?.length + ")" : ""
                                        }`}
                                        selected={usersFilter}
                                        items={userOptions}
                                        onSelectionChange={handleSetUsersFilter}
                                        icon='user-circle'
                                    />
                                    <CustomDatePicker
                                        single
                                        startDate={dateFilter}
                                        onChangeStartDate={handleSetDateFilter}
                                    />
                                </div>
                            ),
                            footer: (
                                <div className='footer-content'>
                                    <Button
                                        variant='tertiary'
                                        color='primary'
                                        className='flex-grow-1'
                                        onClick={handleResetFilters}
                                        label={t("CommonUse.cancel")}
                                    />
                                    <Button
                                        variant='primary'
                                        className='flex-grow-1'
                                        onClick={handleValidateFilters}
                                        label={t("CommonUse.validate")}
                                        disabled={!isFilterChanged}
                                    />
                                </div>
                            ),
                        },
                    }}
                />
            </div>
        </>
    );
}
