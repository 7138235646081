import { middlewareRequest } from "../utils/request";
import {
    AdminBeneficiaryQuery,
    AdminBeneficiaryResponse,
    AdminCreateWalletResponse,
    AdminDashboardInfoResponse,
    AdminWalletApiFilters,
    AdminWalletCreateParams,
    AdminWalletResponse,
    AdminWalletUpdateParams,
    ArchiveWalletParams,
    CreateUserDetailResponse,
    CreateUserParams,
    PhoneParams,
    UserDetailsResponse,
    UserDocumentParams,
    UserRequestParams,
} from "./types";
import { DocumentModel } from "src/shared/models/Document";
import { AdminWalletModel } from "src/shared/models/Wallet";
import { encodeQueryParameters } from "src/shared/utils/network";

import { CreateBeneficiaryPayload } from "src/components/Admin/Beneficiaries/CreateDrawer/Container";

export async function requestGetUserDocument({ userId, countryOfCitizenship }: UserDocumentParams) {
    try {
        if (userId) {
            return await middlewareRequest<DocumentModel>({
                endpoint: `/users/${userId}/documents`,
            });
        } else if (countryOfCitizenship) {
            return await middlewareRequest<DocumentModel>({
                endpoint: `/documents`,
                method: "POST",
                params: { countryOfCitizenship: countryOfCitizenship.toUpperCase() },
            });
        }
        return Promise.resolve(null);
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestMiddlewareAdminWallets(filters?: AdminWalletApiFilters) {
    try {
        return await middlewareRequest<AdminWalletResponse>({
            endpoint: `/admin/wallets/list`,
            method: "POST",
            params: filters,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestMiddlewareAdminWalletById(walletId: number) {
    try {
        return await middlewareRequest<AdminWalletModel>({
            endpoint: `/admin/wallets/${walletId}`,
            method: "GET",
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestGetAdminDashboardInfo() {
    try {
        return await middlewareRequest<AdminDashboardInfoResponse>({
            endpoint: `/admin/dashboard`,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestGetUsersList(params: UserRequestParams) {
    try {
        return await middlewareRequest<UserDetailsResponse>({
            endpoint: "/admin/users/list",
            method: "POST",
            params: params,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestGetUserDetail(userId: number | string) {
    try {
        return await middlewareRequest<CreateUserDetailResponse>({
            endpoint: `/admin/users/${userId}`,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestGetAdminBeneficiaries(query: AdminBeneficiaryQuery) {
    try {
        return await middlewareRequest<AdminBeneficiaryResponse>({
            endpoint: `/admin/beneficiaries/list?${encodeQueryParameters(query)}`,
            method: "GET",
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestPostAdminBeneficiary(payload: CreateBeneficiaryPayload) {
    try {
        return await middlewareRequest<boolean>({
            endpoint: `/admin/beneficiaries`,
            method: "POST",
            params: payload,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestPostBulkAdminBeneficiary(payload: any) {
    try {
        return await middlewareRequest<boolean>({
            endpoint: `/admin/beneficiaries/add`,
            method: "POST",
            params: payload,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestPutAdminBeneficiary(id: number, payload: any) {
    try {
        return await middlewareRequest<boolean>({
            endpoint: `/admin/beneficiaries/${id}`,
            method: "PUT",
            params: payload,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestDeleteAdminBeneficiary(payload: any) {
    try {
        return await middlewareRequest<{ ok: boolean }>({
            endpoint: `/admin/beneficiaries/delete`,
            method: "POST",
            params: payload,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestPostIfUserExist(phone: PhoneParams) {
    try {
        return await middlewareRequest<CreateUserDetailResponse | null>({
            endpoint: "/admin/users/find",
            method: "POST",
            params: phone,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestPostCreateUser(user: CreateUserParams) {
    try {
        return await middlewareRequest<CreateUserDetailResponse>({
            endpoint: "/admin/users/invite",
            method: "POST",
            params: user,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export type CreateCollectorParams = {
    firstname: string;
    lastname: string;
    customReference?: string;
    countryCode: string;
    phone: string;
    services: string[];
    walletId: number;
    password: string;
    otp?: string | null;
};

export async function requestPostCreateCollector(user: CreateCollectorParams) {
    try {
        return await middlewareRequest<CreateUserDetailResponse>({
            endpoint: "/admin/users/collectors",
            method: "POST",
            params: user,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export type RequestPostCreateUserFromInvitationProps = {
    firstname: string;
    lastname: string;
    gender: string;
    countryOfCitizenship: string;
    dateOfBirth: Date;
    password: string;
    token: string;
    countryCode?: string;
    phone?: string;
};

export async function requestPostCreateUserFromInvitation(user: RequestPostCreateUserFromInvitationProps) {
    try {
        return await middlewareRequest<CreateUserDetailResponse>({
            endpoint: "/auth/users/register",
            method: "POST",
            params: user,
            noCredentials: true,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestPostUserJoinCompanyFromInvitation(token: string) {
    try {
        return await middlewareRequest<CreateUserDetailResponse>({
            endpoint: "/auth/users/join",
            method: "POST",
            params: { token },
            noCredentials: true,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestPutUpdateUser(userId: number, user: CreateUserParams) {
    try {
        return await middlewareRequest<CreateUserDetailResponse>({
            endpoint: `/admin/users/${userId}/edit`,
            method: "PUT",
            params: { ...user, ...(user.dateOfBirth ? { dateOfBirth: new Date(user.dateOfBirth).toISOString() } : {}) },
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestChangeUserActiveStatus(userId: number[], params: CreateUserParams) {
    try {
        return await middlewareRequest<boolean>({
            endpoint: `/admin/users/activate`,
            method: "POST",
            params: { users: userId, ...params },
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestArchiveUser(userId: number, params: CreateUserParams) {
    try {
        return await middlewareRequest<boolean>({
            endpoint: `/admin/users/archive`,
            method: "POST",
            params: { users: [userId], ...params },
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestPostCreateAdminWallet(params: AdminWalletCreateParams) {
    try {
        return await middlewareRequest<AdminCreateWalletResponse>({
            endpoint: `/admin/wallets`,
            method: "POST",
            params: params,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestUpdateAdminWallet(walletId: number, params: AdminWalletUpdateParams) {
    try {
        return await middlewareRequest<AdminCreateWalletResponse>({
            endpoint: `/admin/wallets/${walletId}`,
            method: "PUT",
            params: params,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestDeleteAdminWallet(id: number, data: ArchiveWalletParams) {
    try {
        return await middlewareRequest({
            endpoint: `/admin/wallets/${id}/archive`,
            method: "POST",
            params: data,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}
