import { LoginDisplays } from "../Panel";
import { LoginSuccess } from "../Success";
import { CreateFormSteps } from "./Panel";
import { useNavigate } from "react-router-dom";
import { RequestPostCreateUserFromInvitationProps } from "src/services/admin/operations";
import { CreateUserDetailResponse } from "src/services/admin/types";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { InputPhone, InputPhoneValue } from "src/shared/atoms/Inputs/Phone/Phone";
import { Typography } from "src/shared/atoms/Typography/Typography";

type Props = {
    phone: InputPhoneValue;
    handleSetPhone: (value: InputPhoneValue) => void;
    handleSetFormToDisplay: (switchName: LoginDisplays) => () => void;
    setCreateFormToDisplay: (switchName: CreateFormSteps) => void;
    handleOnSubmit: (
        additionalData?: Partial<RequestPostCreateUserFromInvitationProps>
    ) => Promise<CreateUserDetailResponse | boolean>;
};

export const RegisterPhone = ({
    phone,
    handleSetFormToDisplay,
    setCreateFormToDisplay,
    handleOnSubmit,
    handleSetPhone,
}: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState(false);

    const handleSendUser = (phoneValidation?: true) => () => {
        try {
            setLoading(true);
            handleOnSubmit(
                phoneValidation && {
                    countryCode: phone.prefix,
                    phone: phone.value,
                }
            )
                .then(() => {
                    if (phoneValidation) {
                        setCreateFormToDisplay("phoneVerification");
                    } else {
                        setSuccess(true);
                    }
                })
                .catch((error) => setError(error.message))
                .finally(() => setLoading(false));
        } catch {
            setLoading(false);
        }
    };

    const handleRedirect = () => {
        navigate("/");
        handleSetFormToDisplay("login")();
    };

    return success ? (
        <LoginSuccess
            title={"UserCreateDrawerContainer.create-account-success"}
            description={"UserCreateDrawerContainer.create-account-succes-description"}
            successAction={handleRedirect}
            successLabel={"LoginPasswordReset.go-back-to-login"}
        />
    ) : (
        <div className='d-flex flex-column gap-3'>
            <Typography variant='h1' className='fw-bold' message={t("UserCreateDrawerContainer.verify-phone-number")} />
            <Typography
                className='color-neutral-500 fw-bold'
                message={t("UserCreateDrawerContainer.verify-phone-number-description")}
            />
            <InputPhone
                prefix={phone.prefix}
                value={phone.value}
                label={t<string>("UserCreateDrawerContainer.we-send-verification-to")}
                placeholder={t("CommonUse.phone-number")}
                onChange={handleSetPhone}
            />
            {error && (
                <div className='d-flex gap-2 align-items-center'>
                    <Icon name='x-circle-filled' color='error' />
                    <Typography className='color-error-500' message={t(error)} />
                </div>
            )}
            <div className='d-flex gap-2'>
                <Button
                    disabled={loading}
                    className='w-100'
                    variant='tertiary'
                    label={t("LoginPanel.verify-later")}
                    onClick={handleSendUser()}
                />
                <Button
                    disabled={loading || (phone.value ?? "")?.length === 0}
                    className='w-100'
                    label={t("UserCreateDrawerContainer.receive-code")}
                    onClick={handleSendUser(true)}
                />
            </div>
        </div>
    );
};
