import moment from "moment";

import { createSelector } from "@reduxjs/toolkit";
import i18next from "i18next";

import { RootState } from "src/store";

export const getStatsState = (state: RootState) => state.stats;

export const getWalletTransactionsStats = createSelector(
    getStatsState,
    (statsState) => statsState.walletTransactionsStats
);
export const getWalletTransactionsStatsLoading = createSelector(
    getStatsState,
    (statsState) => statsState.walletTansactionStatsLoading
);
export const getPieChartFormatedWalletTransactionsStats = createSelector(
    getWalletTransactionsStats,
    (walletTransactionsStats) => {
        if (!walletTransactionsStats.stats.length) {
            return null;
        }

        const isAllZero = walletTransactionsStats.stats.every((stat) => stat.amountIn === 0 && stat.amountOut === 0);

        if (isAllZero) {
            return null;
        }

        return [
            {
                id: i18next.t("Analytics.transactions-out"),
                label: i18next.t("Analytics.transactions-out"),
                value: walletTransactionsStats.totalOut,
                color: "#FF1744",
            },
            {
                id: i18next.t("Analytics.transactions-in"),
                label: i18next.t("Analytics.transactions-in"),
                value: walletTransactionsStats.totalIn,
                color: "#00C853",
            },
        ];
    }
);
export const getBarChartFormatedWalletTransactionsStats = createSelector(
    getWalletTransactionsStats,
    (walletTransactionsStats) => {
        if (!walletTransactionsStats.stats.length) {
            return null;
        }

        const isAllZero = walletTransactionsStats.stats.every((stat) => stat.amountIn === 0 && stat.amountOut === 0);

        if (isAllZero) {
            return null;
        }

        return walletTransactionsStats.stats.map((stat) => ({
            date: moment(stat.date).format("DD MMM YYYY"),
            [i18next.t("Analytics.transactions-in")]: stat.amountIn,
            [i18next.t("Analytics.transactions-out")]: stat.amountOut,
        }));
    }
);

export const getCollectorStats = createSelector(getStatsState, (statsState) => statsState.collectorStats);
export const getCollectorStatsLoading = createSelector(getStatsState, (statsState) => statsState.collectorStatsLoading);
export const getBarChartFormatedCollectorStats = createSelector(getCollectorStats, (collectorStats) => {
    if (!collectorStats || !Array.isArray(collectorStats.data)) {
        return null;
    }

    const isAllZero = collectorStats.data.every(
        (stat) => Number(stat.wave_amount) === 0 && Number(stat.express_amount) === 0
    );

    if (isAllZero) {
        return null;
    }

    let sortedData = [...collectorStats.data].sort((a, b) => parseFloat(b.total_amount) - parseFloat(a.total_amount));

    const nameCounts = new Map<string, number>();
    const resultData: any[] = [];

    sortedData.forEach((stat) => {
        const name = stat.fullname || stat.phone || "Unknown";

        const count = nameCounts.get(name) || 0;
        const uniqueName = count > 0 ? `${name} (${count})` : name;
        nameCounts.set(name, count + 1);

        resultData.push({
            collectorName: uniqueName,
            Wave: Number(stat.wave_amount),
            XPRESS: Number(stat.express_amount),
            "Yélé Money": Number(stat.yelemoney_amount),
            "Mixx by Yas": Number(stat.mixx_amount),
        });
    });

    return resultData;
});
