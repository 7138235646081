import { BeneficiaryDrawer } from "../../Beneficiary/Drawer/Container";
import { TasksEditLoading } from "./Loading";
import { TasksEditRecap } from "./Recap";
import { TasksEditSelect } from "./Select";
import { TasksEditFooterDialog } from "./footer/Dialog";
import { EditTaskFooterSummary } from "./footer/Summary";
import { useLocation, useNavigate } from "react-router-dom";
import { defaultTaskItem } from "src/services/taskItems/const";
import { TaskItemModelToCreate } from "src/services/taskItems/types";
import { WalletBeneficiary } from "src/services/wallets/types";
import { CreateTaskItemContext } from "src/shared/context/createTaskItem";
import { TaskItemTmpModel } from "src/shared/models/TaskItemTmp";
import { removeExtraSpaces } from "src/shared/utils/removeExtraSpaces";
import { v4 as uuidv4 } from "uuid";

import { useEffect, useMemo, useState } from "react";

import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { useComputeRowsForEditTable } from "./hooks";
import { useRouterTaskToEdit } from "src/modules/tasks/hooks";
import { getTask, getTaskToEditLoading } from "src/modules/tasks/selectors";
import { fetchTaskToEdit } from "src/modules/tasks/thunkActions";
import { getTaskItemsFileUploading, getTasksItemsIsLoading } from "src/modules/tasksItems/selectors";
import { deleteTaskItem, handleDuplicateTaskitems, handleUpdateTaskItem } from "src/modules/tasksItems/thunkActions";
import { SubscriptionRestrictions, useSubscriptionRestrictions } from "src/shared/hooks/useSubscriptionRestrictions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Empty } from "src/shared/components/Empty/Empty";
import { SelectableTable } from "src/shared/components/SelectableTable/SelectableTable";

import "./styles.scss";

const emptyImage = require("src/shared/images/icons/no-tasks.png");

const header = (t: TFunction<string>) => [
    "",
    t("CommonUse.identifier"),
    t("CommonUse.name"),
    t("CommonUse.amount"),
    t("Tasks.julaya-fees"),
    t("CommonUse.comment"),
    "",
];

type LocationState = {
    duplicateTaskId?: number;
};

export function EditTaskPanel() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const state = useLocation().state as LocationState;

    const [duplicate, setDuplicate] = useState(false);
    const [open, setOpen] = useState(false);
    const [taskItemToEdit, setTaskItemToEdit] = useState<TaskItemTmpModel>();
    const [createdItems, setCreatedItems] = useState<{ [key: string]: TaskItemModelToCreate }>();
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [selectedRows, setSelectedRows] = useState<number[]>();

    const task = useRouterTaskToEdit();

    const taskId = task?.id;

    const taskToDuplicate = useAppSelector((reduxState) =>
        state?.duplicateTaskId ? getTask(reduxState, { taskId: state.duplicateTaskId }) : null
    );
    const taskToEditLoading = useAppSelector((state) => getTaskToEditLoading(state));
    const taskItemsLoading = useAppSelector((state) => getTasksItemsIsLoading(state));
    const taskItemsFileUploading = useAppSelector((state) => getTaskItemsFileUploading(state, { taskId }));
    const isNbTransactionsRestricted = useSubscriptionRestrictions(SubscriptionRestrictions.LIMIT_15_TRANSACTION);

    const contextState = useMemo(() => {
        return {
            taskItems: createdItems,
            setTaskItems: setCreatedItems,
            loading: loadingCreate,
            setLoading: setLoadingCreate,
        };
    }, [createdItems, loadingCreate]);

    const handleOpenDrawer = (taskItem: TaskItemTmpModel) => () => {
        setOpen(true);
        setTaskItemToEdit(taskItem);
    };

    const handleCancelCreateTaskItem = (uuid: string) => () => {
        if (createdItems?.[uuid]) {
            const { [uuid]: toRemove, ...rest } = createdItems;

            setCreatedItems(rest);
        }
    };

    const { rows, taskItems } = useComputeRowsForEditTable({
        handleOpenDrawer,
        handleCancelCreateTaskItem,
        createdItems,
        loadingCreate,
        task,
    });

    useEffect(() => {
        if (!duplicate && taskToDuplicate && task && !task?.metadata?.sum) {
            setDuplicate(true);
            dispatch(handleDuplicateTaskitems({ taskId: task.id, taskToDuplicate }));
        }
    }, [state]);

    useEffect(() => {
        if (!taskToEditLoading && !task) {
            navigate("/dashboard");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskToEditLoading, task]);

    const handleCloseDrawer = () => {
        setOpen(false);
        setTaskItemToEdit(undefined);
        if (!createdItems?.serviceSlug) {
            setCreatedItems(undefined);
        }
    };

    const handleSelectBeneficiary = ({
        serviceSelected: { externalFullname, externalReference, serviceSlug },
        typeSlug,
    }: WalletBeneficiary) => {
        if (
            taskId &&
            taskItemToEdit &&
            (externalFullname !== taskItemToEdit?.externalFullname ||
                externalReference !== taskItemToEdit?.externalReference ||
                serviceSlug !== taskItemToEdit.serviceSlug)
        ) {
            dispatch(
                handleUpdateTaskItem({
                    taskId,
                    taskItem: taskItemToEdit,
                    update: {
                        externalReference,
                        externalFullname: removeExtraSpaces(externalFullname),
                        serviceSlug,
                        typeSlug,
                    },
                })
            ).then(() => dispatch(fetchTaskToEdit({ taskId })));
            handleCloseDrawer();
        }
    };

    const handleSelectBeneficiaryCreateTaskitem = ({
        serviceSelected: { externalFullname, externalReference, serviceSlug },
        typeSlug,
    }: WalletBeneficiary) => {
        setCreatedItems({
            ...createdItems,
            [uuidv4()]: {
                ...defaultTaskItem,
                externalFullname: removeExtraSpaces(externalFullname),
                externalReference,
                serviceSlug,
                typeSlug,
            },
        });
        setOpen(false);
    };

    const handleOpenDrawerCreateTaskItem = () => {
        setTaskItemToEdit(undefined);
        setOpen(true);
    };

    const handleSelectRows = (rows: number[]) => {
        setSelectedRows(rows);
    };

    const handleDeleteTaskItems = () => {
        const newCreateTaskItems = selectedRows
            ?.filter((selectedRow) => rows[selectedRow]?.[0]?.id === "createItem")
            .reduce(
                (res, selectedRow) => {
                    const uuid = rows[selectedRow]?.[0]?.uuid;
                    if (uuid && res?.[uuid]) {
                        delete res[uuid];
                    }
                    return res;
                },
                { ...createdItems }
            );
        setCreatedItems(newCreateTaskItems);

        const tasksFiltered = selectedRows
            ?.filter((selectedRow) => rows[selectedRow]?.[0]?.id !== "createItem")
            ?.reduce<string[]>((res, selectedRow) => {
                const row = rows[selectedRow];
                if (row?.[0]?.id) {
                    res.push(row[0].id);
                }
                return res;
            }, []);

        if (taskId && tasksFiltered?.length) {
            dispatch(deleteTaskItem({ taskId, taskItemIds: tasksFiltered }))
                .unwrap()
                .then(() => dispatch(fetchTaskToEdit({ taskId })));
        }
    };

    const freePlanLimit = isNbTransactionsRestricted && Object.keys(taskItems).length >= 15;
    const disabled = Object.keys(createdItems ?? {}).length !== 0 || freePlanLimit;
    const fileUploading = Object.keys(taskItemsFileUploading ?? {}).length !== 0;

    return (
        <div className='edit-task-container'>
            <CreateTaskItemContext.Provider value={contextState}>
                <div className='d-flex flex-grow-1 gap-4'>
                    {fileUploading ? (
                        <TasksEditLoading taskId={taskId} />
                    ) : rows.length > 0 ? (
                        <div className='w-100'>
                            <SelectableTable
                                setSelectedRows={handleSelectRows}
                                selectedBar={{
                                    itemName: t<string>("Transactions.operation"),
                                    buttonActions: {
                                        fitContent: true,
                                        content: {
                                            header: {
                                                title: t<string>("Transactions.multiple-actions"),
                                                icon: "squares",
                                            },
                                            dropDownContent: {
                                                body: (
                                                    <Button
                                                        label={t("Tasks.delete-transactions")}
                                                        icon='thrash'
                                                        variant='ghost'
                                                        onClick={handleDeleteTaskItems}
                                                        disabled={!selectedRows}
                                                    />
                                                ),
                                            },
                                        },
                                    },
                                }}
                                selectable={true}
                                isLoading={taskItemsLoading && !taskItems}
                                header={header(t)}
                                rows={rows}
                            />
                        </div>
                    ) : (
                        <div className='d-flex flex-column mt-4 align-items-center justify-content-center mb-4 flex-grow-1'>
                            <Empty
                                image={emptyImage}
                                title={t("Tasks.no-transaction-added")}
                                description={t<string>("Tasks.add-transactions-or-upload-file")}
                            />
                            <div className='mt-4'>
                                <TasksEditSelect
                                    showIcon
                                    open={open}
                                    taskId={taskId}
                                    taskItems={taskItems}
                                    handleOpenDrawerCreateTaskItem={handleOpenDrawerCreateTaskItem}
                                    disabled={disabled}
                                />
                            </div>
                        </div>
                    )}
                    <div className='edit-task-recap-recap-section'>
                        <TasksEditSelect
                            open={open}
                            taskId={taskId}
                            taskItems={taskItems}
                            handleOpenDrawerCreateTaskItem={handleOpenDrawerCreateTaskItem}
                            disabled={disabled}
                        />
                        <TasksEditRecap task={task} />
                    </div>
                </div>
            </CreateTaskItemContext.Provider>

            <div className='edit-task-footer'>
                <TasksEditFooterDialog taskId={taskId} />
                <EditTaskFooterSummary taskCreation={Object.values(createdItems ?? {})?.length > 0} taskId={taskId} />
            </div>
            <BeneficiaryDrawer
                isOpen={open}
                walletId={String(task?.walletId)}
                handleSetClose={handleCloseDrawer}
                handleSelectBeneficiary={
                    !taskItemToEdit ? handleSelectBeneficiaryCreateTaskitem : handleSelectBeneficiary
                }
            />
        </div>
    );
}
