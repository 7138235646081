import { type ChangeEvent, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "src/shared/atoms/Typography/Typography";

type Props = {
    label: string;
    file?: File | null;
    loading?: boolean;
    classname?: string;
    handleOnSubmit: (file: File) => void;
    acceptedFiles?: string[];
    maxSizeAllowed?: number;
    maxSizeAllowedLabelError?: string;
    emptySizeErrorLabel?: string;
}

export function FileUploaderButton({ label, acceptedFiles, classname, loading, maxSizeAllowed, maxSizeAllowedLabelError="Profile.picture-size-limit" , emptySizeErrorLabel = "Profile.picture-size-empty", handleOnSubmit }: Readonly<Props>) {
    const inputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();

    const onFileLoaded = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];

        // Calculate the size in MB and keep the decimal places
        const filesize = selectedFile ? (selectedFile.size / 1024 / 1024) : 0;

        if (maxSizeAllowed && (filesize > maxSizeAllowed)) {
            alert(t(maxSizeAllowedLabelError, {maxSizeAllowed, filesize}));
        } else if ( filesize === 0) {
            alert(t(emptySizeErrorLabel, {maxSizeAllowed, filesize}));
        } else if (selectedFile) {
            handleOnSubmit(selectedFile);
            event.target.value = "";
        }
    };

    const handleClickInput = () => {
        if (inputRef.current) {
            inputRef.current?.click();
        }
    };

    return (
        <div className={classname} onClick={handleClickInput}>
            <label className='cursor-pointer' htmlFor='user-photo'>
                <div className='button' data-testid='button-file-uploader'>
                    <Typography message={label} />
                </div>
            </label>
            <input
                disabled={loading}
                ref={inputRef}
                type='file'
                id='file-uploader-button'
                accept={acceptedFiles?.join(",")}
                onChange={onFileLoaded}
                className='file-input-display-none'
                data-testid='input-file-uploader'
            />
        </div>
    );
}
