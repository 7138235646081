import { middlewareRequest } from "../utils/request";
import { CompanyInfos } from "./types";

export async function requestCompanyInfos({ reference }: { reference: string }) {
    try {
        return await middlewareRequest<CompanyInfos>({ noCredentials: true, endpoint: `/payment/infos`, method: "POST", params: {
            reference: reference,
        } });
    } catch (error) {
        return Promise.reject(error);
    }
}