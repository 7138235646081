/* eslint-disable react-hooks/exhaustive-deps */
import { getInStorage } from "../utils/storage";

import { useEffect, useState } from "react";

import { logoutResetStore } from "src/modules/auth/Actions";
import { getAuthUser } from "src/modules/auth/selectors";
import { logout } from "src/modules/auth/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

const events = ["mousedown", "keydown", "apiCall"];

export function useAutoLogout(delayInMinutes: number) {
    const dispatch = useAppDispatch();
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const [inactivityTimer, setInactivityTimer] = useState<NodeJS.Timeout | null>(null);
    const [timeLeft, setTimeLeft] = useState(60);
    const [lastActivity, setLastActivity] = useState<number>(Date.now());

    const authUser = useAppSelector((state) => getAuthUser(state));

    const clearTimer = (providedTimer?: NodeJS.Timeout) => {
        if (timer) {
            clearTimeout(timer);
        }
        if (providedTimer) {
            clearTimeout(providedTimer);
        }
    };

    useEffect(() => {}, [timeLeft]);

    const generateTimer = () => {
        const timer = setTimeout(() => {
            const timeoutId = setInterval(() => {
                return setTimeLeft((timeLeft) => {
                    if (timeLeft > 0) {
                        return timeLeft - 1;
                    }
                    dispatch(logout());
                    return 60;
                });
            }, 1000);
            setInactivityTimer(timeoutId);
        }, delayInMinutes * 60 * 1000);
        setTimer(timer);
        return timer;
    };

    const resetTimer = () => {
        setLastActivity(Date.now());
        if (timeLeft === 60) {
            clearTimer();
            return generateTimer();
        }
        return;
    };

    const handleCloseInactivityModal = () => {
        clearTimer(inactivityTimer ?? undefined);
        setTimeLeft(60);
        return generateTimer();
    };

    const handleLogout = () => {
        clearTimer(inactivityTimer ?? undefined);
        setTimeLeft(60);
        dispatch(logout());
    };

    useEffect(() => {
        const timer = resetTimer();
        return () => clearTimer(timer);
    }, []);

    useEffect(() => {
        events.forEach((eventType) => window.addEventListener(eventType, resetTimer));

        return () => {
            events.forEach((eventType) => window.removeEventListener(eventType, resetTimer));
            clearTimer();
        };
    }, [timer]);

    useEffect(() => {
        const handleStorageChange = () => {
            const storedUser = getInStorage("user");

            if (authUser && storedUser?.id !== authUser.id) {
                dispatch(logoutResetStore());
            }
        };
        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, [authUser, dispatch]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (!document.hidden) {
                const expiryTime = Number(lastActivity + (delayInMinutes + 1) * 60 * 1000);
                const now = Date.now();

                if (now >= expiryTime) {
                    handleLogout();
                }
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => document.removeEventListener("visibilitychange", handleVisibilityChange);
    }, [lastActivity]);

    return { openInactivityModal: timeLeft < 60, timeLeft, handleCloseInactivityModal, handleLogout };
}
