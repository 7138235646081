import { TasksSignatureDialog } from "./Dialog";
import { Link, useParams } from "react-router-dom";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUser } from "src/modules/auth/selectors";
import { useRouterTaskToEdit } from "src/modules/tasks/hooks";
import { useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";

import "../Edit/footer/styles.scss";

type RouteParams = {
    taskId: string;
};

export function SignatureTaskFooterSummary() {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const { taskId } = useParams<keyof RouteParams>() as RouteParams;

    const authUser = useAppSelector((state) => getAuthUser(state));
    const task = useRouterTaskToEdit();

    const canSign = !task?.TaskSignatures?.some((signature) => signature.userId === authUser?.id);

    const handleSwitchOpen = () => {
        setOpen((oldOpen) => !oldOpen);
    };

    return (
        <div className='edit-task-footer-container shadow justify-content-end'>
            <div className='d-flex align-items-center'>
                <Link to='/tasks' className='btn btn-light mr-2'>
                    <Button variant='tertiary' label={t("CommonUse.quit")} />
                </Link>
                <Button
                    className='btn px-4 btn-primary'
                    onClick={handleSwitchOpen}
                    disabled={!canSign}
                    label={t("Tasks.sign")}
                />
                <TasksSignatureDialog open={open} handleSwitchOpen={handleSwitchOpen} taskId={taskId} />
            </div>
        </div>
    );
}
