import { useNavigate } from "react-router-dom";
import { parseCreateTaskFile } from "src/utils/xlsx/parseTask";

import { ChangeEvent, DragEvent, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getTaskToEdit } from "src/modules/tasks/selectors";
import { fetchTaskToEdit } from "src/modules/tasks/thunkActions";
import { handleCreateTaskitems } from "src/modules/tasksItems/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Spinner } from "src/shared/atoms/Spinner/Spinner";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

export function CreateTaskPanel() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const [error, setError] = useState<string | null>(null);

    const taskId = useAppSelector((state) => getTaskToEdit(state)?.id);

    useEffect(() => {
        if (!taskId) {
            navigate("/dashboard");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskId]);

    const handleRedirectToTaskEdit = () => {
        if (taskId) {
            navigate(`/tasks/${taskId}/edit`);
        }
    };

    const onFileLoaded = async (event: ChangeEvent<HTMLInputElement>) => {
        setError(null);
        const selectedFile = event.target.files?.[0];

        if (selectedFile) {
            let reader = new FileReader();
            reader.onload = async function (e) {
                setLoading(true);
                let data = e?.target?.result;
                if (data && taskId) {
                    try {
                        const fileData = parseCreateTaskFile(t, data);

                        setLoading(false);
                        if (typeof fileData === "string") {
                            alert(fileData);
                            setLoading(false);
                        } else if (fileData) {
                            navigate(`/tasks/${taskId}/edit`);

                            dispatch(handleCreateTaskitems({ taskId, taskItems: fileData }))
                                .unwrap()
                                .then(() => dispatch(fetchTaskToEdit({ taskId })))
                                .catch((error) => {
                                    setError(error.message);
                                });
                        } else {
                            setError("Tasks.file-is-not-standart-choose-another");
                        }
                    } catch (e) {
                        setError("Tasks.file-is-not-standart-choose-another");
                        setLoading(false);
                    }
                }
            };
            reader.readAsBinaryString(selectedFile);
            event.target.value = "";
            setLoading(false);
        }
    };

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (event.dataTransfer) {
            event.dataTransfer.dropEffect = "copy";
        }
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files && files.length > 0) {
            const file = files[0];

            onFileLoaded({
                target: { files: [file] },
                preventDefault: event.preventDefault,
            } as unknown as ChangeEvent<HTMLInputElement>);
        }
    };

    const handleRedirectToDashboard = () => {
        navigate("/dashboard");
    };

    return (
        <div className='d-flex w-100 create-task-height  align-items-center'>
            <div className='create-task-container'>
                <div className='content-width'>
                    <Typography
                        variant='h3'
                        className='d-flex justify-content-center fw-bold color-black'
                        message={t("Tasks.create-new-task")}
                    />
                    <Typography className='color-neutral-500 pt-1' message={t("Tasks.create-page-description")} />
                    <div className='pt-4'>
                        <div className='d-flex'>
                            <div
                                onDragOver={loading ? undefined : handleDragOver}
                                onDrop={loading ? undefined : handleDrop}
                                className={`drawer-file-uploader create-task-section-button mr-3 ${
                                    loading ? "create-task-section-button-disabled" : ""
                                }`}
                            >
                                <label className='cursor-pointer' htmlFor='files'>
                                    <div className='d-flex w-100 justify-content-center'>
                                        <div className='create-task-image pb-2'>
                                            {loading ? (
                                                <Spinner size='xl' />
                                            ) : (
                                                <img
                                                    src={require("src/shared/images/transaction-bulk-illustrations/document.png")}
                                                    alt={"Ajouter une opération"}
                                                    className={"img-fluid"}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <Typography className='fw-bold pt-2' message={t("Tasks.already-have-model")} />
                                </label>
                                <input
                                    type='file'
                                    disabled={loading}
                                    id='files'
                                    accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                    onChange={onFileLoaded}
                                    className='file-input-display-none'
                                />
                            </div>
                            <div
                                className={`create-task-section-button ${
                                    loading ? "create-task-section-button-disabled" : ""
                                }`}
                                onClick={loading ? undefined : handleRedirectToTaskEdit}
                                data-testid='create-task-page-continue-without-file'
                            >
                                <div className='create-task-image'>
                                    <img
                                        src={require("src/shared/images/transaction-bulk-illustrations/add.png")}
                                        alt={"Ajouter une opération"}
                                        className={"img-fluid"}
                                    />
                                </div>
                                <Typography className='fw-bold pt-2' message={t("Tasks.continue-without-file")} />
                            </div>
                        </div>
                        {error && (
                            <div className='error-message'>
                                <Icon color='error' name='x-circle-filled' />
                                <Typography className='color-error-500' message={t(error)} />
                            </div>
                        )}

                        <div className='alert-create-task'>
                            <div className='first-part'>
                                <Icon name='question-mark-circle' color='warning' />
                                <Typography message={t("Tasks.download-model")} className='fw-bold' />
                            </div>
                            <a
                                href={`/models/bulk_transfert_modele_julaya.xlsx`}
                                download={t("Tasks.download-model-file-name")}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='btn btn-secondary text-nowrap'
                            >
                                <Button color='warning' label={t("CommonUse.download")} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className='test-section'>
                    <Button
                        onClick={handleRedirectToDashboard}
                        label={t("CommonUse.quit")}
                        variant='tertiary'
                        className='mr-2'
                    />
                    <Button disabled={loading} onClick={handleRedirectToTaskEdit} label={t("CommonUse.continue")} />
                </div>
            </div>
        </div>
    );
}
