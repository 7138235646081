import { middlewareRequest } from "../utils/request";
import { AuthLoginResponse, AuthUserExpensePolicy, CreateCollectorSettingPayload, UserAuthApiReturn } from "./types";
import { fileUploader } from "src/services/utils/fileUploader";
import { HandleSubmitPropsPasswordValidation } from "src/shared/common/Password/Modal";
import { UserModel } from "src/shared/models/User";
import { encryptCode } from "src/shared/utils/encryptCode";

export type LoginProps = {
    identifier: string;
    password: string;
    otp?: string;
};

export async function login(props: LoginProps) {
    try {
        return await middlewareRequest<AuthLoginResponse | { tfaActive: boolean }>({
            endpoint: "/auth/login",
            method: "POST",
            params: props,
            noCredentials: true,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function verifyTwoFactor(props: LoginProps) {
    try {
        return await middlewareRequest<AuthLoginResponse>({
            endpoint: "/auth/check-tfa",
            method: "POST",
            params: props,
            noCredentials: true,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

type ForgotPasswordProps = {
    identifier: string;
};

export async function requestForgotPassword(params: ForgotPasswordProps) {
    try {
        return await middlewareRequest<AuthLoginResponse>({
            endpoint: "/auth/reset/password",
            method: "POST",
            params,
            noCredentials: true,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

type UpdatePasswordProps = {
    password: string;
    token?: string;
    otp?: string;
};

export async function requestUpdatePassword({ password, token }: UpdatePasswordProps) {
    try {
        return await middlewareRequest<AuthLoginResponse>({
            endpoint: "/auth/reset/password/update",
            method: "POST",
            params: {
                password: encryptCode(password),
                token,
            },
            noCredentials: true,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

type RequestUpdateAuthUserPassword = {
    password: string;
    newPassword: string;
    twoFactor?: string;
};

export async function requestUpdateAuthUserPassword({
    password,
    newPassword,
    twoFactor,
}: RequestUpdateAuthUserPassword) {
    try {
        return await middlewareRequest<{ token: string }>({
            endpoint: "/me/password/update",
            method: "POST",
            params: {
                password: encryptCode(password),
                newPassword: newPassword,
                otp: twoFactor,
            },
            noCredentials: true,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function getUser(userId: string) {
    return await middlewareRequest<UserModel>({
        endpoint: `/users/${userId}`,
    });
}

export async function requestAuthUser() {
    return await middlewareRequest<UserAuthApiReturn>({
        endpoint: `/me`,
    });
}

type UpdateAuthUserParams = {
    dateOfBirth?: string;
    countryOfCitizenship?: string;
    email?: string;
    firstname?: string;
    lastname?: string;
    phone?: string;
};

export type RequestUpdateAuthUserReturn = {
    firstname?: string;
    lastname?: string;
    email?: string;
};

export async function requestUpdateAuthUser(params: UpdateAuthUserParams) {
    return await middlewareRequest<RequestUpdateAuthUserReturn>({
        endpoint: `/me/edit`,
        method: "PUT",
        params,
    });
}

export type RequestUpdateAuthUserPhotoReturn = {
    avatar: string;
};

export async function requestUpdateAuthUserPhoto(newFile: File) {
    return await fileUploader<RequestUpdateAuthUserPhotoReturn>({
        endpoint: `/me/edit/avatar`,
        params: {
            avatar: newFile,
        },
    });
}

export type RequestGenerateAuthUserQrCodeReturn = {
    key: string;
    qrCode: string;
};

export async function requestGenerateAuthUserQrCode({ password }: HandleSubmitPropsPasswordValidation) {
    return await middlewareRequest<RequestGenerateAuthUserQrCodeReturn>({
        endpoint: `/me/tfa/generate`,
        method: "POST",
        params: {
            password,
        },
    });
}

export async function requestActivateAuthUserTwoFactor({ password, twoFactor }: HandleSubmitPropsPasswordValidation) {
    return await middlewareRequest<RequestGenerateAuthUserQrCodeReturn>({
        endpoint: `/me/tfa/activate`,
        method: "POST",
        params: {
            password,
            otp: twoFactor,
        },
    });
}

export async function requestDeleteAuthUserTwoFactor({ password, twoFactor }: HandleSubmitPropsPasswordValidation) {
    return await middlewareRequest<RequestGenerateAuthUserQrCodeReturn>({
        endpoint: `/me/tfa/delete`,
        method: "DELETE",
        params: {
            password,
            otp: twoFactor,
        },
    });
}

export async function requestAuthUserExpensePolicies() {
    return await middlewareRequest<{ policies: AuthUserExpensePolicy[] }>({
        endpoint: `/me/expence-policies`,
    });
}

export async function createAuthUserCollectorSettings(params: CreateCollectorSettingPayload) {
    return await middlewareRequest<UserModel["collectorInfo"]>({
        endpoint: `/me/collectors`,
        method: "POST",
        params,
    });
}

export async function updateAuthUserCollectorSettings(params: CreateCollectorSettingPayload) {
    return await middlewareRequest<UserModel["collectorInfo"]>({
        endpoint: `/me/collectors`,
        method: "PUT",
        params,
    });
}

export async function requestVerifyEmail() {
    return await middlewareRequest<{ accessToken: string; emailSent: boolean; to: string }>({
        endpoint: "/me/verify-email",
        method: "POST",
    });
}

export async function sendEmailVerification(params: { token: string }) {
    try {
        return await middlewareRequest<boolean>({
            endpoint: "/user/verify-email-token",
            method: "POST",
            noCredentials: true,
            params,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

type SendPhoneCodeReceivedProps = {
    countryCode: string;
    phone: string;
    otp: string;
};

export async function resendPhoneVerificationCode(params: Pick<SendPhoneCodeReceivedProps, "countryCode" | "phone">) {
    try {
        return await middlewareRequest<boolean>({
            endpoint: "/user/verify-phone",
            method: "POST",
            noCredentials: true,
            params,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function sendPhoneVerificationCodeReceived(params: SendPhoneCodeReceivedProps) {
    try {
        return await middlewareRequest<boolean>({
            endpoint: "/user/verify-phone-otp",
            method: "POST",
            noCredentials: true,
            params,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

type SendCreateCompanyAndUserProps = {
    token: string;
    countryOfCitizenship: string;
    address: {
        city: string;
        addressLine: string;
        postCode: string;
    };
    legalStatus: string;
    revenue: string;
    shareCapital: number;
    sector: string;

    firstname: string;
    lastname: string;
    dateOfBirth: Date;
    countryCode?: string;
    gender: string;
    phone?: string;
    password: string;
};

export async function sendCreateCompanyAndUser(params: SendCreateCompanyAndUserProps) {
    try {
        return await middlewareRequest<boolean>({
            endpoint: "/auth/company/register",
            method: "POST",
            noCredentials: true,
            params,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function sendCreateCompany(
    params: Pick<
        SendCreateCompanyAndUserProps,
        "address" | "legalStatus" | "revenue" | "shareCapital" | "sector" | "token" | "countryOfCitizenship"
    >
) {
    try {
        return await middlewareRequest<boolean>({
            endpoint: "/auth/company/add",
            method: "POST",
            noCredentials: true,
            params,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}
