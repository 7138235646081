import { useTranslation } from "react-i18next";

import {
    getTaskItems,
    getTaskItemsFileUploading,
    getTaskItemsIsUpdatingCount,
    getTaskItemsTotalUploaded,
} from "src/modules/tasksItems/selectors";
import { useAppSelector } from "src/store";

import { Typography } from "src/shared/atoms/Typography/Typography";

const firstImage = require("src/shared/images/transaction-bulk-illustrations/loading-one.png");
const secondImage = require("src/shared/images/transaction-bulk-illustrations/loading-two.png");
const thirdImage = require("src/shared/images/transaction-bulk-illustrations/loading-three.png");

type Props = {
    taskId?: number;
};

export function TasksEditLoading({ taskId }: Props) {
    const { t } = useTranslation();

    const taskItemsLoading = useAppSelector((state) => getTaskItemsFileUploading(state, { taskId }));
    const taskItems = useAppSelector((state) => getTaskItems(state, { taskId }));
    const updateCount = useAppSelector((state) => getTaskItemsIsUpdatingCount(state, { taskId }));
    const taskItemsTotalUploaded = useAppSelector((state) => getTaskItemsTotalUploaded(state) ?? 0);

    const taskItemsLoadingLenght = Object.keys(taskItemsLoading ?? {}).length;
    const total = Object.keys(taskItems).length + taskItemsLoadingLenght;

    const taskItemsUploaded = total - (updateCount + taskItemsLoadingLenght);
    const uploading = taskItemsTotalUploaded > 0 ? Math.floor((taskItemsUploaded / taskItemsTotalUploaded) * 100) : 0;

    return (
        <div className='container flex-grow-1 mt-3'>
            <div className='d-flex flex-column align-items-center justify-content-center'>
                <Typography size='md' className='fw-bold' message={t("Tasks.transactions-checked", { uploading })} />
                <Typography message={t("Tasks.transactions-checked-description")} />
                <div className='edit-task-loading-image-container'>
                    <img src={firstImage} className='first-image' />
                    <img src={secondImage} className='second-image' />
                    <img src={thirdImage} className='third-image' />
                </div>
                <div className='edit-task-loading-container'>
                    <div className='edit-task-loading-loader' style={{ width: `${uploading}%` }} />
                </div>
            </div>
        </div>
    );
}
