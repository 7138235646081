import { TasksEditRecap } from "../Recap";

import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { useComputeRowsForSignatureTasksTable } from "../hooks";
import { useRouterTaskToEdit } from "src/modules/tasks/hooks";
import { getTasksItemsIsLoading } from "src/modules/tasksItems/selectors";
import { useAppSelector } from "src/store";

import { SelectableTable } from "src/shared/components/SelectableTable/SelectableTable";

const header = (t: TFunction<string>) => [
    "",
    t("CommonUse.identifier"),
    t("CommonUse.name"),
    t("CommonUse.amount"),
    t("Transactions.julaya-fees"),
    t("CommonUse.comment"),
];

export function EditTaskSummaryPanel() {
    const isLoading = useAppSelector((state) => getTasksItemsIsLoading(state));
    const { t } = useTranslation();

    const task = useRouterTaskToEdit();

    const { rows } = useComputeRowsForSignatureTasksTable();

    return (
        <div className='d-flex w-100 gap-4'>
            <div className='w-100'>
                <SelectableTable isLoading={isLoading} rows={rows} header={header(t)} />
            </div>
            <div className='summary-recap-container'>
                <TasksEditRecap task={task} />
            </div>
        </div>
    );
}
