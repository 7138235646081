import { EditTaskInputCell } from "../InputCell";
import { CreateTaskItemContext } from "src/shared/context/createTaskItem";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useContext } from "react";

import { Typography } from "src/shared/atoms/Typography/Typography";

type Props = {
    uuid: string;
    taskId?: number;
};

export function TasksItemEditFieldCreateAmount({ uuid, taskId }: Props) {
    const { taskItems, loading, setTaskItems } = useContext(CreateTaskItemContext);

    const { externalReference, amount, externalFullname } = taskItems?.[uuid] ?? {};

    const handleUpdateAmount = async (newAmount: string) => {
        if (taskId && taskItems) {
            setTaskItems({ ...taskItems, [uuid]: { ...taskItems[uuid], amount: parseInt(newAmount ?? "") } });
        }
    };

    return (
        <EditTaskInputCell
            editOpen={!!externalReference && !!externalFullname && !amount}
            initialValue={String(amount ?? "")}
            disabled={loading}
            onValidate={handleUpdateAmount}
            type='number'
            testId='edit-task-row-amount-input'
        >
            <Typography
                message={formatMoneyToString({ amount: amount ?? 0 })}
                className='edit-task-table-cell-content'
            />
        </EditTaskInputCell>
    );
}
