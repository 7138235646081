import { UserCreateDrawerBody } from "./Body";
import { CreateCollectorParams } from "src/services/admin/operations";
import { PasswordValidationModal } from "src/shared/common/Password/Modal";
import { companyCollectorPartners } from "src/shared/const/partner";
import { CreateUserContext, UserValuesType } from "src/shared/context/createUser";
import { validateField } from "src/shared/utils/validateField";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getCreatedUser, getUserDetail } from "src/modules/admin/userDetail/selectors";
import {
    clearUserDetailData,
    handleCreateCollector,
    handleCreateUser,
} from "src/modules/admin/userDetail/thunkActions";
import { getSelectedCompanyCountryTag } from "src/modules/auth/selectors";
import { useAppDispatch, useAppSelector } from "src/store";

import { Drawer } from "src/shared/atoms/Drawer/Drawer";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

import "./styles.scss";

const successImage = require("src/shared/images/user/success.png");

type Props = {
    isOpen: boolean;
    createCollector?: boolean;
    attachToWallet?: boolean;
    onCloseDrawer: () => void;
};

export function UserCreateDrawerContainer({ attachToWallet, createCollector, isOpen, onCloseDrawer }: Props) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { userDetail } = useAppSelector(getUserDetail);
    const companyCountryTag = useAppSelector((state) => getSelectedCompanyCountryTag(state));
    const { loading, error } = useAppSelector(getCreatedUser);
    const [userValues, setUserValues] = useState<UserValuesType | undefined>();
    const [openPasswordDialog, setOpenPassword] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [selectedPartners, setSelectedPartners] = useState<SelectOption[]>([]);

    useEffect(() => {
        dispatch(clearUserDetailData());
        setSelectedPartners(companyCountryTag ? companyCollectorPartners[companyCountryTag] : []);
        // eslint-disable-next-line
    }, [isOpen]);

    useEffect(() => {
        if (openPasswordDialog) {
            setShowSuccess(false);
        }
    }, [openPasswordDialog]);

    useEffect(() => {
        if (loading === false && !error && userValues) {
            setShowSuccess(true);
            handleClose();
        }
        // eslint-disable-next-line
    }, [error, loading]);

    const handleClose = () => {
        initState();
        onCloseDrawer();
        dispatch(clearUserDetailData());
    };

    const initState = () => {
        setUserValues(undefined);
    };

    const handleSwitchOpenDialog = () => {
        setOpenPassword((oldOpenDialog) => {
            if (oldOpenDialog) {
                setIsHidden(false);
            }
            return !oldOpenDialog;
        });
    };

    const handleOpenPasswordDialog = () => {
        setOpenPassword(true);
        setIsHidden(true);
    };

    const handleSendCreateUser = async ({ password, twoFactor }: { password: string; twoFactor: string | null }) => {
        if (userValues) {
            const args = {
                password,
                otp: twoFactor,
                ...(userDetail && { id: userDetail.id }),
                role: userValues.accessType,
                email: userValues.email || "",
                gender: userValues.gender,
                dateOfBirth: userValues.dateOfBirth,
                countryOfCitizenship: userValues.countryOfCitizenship?.toUpperCase(),
                firstname: userValues.firstname,
                lastname: userValues.lastname,
                passportFront: userValues.passportFront,
                passportBehind: userValues.passportBehind,
                slug: userValues.slug,
                customReference: userValues.customReference,
            };

            await dispatch(handleCreateUser(args));
        }
    };

    const handleSendCreateCollector = async ({
        password,
        twoFactor,
    }: {
        password: string;
        twoFactor: string | null;
    }) => {
        if (userValues) {
            const args: CreateCollectorParams = {
                firstname: userValues.firstname ?? "",
                lastname: userValues.lastname ?? "",
                countryCode: userValues.waveCountryCode ?? "",
                phone: userValues.customWavePhone ?? "",
                services: selectedPartners.map((partner) => partner.id),
                walletId: parseInt(String(userValues.waveWalletId)),
                password,
                otp: twoFactor,
            };

            await dispatch(handleCreateCollector(args));
        }
    };

    const mandatoryFieldsValid = createCollector
        ? (userValues?.firstname ?? "")?.length > 0 &&
          (userValues?.lastname ?? "")?.length > 0 &&
          userValues?.waveCountryCode &&
          (userValues.customWavePhone ?? "")?.trim().length > 0 &&
          userValues.waveWalletId &&
          selectedPartners.length > 0
        : userValues?.firstname &&
          userValues?.firstname?.length > 0 &&
          userValues?.lastname &&
          userValues?.lastname?.length > 0 &&
          userValues?.accessType &&
          validateField("email", userValues?.email ?? "").isValid;

    return (
        <CreateUserContext.Provider
            value={{
                userValues,
                attachToWallet,
                setUserValues,
                selectedPartners,
                setSelectedPartners,
                initState,
            }}
        >
            <div className='user-create-drawer-container'>
                <Drawer
                    isOpen={isOpen}
                    isHidden={isHidden}
                    onClose={handleClose}
                    header={{
                        title: t("UserCreateDrawerContainer.invite-a-user-to-join-your-team"),
                        image: "user-plus",
                    }}
                    body={<UserCreateDrawerBody createCollector={createCollector} />}
                    footer={{
                        primaryButton: {
                            label: t(
                                createCollector ? "Users.add-a-collector" : "UserCreateDrawerFooter.send-an-invitation"
                            ),
                            onClick: handleOpenPasswordDialog,
                            disabled: !mandatoryFieldsValid,
                        },
                    }}
                />

                <PasswordValidationModal
                    header={{
                        title: t("UserCreateDrawerContainer.invite-a-user"),
                        icon: "user-plus",
                    }}
                    successContent={{
                        image: successImage,
                        text: t("UserCreateDrawerContainer.votre-demande-a-bien-ete-prise-en-compte").toString(),
                    }}
                    error={error}
                    successButtonLabel={t<string>("CommonUse.quit")}
                    asyncFromParent
                    succeeded={showSuccess}
                    open={openPasswordDialog}
                    setOpen={handleSwitchOpenDialog}
                    handleOnSubmit={createCollector ? handleSendCreateCollector : handleSendCreateUser}
                />
            </div>
        </CreateUserContext.Provider>
    );
}
