import { FormContainer } from "../FormContainer";
import { isEqual } from "lodash";
import { toast } from "react-toastify";
import { UserAuthApiReturn } from "src/services/auth/types";
import { validateField } from "src/shared/utils/validateField";

import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getNotificationsState } from "src/modules/notifications/selectors";
import { editNotificationSettings, getNotificationSettings } from "src/modules/notifications/thunkActions";
import { useDebounce } from "src/shared/hooks/useDebounce";
import { useAppDispatch, useAppSelector } from "src/store";

import { getIconName } from "src/components/Notifications/const";
import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Input } from "src/shared/atoms/Inputs/Input";
import { InputPhone, InputPhoneValue } from "src/shared/atoms/Inputs/Phone/Phone";
import { Spinner } from "src/shared/atoms/Spinner/Spinner";
import { Switch } from "src/shared/atoms/Switch/Switch";
import { Tag } from "src/shared/atoms/Tag/Tag";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type NotificationSettingProps = {
    user: UserAuthApiReturn;
    setIsCurrentlyModifying: (isModifying: boolean) => void;
};
export const NotificationSetting = ({ user, setIsCurrentlyModifying }: NotificationSettingProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { settings, updateSettingsError, loadingSettings } = useAppSelector(getNotificationsState);

    const [isContactEdited, setIsContactEdited] = useState(false);
    const [isPlatformNotificationsEdited, setIsPlaformNotifiactionsEdited] = useState(false);

    const [email, setEmail] = useState<string | null>();
    const [phone, setPhone] = useState<InputPhoneValue | null>({
        prefix: settings?.contacts.countryCode ?? "",
        value: settings?.contacts.phone ?? "",
    });
    const [editedSettings, setEditedSettings] = useState(settings);
    const [emailError, setEmailError] = useState<string | null>(null);

    useEffect(() => {
        dispatch(getNotificationSettings());
    }, []);

    useEffect(() => {
        toast.error(updateSettingsError);
    }, [updateSettingsError]);

    useEffect(() => {
        if (settings?.contacts.email) {
            handleChangeEmail(settings.contacts.email);
        }
        if (settings?.contacts.phone && settings.contacts.countryCode) {
            handleChangePhone({ prefix: settings.contacts.countryCode, value: settings.contacts.phone });
        }
        setEditedSettings(settings);
    }, [settings]);

    const debouncedEmail = useDebounce(email, 700);

    useEffect(() => {
        if (debouncedEmail && email !== "" && email) {
            if (!validateField("email", email).isValid) {
                setEmailError("CommonUse.no-spec-char-email");
            } else {
                setEmailError(null);
            }
        }
    }, [debouncedEmail]);

    const handleChangeEmail = (newEmail: string) => {
        setEmail(newEmail);
        setEditedSettings((prevSettings) => {
            if (!prevSettings) {
                return prevSettings;
            }

            return {
                ...prevSettings,
                contacts: {
                    ...prevSettings.contacts,
                    email: newEmail,
                },
            };
        });
    };

    const handleChangePhone = (newTelephone: InputPhoneValue) => {
        setPhone(newTelephone);
        setEditedSettings((prevSettings) => {
            if (!prevSettings) {
                return prevSettings;
            }

            return {
                ...prevSettings,
                contacts: {
                    ...prevSettings.contacts,
                    phone: newTelephone.value || "",
                    countryCode: newTelephone.prefix || "",
                },
            };
        });
    };

    const handleChangeSettings = (field: string, slug: string) => (value: boolean) => {
        setEditedSettings((prevSettings) => {
            if (!prevSettings) {
                return prevSettings;
            }

            return {
                ...prevSettings,
                subscriptions: {
                    ...prevSettings.subscriptions,
                    [slug]: {
                        ...prevSettings.subscriptions[slug],
                        [field]: value,
                    },
                },
            };
        });
    };

    const handleCancelSettingsChange = () => {
        setEditedSettings(settings);
    };

    const handleCancelContactSettings = () => {
        handleChangeEmail(user.email ?? "");
        handleChangePhone({ value: user.phone, prefix: user.countryCode });
    };

    const handleValidateChanges = () => {
        if (editedSettings && !isEqual(editedSettings, settings)) {
            dispatch(editNotificationSettings(editedSettings)).then((result: any) => {
                if (result.payload && !result.payload.message) {
                    toast.success(t("Profile.information-updated"));
                }
            });
        }
    };

    const handleKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === "Enter") {
                if (!isEqual(editedSettings, settings)) {
                    handleValidateChanges();
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [handleValidateChanges]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);

        return () => document.removeEventListener("keydown", handleKeyPress);
    }, [handleKeyPress]);

    useEffect(() => {
        setIsContactEdited(isEqual(editedSettings?.contacts, settings?.contacts));
        setIsPlaformNotifiactionsEdited(isEqual(editedSettings?.subscriptions, settings?.subscriptions));
    }, [editedSettings, settings]);

    useEffect(() => {
        setIsCurrentlyModifying(!isContactEdited || !isPlatformNotificationsEdited);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isContactEdited, isPlatformNotificationsEdited]);

    return (
        <div className='notifications-settings-tab'>
            <div className='form-contents'>
                <div className='left-content'>
                    <FormContainer
                        icon={"user-circle"}
                        title={t("Profile.notif-will-be-sent-to")}
                        subTitle={t<string>("Profile.you-can-edit-anytime")}
                        children={
                            <div className='notifications-form'>
                                <Input
                                    inputClassname='background-neutral-100'
                                    label={t("CommonUse.email").toString()}
                                    value={email ?? ""}
                                    placeholder={t("CommonUse.email-placeholder")}
                                    onChange={handleChangeEmail}
                                    variant={emailError ? "error" : "primary"}
                                    underMessage={emailError ? t<string>(emailError) : undefined}
                                    disabled={loadingSettings}
                                />
                                <InputPhone
                                    label={t("CommonUse.phone-number").toString()}
                                    value={phone?.value ?? ""}
                                    disabled={loadingSettings}
                                    prefix={phone?.prefix ?? "00225"}
                                    placeholder={t("CommonUse.phone-number-placeholder")}
                                    onChange={handleChangePhone}
                                />
                            </div>
                        }
                        buttons={{
                            primary: {
                                label: t("CommonUse.validate"),
                                onClick: handleValidateChanges,
                                variant: "primary",
                                disabled: isContactEdited || emailError !== null,
                            },
                            secondary: {
                                label: t("CommonUse.cancel"),
                                onClick: handleCancelContactSettings,
                                variant: "tertiary",
                                disabled: isContactEdited,
                            },
                        }}
                    />
                </div>
                <div className='right-content'>
                    <FormContainer
                        icon={"device-computer"}
                        title={t("Profile.platform")}
                        children={
                            <div className='notification-list-settings'>
                                {loadingSettings && (
                                    <div className='loading-spinner'>
                                        <Spinner size='lg' />
                                    </div>
                                )}
                                {!loadingSettings && (
                                    <div className='notification-settings-item'>
                                        <div className='left-content'>
                                            <Avatar
                                                icon='exclamation-triangle'
                                                color='lilas-900'
                                                backgroundColor='lilas-50'
                                            />
                                            <div className='text-part'>
                                                <Typography
                                                    message={t("NotificationsSettings.info-mandatory")}
                                                    className='fw-bold'
                                                />
                                                <Typography
                                                    message={t("NotificationsSettings.alert-maintenance-conformity")}
                                                />
                                            </div>
                                        </div>
                                        <div className='right-content'>
                                            <Tag
                                                label={t<string>("NotificationsSettings.activated-by-default")}
                                                color='lilas'
                                                type='accent'
                                                size='sm'
                                            />
                                        </div>
                                    </div>
                                )}
                                {!loadingSettings &&
                                    editedSettings &&
                                    Object.keys(editedSettings.subscriptions).map((key) => {
                                        const content = editedSettings.subscriptions[key];
                                        return content ? (
                                            <div className='notification-settings-item' key={key}>
                                                <div className='left-content'>
                                                    <Avatar
                                                        icon={getIconName(key)}
                                                        color='lilas-900'
                                                        backgroundColor='lilas-50'
                                                    />
                                                    <div className='text-part'>
                                                        <Typography
                                                            message={t(`${"NotificationsSettings." + key + "-title"}`)}
                                                            className='fw-bold'
                                                        />
                                                        <Typography
                                                            message={t(
                                                                `${"NotificationsSettings." + key + "-subTitle"}`
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='right-content'>
                                                    <div className='switch-container'>
                                                        <Typography
                                                            className='fw-bold'
                                                            message={t("CommonUse.email")}
                                                        />
                                                        <Switch
                                                            isOn={content.sendEmail}
                                                            onToggle={handleChangeSettings("sendEmail", key)}
                                                            disabled={!content.capabilities?.includes("mail")}
                                                        />
                                                    </div>
                                                    <div className='switch-container'>
                                                        <Typography className='fw-bold' message={t("CommonUse.sms")} />
                                                        <Switch
                                                            isOn={content.sendSms}
                                                            onToggle={handleChangeSettings("sendSms", key)}
                                                            disabled={!content.capabilities?.includes("sms")}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null;
                                    })}
                            </div>
                        }
                        buttons={{
                            primary: {
                                label: t("CommonUse.validate"),
                                disabled: loadingSettings || isPlatformNotificationsEdited,
                                onClick: handleValidateChanges,
                                variant: "primary",
                            },
                            secondary: {
                                label: t("CommonUse.cancel"),
                                disabled: loadingSettings || isPlatformNotificationsEdited,
                                onClick: handleCancelSettingsChange,
                                variant: "tertiary",
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
