import { useNavigate } from "react-router-dom";
import { createTask } from "src/services/tasks/operations";
import { CompanyStatus } from "src/shared/models/UserCompany";
import { DrawerContext } from "src/wrapper/context";

import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import {
    getAuthUser,
    getAuthUserPreferenceSelectedCompany,
    getSelectedCompanyCountryCode,
} from "src/modules/auth/selectors";
import { receiveTaskToEdit } from "src/modules/tasks/slice";
import { getAuthorizedWalletsAsSelectOptions } from "src/modules/wallets/selectors";
import { fetchAuthorizedWalletsAuthUser } from "src/modules/wallets/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Drawer } from "src/shared/atoms/Drawer/Drawer";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Input } from "src/shared/atoms/Inputs/Input";
import { TextArea } from "src/shared/atoms/Inputs/TextArea";
import { Switch } from "src/shared/atoms/Switch/Switch";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { BlockedAccountAlert } from "src/shared/components/BlockedAccountAlert/BlockedAccountAlert";
import { KybAlert } from "src/shared/components/KybAlert/KybAlert";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";
import { SimpleSelect } from "src/shared/components/SimpleSelect/SimpleSelect";

import "./styles.scss";

const defaultSmsMessage = "Tasks.sms-exemple";
const smsVariables = {
    DESTINATAIRE: "Transactions.receiver-name",
    SERVICE: "Tasks.sms-services-exemple",
    MONTANT: "Tasks.sms-amount-exemple",
    COMMENTAIRE: "Tasks.sms-comment-exemple",
};

const smsVariablesValues = (t: TFunction<"translation", undefined, "translation">) => ({
    DESTINATAIRE: "Ahmed Coulibaly",
    SERVICE: "Orange money",
    MONTANT: "10 000 " + t("CommonUse.currency"),
    COMMENTAIRE: t("CommonUse.your-comment"),
});

function replaceAllDictionnary(str: string, dictionnary: { [key: string]: string }) {
    const regex = new RegExp("\\[" + Object.keys(dictionnary).join("\\]|\\[") + "\\]", "gi");
    return str.replace(regex, (matched) => {
        return dictionnary[matched.replace(/\[/g, "").replace(/\]/g, "")];
    });
}

type FormErrors = {
    title?: string;
    selectedAccount?: string;
};

export function CreateTaskDrawer() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { open, setOpen, providedState } = useContext(DrawerContext);

    const companyName = useAppSelector((state) => getAuthUserPreferenceSelectedCompany(state)?.name ?? "");

    const [errors, setErrors] = useState<FormErrors>({});
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [smsTemplate, setSmsTemplate] = useState(t<string>(defaultSmsMessage, { companyName }));
    const [selectedAccount, setSelectedAccount] = useState<SelectOption>();
    const [sendSms, setSendSms] = useState(false);
    const [includeReceiverFees, setIncludeReceiverFees] = useState(false);
    const [disabledButton, setDisabledButton] = useState(true);
    const companyCountryCode = useAppSelector((state) => getSelectedCompanyCountryCode(state));
    const walletOptions = useAppSelector((state) => getAuthorizedWalletsAsSelectOptions(state));

    const authUser = useAppSelector((state) => getAuthUser(state));
    const isBlockedAccount = !!(authUser && authUser.Company?.status !== CompanyStatus.BLOCK_0);

    useEffect(() => {
        const disabled = !title || title.length < 4 || !selectedAccount;
        setDisabledButton(disabled);

        const handleGlobalEnterPress = (event: any) => {
            if (event.key === "Enter" && disabled === false) {
                handleCreateTask();
            }
        };

        document.addEventListener("keydown", handleGlobalEnterPress);

        return () => {
            document.removeEventListener("keydown", handleGlobalEnterPress);
        };
    }, [selectedAccount, title]);

    useEffect(() => {
        dispatch(fetchAuthorizedWalletsAuthUser());
    }, [dispatch]);

    useEffect(() => {
        if (providedState) {
            const selectedWallet = walletOptions.find(
                (wallet) => wallet.id === String(providedState?.duplicateTask?.Wallet?.id)
            );
            setSelectedAccount(selectedWallet);
        } else if (walletOptions.length === 1) {
            setSelectedAccount(walletOptions[0]);
        }
    }, [providedState, walletOptions]);

    useEffect(() => {
        const providedTask = providedState?.duplicateTask;
        if (providedTask) {
            setTitle(providedTask.title);
            setDescription(providedTask.description);
            setSendSms(!!providedTask.smsTemplate);
            setSmsTemplate(providedTask.smsTemplate);
            setIncludeReceiverFees(providedTask.includeReceiverFees);
        }
    }, [providedState]);

    useEffect(() => {
        checkForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, selectedAccount]);

    const handleUpdateTitle = (newTitle: string) => {
        setTitle(newTitle);
    };

    const handleUpdateDescription = (newDescription: string) => {
        setDescription(newDescription);
    };

    const handleUpdateSmsTemplate = (newTemplate: string) => {
        setSmsTemplate(newTemplate);
    };

    const handleAddVariableToText = (variable: string) => () => {
        setSmsTemplate(`${smsTemplate}[${variable}]`);
    };

    const handleUpdateSelectedAccount = (newSelectedAccount: SelectOption) => {
        setSelectedAccount(newSelectedAccount.id === selectedAccount?.id ? undefined : newSelectedAccount);
    };

    const handleSwitchSendSms = () => {
        setSendSms(!sendSms);
    };

    const handleSwitchIncludeReceiverFees = () => {
        setIncludeReceiverFees(!includeReceiverFees);
    };

    const handleCloseDrawer = useCallback(() => {
        setOpen(undefined);
    }, [setOpen]);

    const checkForm = useCallback(() => {
        const errors: FormErrors = {};
        if (!title) {
            errors.title = t("Tasks.title-length").toString();
        }
        if (!selectedAccount) {
            errors.selectedAccount = t("CommonUse.select-account").toString();
        }
        return errors;
    }, [selectedAccount, t, title]);

    const handleCreateTask = useCallback(() => {
        const errors = checkForm();
        if (Object.keys(errors).length) {
            setErrors(errors);
            return;
        }
        if (selectedAccount && title) {
            createTask({
                title,
                description,
                includeReceiverFees,
                walletId: String(selectedAccount?.id),
                smsTemplate: sendSms ? smsTemplate : "",
            }).then((response) => {
                handleCloseDrawer();
                dispatch(receiveTaskToEdit({ task: response }));
                if (providedState) {
                    navigate(`/tasks/${response.id}/edit`, {
                        state: { duplicateTaskId: providedState.duplicateTask?.id },
                    });
                } else {
                    navigate("/create-task");
                }
            });
        }
    }, [
        providedState,
        checkForm,
        description,
        dispatch,
        handleCloseDrawer,
        includeReceiverFees,
        navigate,
        selectedAccount,
        sendSms,
        smsTemplate,
        title,
    ]);

    const drawerHeader = useMemo(
        () =>
            ({
                title: t("CommonUse.add-task", { entity: `${t("Tasks.a-task")}` }),
                image: "task",
            } as const),
        [t]
    );

    const drawerFooter = {
        primaryButton: {
            label: t("CommonUse.continue"),
            disabled: disabledButton || isBlockedAccount,
            onClick: disabledButton ? undefined : handleCreateTask,
        },
        secondaryButton: {
            label: t("CommonUse.quit"),
            onClick: handleCloseDrawer,
        },
    };

    return (
        <Drawer
            isOpen={open === "createTask"}
            onClose={handleCloseDrawer}
            header={drawerHeader}
            footer={drawerFooter}
            body={
                <div className='drawer-content-section-body create-task-drawer-body'>
                    <BlockedAccountAlert topBottom />
                    <KybAlert topBottom />
                    <div className='create-task-drawer-body-item'>
                        <Input
                            autoFocus
                            variant={errors.title ? "error" : "primary"}
                            value={title}
                            underMessage={errors.title}
                            onChange={handleUpdateTitle}
                            label={t<string>("Tasks.title")}
                            testId='create-task-drawer-title-input'
                            placeholder={t("Tasks.title-placeholder")}
                            disabled={isBlockedAccount}
                        />
                    </div>
                    <div className='create-task-drawer-body-item'>
                        <TextArea
                            label={t<string>("CommonUse.description")}
                            value={description}
                            onChange={handleUpdateDescription}
                            testId='create-task-drawer-description-input'
                            rows={4}
                            placeholder={t("Tasks.task-description-placeholder").toString()}
                            disabled={isBlockedAccount}
                        />
                    </div>
                    <div className='create-task-drawer-body-item'>
                        <SimpleSelect
                            useRadio
                            placeholder={t<string>("CommonUse.select-account")}
                            headerClassname='create-task-input-width'
                            contentClassname='w-100'
                            options={walletOptions}
                            selected={selectedAccount}
                            variant={errors.selectedAccount ? "error" : "primary"}
                            underMessage={errors.selectedAccount}
                            onSelectionChange={handleUpdateSelectedAccount}
                            color='white'
                            testId='create-task-drawer-account-select'
                            disabled={isBlockedAccount}
                        />
                    </div>
                    {companyCountryCode !== "00221" && (
                        <Switch
                            label={t<string>("Tasks.include-op-fees")}
                            isOn={includeReceiverFees}
                            onToggle={handleSwitchIncludeReceiverFees}
                            disabled={isBlockedAccount}
                        />
                    )}
                    <div className='switch-tooltip'>
                        <Switch
                            label={t<string>("Tasks.send-sms")}
                            isOn={sendSms}
                            onToggle={handleSwitchSendSms}
                            disabled={isBlockedAccount}
                        />
                        <ToolTip
                            content={<Typography message={t("Tasks.no-extra-fees")} className='fw-bold' />}
                            activation={true}
                            children={<Icon name='question-mark-circle' />}
                            position='bottom'
                        />
                    </div>
                    {sendSms && (
                        <div className='sms-generator-container'>
                            <div className='create-task-drawer-body-item'>
                                <TextArea
                                    maxLength={230}
                                    value={smsTemplate}
                                    onChange={handleUpdateSmsTemplate}
                                    rows={4}
                                />
                            </div>
                            <div className='create-task-drawer-body-item'>
                                <Typography message={t("Tasks.variables")} className='fw-bold' />
                                <div className='create-task-drawer-variables-container '>
                                    {Object.entries(smsVariables).map(([title, description]) => (
                                        <ToolTip
                                            activation
                                            content={<Typography message={t<string>(description)} />}
                                            key={title}
                                            position='bottom'
                                        >
                                            <div
                                                onClick={handleAddVariableToText(title)}
                                                className='create-task-drawer-variables'
                                            >
                                                <Typography message={`[${t(title)}]`} />
                                            </div>
                                        </ToolTip>
                                    ))}
                                </div>
                            </div>
                            <div className='create-task-drawer-body-item'>
                                <Typography message={t("Tasks.custom-sms")} />
                                <TextArea
                                    value={replaceAllDictionnary(smsTemplate, smsVariablesValues(t))}
                                    disabled
                                    rows={4}
                                />
                            </div>
                        </div>
                    )}

                    <div className='task-information-tooltip-container'>
                        <Alert
                            color='warning'
                            icon='question-mark-circle'
                            message={t("Tasks.what-is-task")}
                            subMessage={t<string>("Tasks.what-is-task-description")}
                        />
                    </div>
                </div>
            }
        />
    );
}
