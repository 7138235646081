import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { sectorIcon } from "src/shared/const/sectors";

import { t } from "i18next";

import { getBillingBillers, getBillingBillersIsLoading } from "src/modules/billing/billers/selectors";
import { handleSetBillerCategoryFilter } from "src/modules/billing/billers/slice";
import { useAppDispatch, useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Carousel } from "src/shared/atoms/Carousel/Carousel";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

const skeletonArray = [...Array(4).keys()];
const emptyListImg = require("src/shared/images/empty/empty-user.svg").default;

type BillingTopBillersProps = {
    preselectedWalletId?: string;
};

export const BillingTopBillers = ({ preselectedWalletId }: BillingTopBillersProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isLoading = useAppSelector((state) => getBillingBillersIsLoading(state));
    const billers = useAppSelector((state) => getBillingBillers(state) ?? []);

    const handleRedirectToList = () => {
        dispatch(handleSetBillerCategoryFilter("direct-billers"));
        navigate("/billing/pay-bill-list");
    };

    const handleRedirectToPayment = (reference: string) => () => {
        navigate(`/billing/pay-bill/${reference.replace("#", "")}`, {
            state: { preselectedWalletId: preselectedWalletId },
        });
    };

    return (
        <Carousel
            header={<Typography variant='h6' className='fw-bold' message={t("Billing.popular-billers")} />}
            headerButton={
                <Button onClick={handleRedirectToList} variant='ghost' label={t("Billing.show-everything")} />
            }
            items={
                isLoading
                    ? skeletonArray.map((placeholder, index) => (
                          <div key={`skeleton-${placeholder}`} className={`skeleton skeleton-delay-${index}`} />
                      ))
                    : billers.length
                    ? billers.map((biller) => (
                          <div
                              onKeyDown={() => {}}
                              key={`biller-${biller.name}`}
                              onClick={handleRedirectToPayment(biller.reference)}
                              className='top-biller-item'
                          >
                              {biller.avatar ? (
                                  <div
                                      className={classNames(
                                          "top-biller-image",
                                          "background-gradiant-pink-horizontal-1",
                                          "top-biller-image-background"
                                      )}
                                  >
                                      <img src={biller.avatar} alt='Biller avatar' className='top-biller-avatar' />
                                      <div className='top-biller-sector'>
                                          <Icon name={sectorIcon[biller.sector] ?? "kiosk"} size='sm' color='lilas' />
                                          <Typography
                                              className='fw-bold'
                                              message={t(`Bills.sector-${biller.sector}`)}
                                          />
                                      </div>
                                  </div>
                              ) : (
                                  <div
                                      className={classNames(
                                          "top-biller-image",
                                          "background-gradiant-pink-horizontal-1"
                                      )}
                                  >
                                      <div className='top-biller-sector'>
                                          <Icon name={sectorIcon[biller.sector] ?? "kiosk"} size='sm' color='lilas' />
                                          <Typography
                                              className='fw-bold'
                                              message={t(`Bills.sector-${biller.sector}`)}
                                          />
                                      </div>
                                      <div className='top-biller-center-sector'>
                                          <Icon name={sectorIcon[biller.sector] ?? "kiosk"} size='lg' color='lilas' />
                                      </div>
                                  </div>
                              )}
                              <div className='top-biller-description'>
                                  <Typography className='fw-bold' message={biller.name} />
                                  <Typography className='fw-bold color-lilas-500' message={"-"} />
                                  <div className='top-biller-informations'>
                                      <div>
                                          <Icon className='color-neutral-500' name='information-circle' size='sm' />
                                      </div>
                                      <Typography className='informations' message={biller.description} />
                                  </div>
                              </div>
                          </div>
                      ))
                    : [
                          <div key='biller-empty' className='top-biller-item error pt-3'>
                              <div className='top-biller-image background-gradiant-pink-horizontal-1'>
                                  <img src={emptyListImg} alt='Empty list' className='top-biller-avatar' />
                              </div>
                              <div className='top-biller-description'>
                                  <Typography className='fw-bold' message={t("Table.no-result-found")} />
                                  <div className='top-biller-informations'>
                                      <Typography
                                          className='informations'
                                          message={t("Table.no-result-found-description")}
                                      />
                                  </div>
                              </div>
                          </div>,
                      ]
            }
        />
    );
};
