import { AdminUserCreateDrawerSectionsCollector } from "./Sections/Collector";
import { AdminUserCreateDrawerSectionsProfile } from "./Sections/Profile";
import { AdminUserCreateDrawerSectionsRole } from "./Sections/Role";

import { getUserDetail } from "src/modules/admin/userDetail/selectors";
import { useAppSelector } from "src/store";

import { Spinner } from "src/shared/atoms/Spinner/Spinner";

import "./styles.scss";

type Props = {
    createCollector?: boolean;
};

export function UserCreateDrawerBody({ createCollector }: Props) {
    const { userDetailLoading } = useAppSelector(getUserDetail);

    return (
        <div className='create-user-drawer-body'>
            {userDetailLoading ? (
                <div className='d-flex w-100 h-100 justify-content-center align-items-center'>
                    <Spinner size='lg' />
                </div>
            ) : (
                <>
                    <AdminUserCreateDrawerSectionsProfile createCollector={createCollector} />
                    {createCollector ? (
                        <AdminUserCreateDrawerSectionsCollector />
                    ) : (
                        <AdminUserCreateDrawerSectionsRole />
                    )}
                </>
            )}
        </div>
    );
}
