import { SelectableTabledBar, SelectableTabledBarProps } from "./SelectableTabledBar/SelectableTabledBar";
import { TableCellContent } from "./TableCellContent/TableCellContent";
import classNames from "classnames";

import { ReactNode, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Typography } from "../../atoms/Typography/Typography";
import { Checkbox } from "src/shared/atoms/Checkbox/Checkbox";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Paginate, PaginateProps } from "src/shared/atoms/Pagination/Pagination";
import { PaginationDynamo, PaginationDynamoProps } from "src/shared/atoms/Pagination/PaginationDynamo";
import { Skeleton } from "src/shared/atoms/Skeleton/Skeleton";

import "./styles.scss";

export type SelectableTableRow = {
    id?: string;
    content: ReactNode;
    type: "image" | "text" | "button" | "status";
    onClick?: () => void;
    status?: "success" | "warning" | "error";
    showIcon?: boolean;
    icon?: IconListType;
    alertIcon?: IconListType;
    toolTip?: { title: string; subtitle?: string };
};

export type SelectableTableProps = {
    header: (string | ReactNode)[];
    rows: SelectableTableRow[][];
    selectedBar?: SelectableTabledBarProps;
    pagination?: PaginateProps;
    paginationDynamo?: PaginationDynamoProps;
    loadingRows?: number;
    isLoading?: boolean;
    selectable?: boolean;
    empty?: ReactNode;
    selectAll?: (selected: boolean) => void;
    selectedRows?: number[];
    setSelectedRows?: (rows: number[]) => void;
    hoverEffect?: boolean;
    isLoadingLength?: number;
};

export const SelectableTable = ({
    header,
    rows,
    selectedBar,
    pagination,
    paginationDynamo,
    isLoading = false,
    selectable = false,
    empty,
    selectAll,
    selectedRows,
    setSelectedRows,
    hoverEffect = false,
    isLoadingLength = 20,
}: SelectableTableProps) => {
    const { t } = useTranslation();
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [selectedIndexRows, setSelectedIndexRows] = useState<number[]>([]);

    useEffect(() => {
        if (selectedRows) {
            setSelectedIndexRows(selectedRows);
        }
    }, [selectedRows]);

    useEffect(() => {
        setSelectedIndexRows([]);
        setIsAllSelected(false);
    }, [isLoading, rows.length]);

    useEffect(() => {
        if (rows.length > 0 && selectedIndexRows.length === rows.length) {
            setIsAllSelected(true);
        } else {
            setIsAllSelected(false);
        }
    }, [selectedIndexRows.length, rows.length]);

    useEffect(() => {
        if (pagination?.pageSize || pagination?.currentPage) {
            setSelectedIndexRows([]);
            setIsAllSelected(false);
        }
    }, [pagination?.pageSize, pagination?.currentPage]);

    const handlePageChange = (page: number) => {
        if (pagination?.onPageChange) {
            pagination.onPageChange(page);
        }
    };

    const handlePageSizeChange = (newPage: number) => {
        if (pagination?.onPageSizeChange) {
            pagination.onPageSizeChange(newPage);
        }
    };

    const handleSelectAll = () => {
        if (isAllSelected) {
            setSelectedIndexRows([]);
            if (setSelectedRows) {
                setSelectedRows([]);
            }
        } else {
            const allIndexes = rows.map((_, index) => index);
            setSelectedIndexRows(allIndexes);
            if (setSelectedRows) {
                setSelectedRows(allIndexes);
            }
        }
        setIsAllSelected(!isAllSelected);
        if (selectAll) {
            selectAll(!isAllSelected);
        }
    };

    const handleDeselection = () => {
        setSelectedIndexRows([]);
        setIsAllSelected(false);
        if (selectAll) {
            selectAll(false);
        }
        if (setSelectedRows) {
            setSelectedRows([]);
        }
    };

    const handleRowSelect = (index: number) => {
        const newSelectedIndexes = selectedIndexRows.includes(index)
            ? selectedIndexRows.filter((i) => i !== index)
            : [...selectedIndexRows, index];
        setSelectedIndexRows(newSelectedIndexes);
        if (setSelectedRows) {
            setSelectedRows(newSelectedIndexes);
        }
        setIsAllSelected(false);
    };

    const isRowSelected = (index: number) => {
        return selectedIndexRows.includes(index);
    };

    return (
        <>
            {selectedIndexRows.length > 0 && (
                <SelectableTabledBar
                    {...selectedBar}
                    selected={selectedIndexRows.length}
                    handleDeselection={handleDeselection}
                />
            )}
            <table
                className={classNames("selectable-table-container", { "row-hover": !hoverEffect })}
                data-testid='selectable-table'
            >
                <thead>
                    <tr>
                        {selectable && (
                            <th>
                                <div className='center-item'>
                                    <Checkbox label={undefined} checked={isAllSelected} onChange={handleSelectAll} />
                                </div>
                            </th>
                        )}
                        {header.map((headerItem, index) => {
                            return (
                                <th key={index} id={headerItem?.toString()}>
                                    {typeof headerItem === "string" ? (
                                        <Typography variant={"span"} size={"sm"} message={headerItem} />
                                    ) : (
                                        headerItem
                                    )}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {isLoading ? (
                        <>
                            {Array.from({ length: isLoadingLength }).map((_, index) => (
                                <tr key={index}>
                                    {Array.from({ length: header.length + (selectable ? 1 : 0) }).map(
                                        (_, cellIndex) => (
                                            <td key={cellIndex}>
                                                <Skeleton />
                                            </td>
                                        )
                                    )}
                                </tr>
                            ))}
                        </>
                    ) : (
                        Array.isArray(rows) &&
                        rows.map((cells: SelectableTableRow[], rowIndex: number) => {
                            const isSelected = isAllSelected || isRowSelected(rowIndex);

                            return (
                                <tr
                                    key={rowIndex}
                                    className={classNames({ selected: isSelected, "hover-effect-row": !hoverEffect })}
                                >
                                    {selectable && (
                                        <td className={`selectable-row-td ${cells?.[0]?.status ?? ""}`}>
                                            <div className='center-item'>
                                                <Checkbox
                                                    label={undefined}
                                                    checked={isRowSelected(rowIndex)}
                                                    onChange={() => handleRowSelect(rowIndex)}
                                                />
                                            </div>
                                        </td>
                                    )}
                                    {Array.isArray(cells) &&
                                        cells.map((cell: SelectableTableRow, cellIndex: number) => {
                                            return (
                                                <td
                                                    className={classNames(cell.type, cell.status, {
                                                        "hover-effect-cell": hoverEffect,
                                                        "on-click-cell": cell.onClick,
                                                    })}
                                                    key={cellIndex}
                                                    onClick={cell.onClick ?? undefined}
                                                >
                                                    <TableCellContent
                                                        content={cell.content}
                                                        type={cell.type}
                                                        status={cell.status}
                                                        alertIcon={cell.alertIcon}
                                                        icon={cell.icon}
                                                        showIcon={cell.showIcon}
                                                        toolTip={cell.toolTip}
                                                        onClick={cell.onClick}
                                                    />
                                                </td>
                                            );
                                        })}
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </table>
            {!isLoading && rows.length === 0 ? (
                empty ? (
                    empty
                ) : (
                    <Typography
                        size='lg'
                        variant='span'
                        className='d-flex w-100 justify-content-center'
                        message={t("Table.no-result-found")}
                    />
                )
            ) : null}
            {pagination && (
                <Paginate
                    {...pagination}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    isLoading={isLoading}
                />
            )}
            {paginationDynamo && <PaginationDynamo {...paginationDynamo} isLoading={isLoading} />}
        </>
    );
};
