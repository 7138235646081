import { LoginDisplays } from "../Panel";
import { LoginSuccess } from "../Success";
import { useNavigate, useParams } from "react-router-dom";
import { requestPostUserJoinCompanyFromInvitation } from "src/services/admin/operations";
import { requestCheckJoinCompanyToken } from "src/services/companies/operations";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type Props = {
    handleSetFormToDisplay: (value: LoginDisplays) => () => void;
};

export function LoginJoinPanel({ handleSetFormToDisplay }: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [companyName, setCompanyName] = useState("");

    const { token = "" } = useParams<{ token: string }>();

    const handleJoin = async () => {
        setLoading(true);
        requestPostUserJoinCompanyFromInvitation(token)
            .then(() => {
                handleSetFormToDisplay("login")();
                navigate("/");
            })
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    };

    const handleRedirect = () => {
        handleSetFormToDisplay("login")();
        navigate("/");
    };

    useEffect(() => {
        const checkToken = async () => {
            setLoading(true);
            await requestCheckJoinCompanyToken({ token })
                .then((res) => {
                    setCompanyName(res.name);
                })
                .catch(() => {
                    setError(true);
                })
                .finally(() => setLoading(false));
        };
        checkToken();
    }, [token]);

    return error ? (
        <LoginSuccess
            variant='error'
            title={"LoginPanel.link-no-longer-valid"}
            description={"LoginPanel.contact-admin-to-send-invit"}
            successAction={handleRedirect}
            successLabel={"UserCreateDrawerContainer.access-to-platform"}
        />
    ) : (
        <div className='d-flex flex-column gap-3'>
            <Typography
                variant={"h2"}
                message={t("UserCreateDrawerContainer.join-company", { companyName })}
                className='login-title-happy'
            />
            <Typography
                message={t("UserCreateDrawerContainer.your-profile-already-exist")}
                className='color-neutral-500 fw-bold'
            />
            <div className='section-join-container'>
                <div className='d-flex gap-2 align-items-center'>
                    <Icon name='office' variant='squared' color='gradiant-lilas' />
                    <Typography className='fw-bold' message={companyName} />
                </div>
                <Button
                    disabled={loading}
                    onClick={handleJoin}
                    label={t("UserCreateDrawerContainer.join")}
                    position='right'
                    icon='arrow-right'
                />
            </div>
        </div>
    );
}
