import { LoginCreateCompanyPanel } from "../CreateCompany/Panel";
import { LoginNewPassword } from "../NewPassword";
import { LoginDisplays } from "../Panel";
import { RegisterPhone } from "./Phone";
import { RegisterPhoneCode } from "./PhoneCode";
import { useLocation, useParams } from "react-router-dom";
import {
    RequestPostCreateUserFromInvitationProps,
    requestPostCreateUserFromInvitation,
} from "src/services/admin/operations";
import { CountryOptions } from "src/shared/const/countries";

import { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Input } from "src/shared/atoms/Inputs/Input";
import { InputPhoneValue } from "src/shared/atoms/Inputs/Phone/Phone";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { CustomDatePicker } from "src/shared/components/DatePicker/DatePicker";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";
import { SimpleSelect } from "src/shared/components/SimpleSelect/SimpleSelect";

const julayaImage = require("src/shared/images/logo/julaya_pro.png");

const genderOptionsRaw = (t: (key: string) => string): SelectOption[] => [
    { id: t("AdminAccountsFormsProfile.m"), label: t("CommonUse.mr") },
    { id: t("AdminAccountsFormsProfile.f"), label: t("CommonUse.mme") },
];

type Props = {
    handleSetFormToDisplay: (switchName: LoginDisplays) => () => void;
};

export type CreateFormSteps = "creation" | "newPassword" | "phone" | "phoneVerification" | "createCompany";

export function LoginRegisterPanel({ handleSetFormToDisplay }: Props) {
    const { t } = useTranslation();

    const token = useParams<{ token: string }>().token;

    const [createFormToDisplay, setCreateFormToDisplay] = useState<CreateFormSteps>("creation");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [phone, setPhone] = useState<InputPhoneValue>({ prefix: "00225", value: "" });
    const [password, setPassword] = useState("");
    const [selectedGenderOption, setSelectedGenderOption] = useState<SelectOption | null>(null);
    const [selectedCountryOption, setSelectedCountryOption] = useState<SelectOption | null>(null);
    const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);

    const location = useLocation();

    const isCreateCompany = location.pathname.includes("/create-company/");

    const GenderOptions = useMemo(() => genderOptionsRaw(t), [t]);

    const handleChangeFirstName = (newFirstName: string) => {
        setFirstname(newFirstName);
    };

    const handleChangeLastName = (newLastName: string) => {
        setLastname(newLastName);
    };

    const handleChangeGender = (option: SelectOption) => {
        setSelectedGenderOption(option.id === selectedGenderOption?.id ? null : option);
    };

    const handleChangeCountry = (option: SelectOption) => {
        setSelectedCountryOption(option.id === selectedCountryOption?.id ? null : option);
    };

    const handleChangeDateOfBirth = (date: Date | null) => {
        setDateOfBirth(date);
    };

    const handleRedirectForm = (formToDisplay: CreateFormSteps) => () => {
        setCreateFormToDisplay(formToDisplay);
    };

    const handleSetPhone = (phone: InputPhoneValue) => {
        setPhone(phone);
    };

    const handleSetPassword = (password: string) => {
        setPassword(password);
        setCreateFormToDisplay(isCreateCompany ? "createCompany" : "phone");
    };

    const handleOnSubmit = async (additionalData?: Partial<RequestPostCreateUserFromInvitationProps>) => {
        if (
            firstname &&
            lastname &&
            selectedGenderOption &&
            selectedCountryOption &&
            dateOfBirth &&
            token &&
            password
        ) {
            const countryOfCitizenship = selectedCountryOption.id;
            const gender = selectedGenderOption?.id;

            const res = await requestPostCreateUserFromInvitation({
                firstname,
                lastname,
                countryOfCitizenship,
                dateOfBirth,
                gender,
                password,
                token,
                ...(additionalData ?? {}),
            });
            return res;
        }
        return Promise.reject();
    };

    const formFilled =
        firstname.trim().length > 0 &&
        lastname.trim().length > 0 &&
        selectedGenderOption &&
        selectedCountryOption &&
        dateOfBirth;

    return createFormToDisplay === "createCompany" ? (
        <LoginCreateCompanyPanel
            handleSetPhone={handleSetPhone}
            handleSetFormToDisplay={handleSetFormToDisplay}
            setCreateFormToDisplay={setCreateFormToDisplay}
            firstname={firstname}
            lastname={lastname}
            gender={selectedGenderOption}
            dateOfBirth={dateOfBirth}
            password={password}
            phone={phone}
        />
    ) : createFormToDisplay === "creation" ? (
        <>
            <img src={julayaImage} alt='julaya logo' className='julaya-logo' />
            <div className='d-flex flex-column gap-3'>
                <Typography
                    message={t("UserCreateDrawerContainer.welcome-create-user")}
                    className='fw-bold'
                    variant='h1'
                />
                <Typography
                    className='color-neutral-500 fw-bold'
                    message={t("UserCreateDrawerContainer.complete-informations-create-user")}
                />
                <div className='d-flex flex-row gap-2'>
                    <Input
                        value={firstname}
                        onChange={handleChangeFirstName}
                        className='w-100'
                        label={t<string>("CommonUse.firstnames")}
                        placeholder={t("CommonUse.firstnames")}
                    />
                    <Input
                        value={lastname}
                        onChange={handleChangeLastName}
                        className='w-100'
                        label={t<string>("CommonUse.lastname")}
                        placeholder={t("CommonUse.lastname")}
                    />
                </div>
                <SimpleSelect
                    headerClassname='w-100 border-box'
                    label={t<string>("AdminAccountsFormsProfile.civilite")}
                    placeholder={t<string>("AdminAccountsFormsProfile.civilite")}
                    selected={selectedGenderOption}
                    onSelectionChange={handleChangeGender}
                    useRadio
                    options={GenderOptions}
                />
                <SimpleSelect
                    headerClassname='w-100 border-box'
                    label={t<string>("AdminAccountsFormsProfile.nationalite")}
                    placeholder={t<string>("CommonUse.country-of-citizenship")}
                    selected={selectedCountryOption}
                    onSelectionChange={handleChangeCountry}
                    useRadio
                    options={CountryOptions}
                />
                <CustomDatePicker
                    label={t("Users.date-of-birth")}
                    single
                    startDate={dateOfBirth ? new Date(dateOfBirth) : null}
                    onChangeStartDate={handleChangeDateOfBirth}
                />
                <Button
                    className='w-100'
                    onClick={handleRedirectForm("newPassword")}
                    disabled={!formFilled}
                    label={t("CommonUse.continue")}
                />
            </div>
        </>
    ) : createFormToDisplay === "newPassword" ? (
        <LoginNewPassword
            handleCustomSendPassword={handleSetPassword}
            redirectCancel={handleRedirectForm("creation")}
        />
    ) : createFormToDisplay === "phone" ? (
        <RegisterPhone
            handleSetFormToDisplay={handleSetFormToDisplay}
            setCreateFormToDisplay={setCreateFormToDisplay}
            phone={phone}
            handleSetPhone={handleSetPhone}
            handleOnSubmit={handleOnSubmit}
        />
    ) : phone.prefix && phone.value ? (
        <RegisterPhoneCode
            countryCode={phone.prefix}
            phone={phone.value}
            handleSetFormToDisplay={handleSetFormToDisplay}
        />
    ) : null;
}
