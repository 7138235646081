import { FormContainer } from "../FormContainer";
import { toast } from "react-toastify";
import { requestUpdateAuthUserPassword } from "src/services/auth/operations";
import { getInStorage, setInStorage } from "src/shared/utils/storage";

import { useCallback, useEffect, useState } from "react";

import { t } from "i18next";

import { getAuthUser } from "src/modules/auth/selectors";
import { useAppSelector } from "src/store";

import { Icon } from "src/shared/atoms/Icons/Icon";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type ChangePasswordFormProps = {
    setIsCurrentlyModifying: (isModifying: boolean) => void;
};

export const ChangePasswordForm = ({ setIsCurrentlyModifying }: ChangePasswordFormProps) => {
    const [actualPassword, setActualPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordCriteria, setPasswordCriteria] = useState({
        minLength: false,
        upperCase: false,
        lowerCase: false,
        number: false,
        specialChar: false,
    });
    const [passwordCriteriaAllValid, setIsPasswordCriteriaAllValid] = useState(false);
    const [passwordConfirmationIsSame, setPasswordConfirmationIsSame] = useState(false);
    const [isPasswordEditMode, setIsPasswordEditMode] = useState(false);
    const [twoFactor, setTwoFactor] = useState("");
    const [twoFactorError, setTwoFactorError] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isValidateButtonDisabled, setIsValidateButtonDisabled] = useState(false);
    const [focusedPasswordForm, setFocusedPasswordForm] = useState(false);

    const authUser = useAppSelector((state) => getAuthUser(state));

    const numberOfValidCriteria = Object.values(passwordCriteria).filter(Boolean).length;

    const renderItem = (isValid: boolean, typo: string) => {
        return (
            <div className={`item ${!newPassword ? "" : isValid ? "valid" : "invalid"}`}>
                {newPassword.length > 0 && (
                    <Icon name={isValid ? "check-circle-filled" : "x-circle-filled"} size='sm' />
                )}
                <Typography message={t(typo)} />
            </div>
        );
    };

    useEffect(() => {
        const isEditing = actualPassword !== "" || newPassword !== "" || confirmPassword !== "";
        setIsCurrentlyModifying(isEditing);
    }, [actualPassword, newPassword, confirmPassword]);

    const handleSendNewPassword = () => {
        setTwoFactorError("");
        setErrorMessage("");
        requestUpdateAuthUserPassword({ password: actualPassword, newPassword, twoFactor })
            .catch((e) => {
                if (e.message === "Api-errors.user-2fa-code-invalid") {
                    setTwoFactorError(e.message);
                } else {
                    console.log({ e });
                    setErrorMessage(e.message);
                }
                return;
            })
            .then((res) => {
                const accessToken = getInStorage("accessToken");
                if (res && accessToken) {
                    toast.success(t("PasswordEdition.password-well-changed"));
                    setIsPasswordEditMode(false);
                    setInStorage("accessToken", {
                        id: res.token,
                        userId: accessToken.userId,
                        createdAt: accessToken.createdAt,
                    });
                }
            });
    };

    useEffect(() => {
        const checks = {
            minLength: newPassword.length >= 8,
            upperCase: /[A-Z]/.test(newPassword),
            lowerCase: /[a-z]/.test(newPassword),
            number: /\d/.test(newPassword),
            specialChar: /[~`!@#$%^&*+=\[\]\\;',/{}|":<>?().-]/.test(newPassword),
        };

        setPasswordCriteria(checks);
    }, [newPassword]);

    useEffect(() => {
        setIsPasswordCriteriaAllValid(
            passwordCriteria.minLength &&
                passwordCriteria.upperCase &&
                passwordCriteria.lowerCase &&
                passwordCriteria.number &&
                passwordCriteria.specialChar
        );
    }, [passwordCriteria]);

    useEffect(() => {
        setPasswordConfirmationIsSame(newPassword === confirmPassword);
    }, [confirmPassword, newPassword]);

    const handleSetEditMode = () => {
        setIsPasswordEditMode(!isPasswordEditMode);
    };

    useEffect(() => {
        setActualPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setTwoFactor("");
        setTwoFactorError("");
        setErrorMessage("");
    }, [isPasswordEditMode]);

    const handleActualPasswordChange = (value: string) => {
        setActualPassword(value);
    };

    const handleNewPasswordChange = (value: string) => {
        setNewPassword(value);
    };

    const handleChangeTwoFactor = (value: string) => {
        setTwoFactor(value);
    };

    const handleConfirmPasswordChange = (value: string) => {
        setConfirmPassword(value);
    };

    useEffect(() => {
        const isDisabled =
            passwordConfirmationIsSame &&
            passwordCriteriaAllValid &&
            actualPassword !== "" &&
            (authUser?.tfaActive ? twoFactor !== "" : true);

        setIsValidateButtonDisabled(isDisabled);
    }, [passwordConfirmationIsSame, passwordCriteriaAllValid, actualPassword, authUser, twoFactor]);

    const handleSetFocusedPasswordForm = () => {
        setFocusedPasswordForm(true);
    };

    const handleKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === "Enter") {
                if (isValidateButtonDisabled) {
                    handleSendNewPassword();
                }
            }
        },
        [handleSendNewPassword]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);
        return () => document.removeEventListener("keydown", handleKeyPress);
    }, [handleKeyPress]);

    return (
        <FormContainer
            icon={"lock"}
            title={t("Profile.your-password")}
            editMode={{ isEditMode: isPasswordEditMode, setIsEditMode: handleSetEditMode }}
            testId='edit-password-form'
            collapsed
            buttons={{
                primary: {
                    label: t("CommonUse.validate"),
                    onClick: handleSendNewPassword,
                    disabled: !isValidateButtonDisabled,
                    variant: "primary",
                    testId: "validate-edit-form",
                },
                secondary: { label: t("CommonUse.cancel"), onClick: handleSetEditMode, variant: "tertiary" },
            }}
            children={
                <div className='password-change-form'>
                    <Input
                        testId='input-old-password'
                        autoFocus
                        onChange={handleActualPasswordChange}
                        value={actualPassword}
                        type='password'
                        label={t<string>("PasswordEdition.actual-password")}
                        placeholder={t("PasswordEdition.fill-your-actual-passwrd")}
                    />
                    <Input
                        testId='input-new-password'
                        onChange={handleNewPasswordChange}
                        value={newPassword}
                        type='password'
                        label={t<string>("PasswordEdition.new-password")}
                        placeholder={t("PasswordEdition.fill-your-new-password")}
                        variant={passwordCriteriaAllValid ? "success" : "primary"}
                        onFocusCapture={handleSetFocusedPasswordForm}
                    />

                    {(newPassword || focusedPasswordForm) && (
                        <div className='password-validation-checker'>
                            <Typography
                                message={t("PasswordEdition.password-must-be-at-least")}
                                className={
                                    !newPassword
                                        ? "color-neutral-500"
                                        : passwordCriteriaAllValid
                                        ? "color-success"
                                        : "color-error"
                                }
                            />
                            {renderItem(passwordCriteria.minLength, "PasswordEdition.height-char")}
                            {renderItem(passwordCriteria.upperCase, "PasswordEdition.one-big-letter")}
                            {renderItem(passwordCriteria.lowerCase, "PasswordEdition.one-small-letter")}
                            {renderItem(passwordCriteria.number, "PasswordEdition.one-number")}
                            {renderItem(passwordCriteria.specialChar, "PasswordEdition.special-char")}
                            {newPassword.length > 0 && (
                                <div className='progress-bar-container'>
                                    <div className='progress-bar'>
                                        {Array.from({ length: 5 }).map((_, index) => (
                                            <div
                                                key={index}
                                                className={`progress-bar-item ${
                                                    index < numberOfValidCriteria ? "valid" : ""
                                                } ${
                                                    numberOfValidCriteria < 2
                                                        ? "error"
                                                        : numberOfValidCriteria < 4
                                                        ? "warning"
                                                        : "success"
                                                } ${index === numberOfValidCriteria - 1 ? "last" : ""}`}
                                            />
                                        ))}
                                    </div>
                                    <Typography
                                        message={
                                            numberOfValidCriteria < 2
                                                ? t("PasswordEdition.weak")
                                                : numberOfValidCriteria < 4
                                                ? t("PasswordEdition.medium")
                                                : t("PasswordEdition.strong")
                                        }
                                        className={
                                            numberOfValidCriteria === 0
                                                ? "color-neutral-300"
                                                : numberOfValidCriteria < 2
                                                ? "color-error"
                                                : numberOfValidCriteria < 4
                                                ? "color-warning"
                                                : "color-success"
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    )}

                    <Input
                        testId='input-new-password-validation'
                        onChange={handleConfirmPasswordChange}
                        value={confirmPassword}
                        type='password'
                        label={t<string>("PasswordEdition.confirm-new")}
                        placeholder={t("PasswordEdition.confirm-new-password")}
                        variant={!confirmPassword ? "primary" : passwordConfirmationIsSame ? "success" : "error"}
                        underMessage={
                            !confirmPassword
                                ? undefined
                                : passwordConfirmationIsSame
                                ? undefined
                                : t<string>("PasswordEdition.password-are-different")
                        }
                    />

                    {authUser?.tfaActive && (
                        <Input
                            label={t<string>("PasswordModal.auth-code")}
                            id='otp'
                            name='otp'
                            placeholder={t("PasswordModal.add-temporary-code")}
                            value={twoFactor}
                            variant={twoFactorError ? "error" : "primary"}
                            onChange={handleChangeTwoFactor}
                            underMessage={twoFactorError ? t<string>(twoFactorError) : undefined}
                        />
                    )}

                    {errorMessage && (
                        <div className='error-message-container'>
                            <Icon name='x-circle-filled' size='sm' />
                            <Typography message={t<string>(errorMessage)} className='color-error' />
                        </div>
                    )}
                </div>
            }
        />
    );
};
