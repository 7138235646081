import { useTranslation } from "react-i18next";

import { getTaskToEdit } from "src/modules/tasks/selectors";
import {
    getTaskItems,
    getTaskItemsErrorsCount,
    getTaskItemsFileUploading,
    getTaskItemsIsUpdatingCount,
} from "src/modules/tasksItems/selectors";
import { useAppSelector } from "src/store";

import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type Props = {
    taskCreation: boolean;
    taskId: number | undefined;
};

export const EditTaskFooterStatus = ({ taskId, taskCreation }: Props) => {
    const { t } = useTranslation();

    const taskItems = useAppSelector((state) => getTaskItems(state, { taskId }));
    const taskItemsLoading = useAppSelector((state) => getTaskItemsFileUploading(state, { taskId }));
    const task = useAppSelector((state) => getTaskToEdit(state));
    const updateCount = useAppSelector((state) => getTaskItemsIsUpdatingCount(state, { taskId }));
    const errorsCount = useAppSelector((state) => getTaskItemsErrorsCount(state, { taskId }));

    const taskItemsLoadingLenght = Object.keys(taskItemsLoading ?? {}).length;
    const total = Object.keys(taskItems).length + taskItemsLoadingLenght;
    const current = total - (updateCount + taskItemsLoadingLenght);

    return taskCreation || Object.keys(taskItems).length === 0 ? null : updateCount || taskItemsLoadingLenght ? (
        <Typography
            className='fw-bold color-warning-500'
            message={`${t("CommonUse.checking")} (${current}/${total})`}
        />
    ) : task?.errorCode ? (
        <Typography className='fw-bold color-error-500' message={t("EditTaskFooterStatus.solde-insuffisant")} />
    ) : errorsCount > 0 ? (
        <Typography
            className='fw-bold color-error-500'
            message={t("EditTaskFooterStatus.operation-s-a-verifier", { count: errorsCount })}
        />
    ) : (
        <Typography
            className='fw-bold color-success-500'
            message={t("EditTaskFooterStatus.les-operations-sont-verifiees")}
        />
    );
};
