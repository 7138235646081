import { SelectOption } from "../components/SelectMultipleOptions/SelectMultipleOptions";

export const companyCollectorPartners: { [key: string]: SelectOption[] } = {
    CIV: [
        { id: "momo-deposit-collect-wave", label: "Wave" },
        { id: "momo-deposit-collect-ecobank", label: "Xpress" },
        { id: "momo-deposit-collect-yelemoney", label: "Yélé Money" },
    ],
    SEN: [
        { id: "momo-deposit-collect-wave", label: "Wave" },
        { id: "momo-deposit-collect-ecobank", label: "Xpress" },
        { id: "momo-deposit-collect-mixx", label: "Mixx by Yas" },
    ],
    BEN: [{ id: "momo-deposit-collect-mtn", label: "MTN" }],
};
