import { TaskItemModelToCreate } from "src/services/taskItems/types";

import { Dispatch, SetStateAction, createContext } from "react";

export type CreateTaskItemContextProps = {
    taskItems: { [uuid: string]: TaskItemModelToCreate } | undefined;
    setTaskItems: Dispatch<SetStateAction<{ [uuid: string]: TaskItemModelToCreate } | undefined>>;
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
};

export const CreateTaskItemContext = createContext<CreateTaskItemContextProps>({} as CreateTaskItemContextProps);
