import { RolesForm } from "../../Forms/Role/Role";
import classnames from "classnames";
import { isValidNumberForRegion } from "libphonenumber-js";
import { companyCollectorPartners } from "src/shared/const/partner";
import { EditUserContext } from "src/shared/context/editUser";
import { CompanyRole, phoneCountryCodes } from "src/shared/models/UserCompany";
import { formatPhone } from "src/shared/utils/formatPhone";

import { useContext, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAdminWalletsAsSelectOptions } from "src/modules/admin/adminWallets/selectors";
import { fetchAdminWallets } from "src/modules/admin/adminWallets/thunkActions";
import { getUserDetail } from "src/modules/admin/userDetail/selectors";
import { handlePhoneValidation } from "src/modules/admin/userDetail/thunkActions";
import { getAuthUser, getSelectedCompanyCountryTag } from "src/modules/auth/selectors";
import { useAppDispatch, useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { InputPhone, InputPhoneValue } from "src/shared/atoms/Inputs/Phone/Phone";
import { Skeleton } from "src/shared/atoms/Skeleton/Skeleton";
import { Switch } from "src/shared/atoms/Switch/Switch";
import { Tag } from "src/shared/atoms/Tag/Tag";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { SelectMultipleOptions, SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";
import { SimpleSelect } from "src/shared/components/SimpleSelect/SimpleSelect";

export function AdminUsersDetailDrawerSectionCollector() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { userDetail, userDetailLoading } = useAppSelector((state) => getUserDetail(state));
    const companyCountryTag = useAppSelector((state) => getSelectedCompanyCountryTag(state));
    const [form, setForm] = useState<RolesForm["collectorInfo"]>();
    const [selectedWallet, setSelectedWallet] = useState<SelectOption | undefined>();
    const [isCustomWavePhoneNumberOpen, setIsCustomWavePhoneNumberOpen] = useState(false);
    const [access, setAccess] = useState(false);
    const user = useAppSelector(getAuthUser);

    const { editingSection, setEditingSection, setPayload, handleOpenPasswordDialog } = useContext(EditUserContext);

    const [selectedPartners, setSelectedPartners] = useState<SelectOption[]>([]);
    const [partnerSelectionError, setPartnerSelectionError] = useState<string | null>(null);
    const showPartners = selectedPartners.map((partner) => partner.label).join(", ");

    const walletOptions = useAppSelector((state) => getAdminWalletsAsSelectOptions(state));

    const haveCollectorInfo = !!userDetail?.collectorInfo;
    const disabled = !access && !userDetail?.collectorInfo;
    const showAlert = !access && !!userDetail?.collectorInfo;
    const isEditing = editingSection === "collector";

    const [canEdit, setCanEdit] = useState(false);

    useEffect(() => {
        if (user?.role === CompanyRole.ADMIN || user?.role === CompanyRole.ADMIN_ONLY) {
            setCanEdit(true);
        }
        const availablePartners = companyCountryTag ? companyCollectorPartners[companyCountryTag] : [];
        if (userDetail?.collectorInfo?.services) {
            const services =
                (userDetail?.collectorInfo?.services
                    ?.map((service) => availablePartners.find((partner) => partner.id === service))
                    .filter((partner) => !!partner) as SelectOption[]) ?? [];

            setSelectedPartners(services);
        } else {
            setSelectedPartners(availablePartners);
        }
    }, [userDetail]);

    const [color, text] = useMemo(() => {
        if (!canEdit) {
            return ["color-lilas-500", t("CommonUse.non-modifiable")];
        } else if (!isEditing) {
            return ["color-lilas-900", t("CommonUse.modify")];
        } else {
            return ["color-warning", t("CommonUse.being-modified")];
        }
        // eslint-disable-next-line
    }, [canEdit, editingSection]);

    const handleToggleEditing = () => {
        if (canEdit) {
            setEditingSection(!editingSection ? "collector" : null);
            setPayload(null);
        }
    };

    const handleSwitchOpenCustomWavePhoneNumberOpen = () => {
        setIsCustomWavePhoneNumberOpen((oldValue) => {
            if (oldValue === true && form?.phone) {
                setForm({ ...form, phone: userDetail?.phone });
            }
            return !oldValue;
        });
    };

    const handleSelectWallet = (wallet: SelectOption) => {
        setSelectedWallet(wallet);
    };

    const handleChangePhone = (newPhone: InputPhoneValue) => {
        setForm({ ...form, phone: newPhone.value });
        setPayload({
            accessType: userDetail?.accessType,
            collectorInfo: { ...form, phone: newPhone.value },
        });
    };

    const handleSwitchAccess = () => {
        setAccess((value) => !value);
        setForm({ ...form, services: selectedPartners.map((partner) => partner.id) });
        setPayload({
            accessType: userDetail?.accessType,
            collectorInfo: { ...form, services: selectedPartners.map((partner) => partner.id) },
        });
    };

    const handleDeleteCollectorSettings = () => {
        setPayload({ accessType: userDetail?.accessType });
        handleOpenPasswordDialog();
    };

    useEffect(() => {
        dispatch(
            fetchAdminWallets({
                page: -1,
                archived: false,
            })
        );
    }, [dispatch]);

    useEffect(() => {
        setAccess(!!userDetail?.collectorInfo);
        setPayload({
            accessType: userDetail?.accessType,
            collectorInfo: {
                ...form,
                walletId: form?.wallet?.id,
                services: selectedPartners.map((partner) => partner.id),
            },
        });
    }, [userDetail]);

    useEffect(() => {
        if (
            isCustomWavePhoneNumberOpen &&
            form?.phone &&
            form?.countryCode &&
            isValidNumberForRegion(form.phone, phoneCountryCodes[form.countryCode])
        ) {
            dispatch(
                handlePhoneValidation({
                    countryCode: form?.countryCode,
                    phone: form?.phone,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, form?.phone]);

    useEffect(() => {
        if (userDetail?.collectorInfo) {
            const walletOption = walletOptions.find(
                (wallet) => wallet.id === String(userDetail?.collectorInfo?.wallet?.id)
            );
            setSelectedWallet(walletOption);
            setForm({
                countryCode: userDetail?.countryCode,
                phone: userDetail?.phone,
                walletId: userDetail?.collectorInfo?.wallet?.id,
                services: userDetail?.collectorInfo?.services,
            });
        } else {
            setForm({ countryCode: userDetail?.countryCode, phone: userDetail?.phone });
        }
    }, [userDetail, walletOptions]);

    const handleChangePartnerSelection = (partners: SelectOption[]) => {
        setSelectedPartners(partners);
    };

    useEffect(() => {
        setForm({
            ...form,
            countryCode: userDetail?.countryCode,
            phone: isCustomWavePhoneNumberOpen ? form?.phone : userDetail?.phone,
            walletId: Number(selectedWallet?.id),
            services: selectedPartners.map((partner) => partner.id),
        });
        setPayload({
            accessType: userDetail?.accessType,
            collectorInfo: {
                ...form,
                countryCode: userDetail?.countryCode,
                phone: isCustomWavePhoneNumberOpen ? form?.phone : userDetail?.phone,
                walletId: Number(selectedWallet?.id),
                services: selectedPartners.map((partner) => partner.id),
            },
        });
        setPartnerSelectionError(selectedPartners.length > 0 ? null : t("Users.choose-at-least-one-partner"));
    }, [userDetail, selectedWallet, selectedPartners]);

    return (
        <div
            className={classnames("drawer-content-section users-details-drawer-phone", {
                disabled: editingSection && !isEditing,
            })}
        >
            <div className='drawer-content-section-header'>
                <Typography className='fw-bold' message={t("Users.mobile-money")} />
                <div className={classnames({ disabled: editingSection && !isEditing })} onClick={handleToggleEditing}>
                    <Typography className={`fw-bold cursor-pointer ${color}`} message={text} />
                </div>
            </div>

            {isEditing ? (
                <>
                    <div className='drawer-content-section-body'>
                        <div className='user-drawer-roles-form'>
                            <div className='collector-role-form'>
                                <div className='access-text'>
                                    <Switch isOn={access} onToggle={handleSwitchAccess} />
                                    <Typography className='fw-bold' message={t("Users.collector-access")} />
                                </div>
                                {showAlert && (
                                    <Alert
                                        message={t("Users.what-happen-delete-collector-title")}
                                        subMessage={t<string>("Users.what-happen-delete-collector-description")}
                                        icon='exclamation-triangle'
                                        color='error'
                                    />
                                )}
                                <div className='wave-text'>
                                    <Avatar icon='question-mark-circle' backgroundColor='lilas-50' />
                                    <Typography message={t("Roles.collector-access")} />
                                </div>
                                <SelectMultipleOptions
                                    label={t<string>("Users.choose-partner")}
                                    onSelectionChange={handleChangePartnerSelection}
                                    showSelectedItems={true}
                                    selected={selectedPartners}
                                    items={companyCountryTag ? companyCollectorPartners[companyCountryTag] : []}
                                    name={t<string>("Users.choose-partner-placeholder")}
                                    disabled={!access}
                                    underMessage={partnerSelectionError ?? undefined}
                                    variant={partnerSelectionError ? "error" : "primary"}
                                />
                                <InputPhone
                                    disabled={!isCustomWavePhoneNumberOpen || !access}
                                    label={t<string>("CommonUse.phone-number")}
                                    placeholder={t("Users.enter-phone-number")}
                                    onChange={handleChangePhone}
                                    disablePhonePrefix
                                    prefix={form?.countryCode}
                                    value={form?.phone}
                                />
                                {!haveCollectorInfo && (
                                    <>
                                        <Alert
                                            color='warning'
                                            icon='exclamation-triangle'
                                            message={t<string>("Users.choose-collector-phone-warning")}
                                        />
                                        <Switch
                                            disabled={!access}
                                            label={t<string>("Users.use-another-number")}
                                            isOn={isCustomWavePhoneNumberOpen}
                                            onToggle={handleSwitchOpenCustomWavePhoneNumberOpen}
                                        />
                                    </>
                                )}
                                <SimpleSelect
                                    disabled={!access}
                                    useRadio
                                    placeholder={t<string>("CommonUse.select-account")}
                                    label={t<string>("TopUp.mobile-money-wallet-deposit")}
                                    options={walletOptions}
                                    selected={selectedWallet}
                                    onSelectionChange={handleSelectWallet}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='drawer-content-section-footer'>
                        <div className='button-part'>
                            <Button label={t("CommonUse.cancel")} variant='tertiary' onClick={handleToggleEditing} />
                            <Button
                                disabled={disabled || !selectedWallet || selectedPartners.length === 0}
                                label={t(!haveCollectorInfo ? "Users.activate-collector" : "CommonUse.modify")}
                                onClick={showAlert ? handleDeleteCollectorSettings : handleOpenPasswordDialog}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <div className='drawer-content-section-body drawer-content-gap'>
                    <div className='drawer-content-phone'>
                        <Typography
                            className='fw-bold color-neutral-500 color-neutral'
                            message={t("Users.collect-status")}
                        />
                        <div>
                            {!userDetailLoading ? (
                                <Tag
                                    type='accent'
                                    size='lg'
                                    label={t<string>(haveCollectorInfo ? "Bills.active" : "Users.no-access")}
                                    icon={haveCollectorInfo ? "check-circle" : "x-circle"}
                                    color={haveCollectorInfo ? "success" : "neutral"}
                                />
                            ) : (
                                <Skeleton />
                            )}
                        </div>
                    </div>
                    <div className='drawer-content-phone'>
                        <Typography className='fw-bold color-neutral-500 color-neutral' message={t("Users.partners")} />
                        {!userDetailLoading ? (
                            <Typography className='fw-bold' message={haveCollectorInfo ? showPartners : "-"} />
                        ) : (
                            <Skeleton />
                        )}
                    </div>
                    <div className='drawer-content-phone'>
                        <Typography
                            className='fw-bold color-neutral-500 color-neutral'
                            message={t("CommonUse.phone-number")}
                        />
                        {!userDetailLoading ? (
                            <Typography
                                className='fw-bold'
                                message={haveCollectorInfo ? formatPhone(userDetail?.collectorInfo?.phone) : "-"}
                            />
                        ) : (
                            <Skeleton />
                        )}
                    </div>
                    <div className='drawer-content-phone'>
                        <Typography
                            className='fw-bold color-neutral-500 color-neutral'
                            message={t("Users.collect-account")}
                        />
                        {!userDetailLoading ? (
                            <Typography
                                className='fw-bold'
                                message={
                                    haveCollectorInfo
                                        ? `${userDetail?.collectorInfo?.wallet?.label} - ${userDetail?.collectorInfo?.wallet?.reference}`
                                        : "-"
                                }
                            />
                        ) : (
                            <Skeleton />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
