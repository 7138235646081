import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { WalletApi } from "src/services/wallets/types";
import { DrawerContext, DrawerDisplays } from "src/wrapper/context";

import { useContext } from "react";

import { useTranslation } from "react-i18next";

import { getSelectedCompanyCountryTag } from "src/modules/auth/selectors";
import { getWallet } from "src/modules/wallets/selectors";
import { useAppSelector } from "src/store";

import { Icon } from "src/shared/atoms/Icons/Icon";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { List } from "src/shared/components/ExpandableList/ExpandableList";

import "./styles.scss";

type TopUpMethod = {
    title: string;
    description: string;
    deadline?: string;
    icon: IconListType;
    link?: string;
    isDisabled?: (wallet: WalletApi | undefined) => boolean;
    isCountryDissalowed?: (countryCode: string) => boolean;
    soonChip?: boolean;
    drawer?: DrawerDisplays;
    id?: string;
};

const topUpMethods: TopUpMethod[] = [
    {
        title: "TopUp.iban",
        description: "TopUp.iban-description",
        icon: "bank",
        drawer: "iban",
        deadline: "Iban.see-bank-details",
        isCountryDissalowed: (countryCode: string) => ["BEN", "TGO"].includes(countryCode),
        isDisabled: (wallet: WalletApi | undefined) => !wallet?.iban,
        id: "drawer_topup_iban",
    },
    {
        title: "TopUp.bank-wire",
        description: "TopUp.bank-wire-description",
        deadline: "TopUp.deadline-one-day",
        icon: "arrows-right-left",
        link: "/transfer/bank-disposal",
        id: "drawer_topup_bank-transfer",
    },
    {
        title: "TopUp.collect-method-title",
        description: "TopUp.collect-method-description",
        deadline: "TopUp.deadline-now",
        icon: "get-paid",
        isCountryDissalowed: (countryCode: string) => ["TGO"].includes(countryCode),
        link: "/transfer/collect",
    },
    {
        title: "Export.credit",
        description: "TopUp.credit-description",
        deadline: "TopUp.deadline-less-than-one-day",
        icon: "bills",
        link: "/loan/my-loans",
        isCountryDissalowed: (countryCode: string) => ["TGO"].includes(countryCode),
        id: "drawer_topup_credit",
    },
];

export function TopUpDrawerBody() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { providedState, setOpen } = useContext(DrawerContext);

    const selectedWallet = useAppSelector((state) =>
        providedState?.walletId ? getWallet(state, { walletId: parseInt(providedState.walletId) }) : undefined
    );

    const companyCountryTag = useAppSelector((state) => getSelectedCompanyCountryTag(state));

    const handleRedirect = (link: string) => () => {
        navigate(link, { ...(providedState?.sendStateOnRedirection ? { state: providedState } : {}) });
        setOpen(undefined);
    };

    const handleOpenDrawer = (drawer: DrawerDisplays) => () => {
        setOpen(drawer);
    };

    return (
        <div className='top-up-drawer-body-container'>
            <List
                extended
                animation='fadeInRight'
                keyName='transfer-drawer-body-item'
                items={topUpMethods.map(
                    ({
                        title,
                        description,
                        deadline,
                        icon,
                        link,
                        drawer,
                        isDisabled,
                        isCountryDissalowed,
                        soonChip,
                        id,
                    }) => {
                        const disabled = isDisabled ? isDisabled(selectedWallet) : false;

                        return isCountryDissalowed && isCountryDissalowed(companyCountryTag ?? "") ? null : (
                            <div
                                key={title}
                                onClick={
                                    disabled || (!link && !drawer)
                                        ? undefined
                                        : drawer
                                        ? handleOpenDrawer(drawer)
                                        : handleRedirect(link ?? "")
                                }
                                id={id}
                                className={classNames("top-up-drawer-body-section", { disabled, active: !disabled })}
                            >
                                <div>
                                    <Icon name={icon} variant='squared' color='lilas' />
                                </div>
                                <div className='d-flex w-100 align-items-center justify-content-between'>
                                    <div className='top-up-drawer-deadline '>
                                        <div className='d-flex flex-column'>
                                            <Typography className='fw-bold' message={t(title)} />
                                            <Typography message={t(description)} />
                                        </div>
                                        {deadline && <Typography className='fw-bold' message={t(deadline)} />}
                                    </div>
                                    {soonChip && (
                                        <Typography className='top-up-drawer-body-chip' message={t("TopUp.soon")} />
                                    )}
                                </div>
                            </div>
                        );
                    }
                )}
            />
        </div>
    );
}
