import { CompanyKybLevel, CompanyRole, CompanyStatus } from "./UserCompany";
import { BillingBiller } from "src/services/billing/biller/type";

export type FavoriteBiller = {
    companyId: string | number;
    favoriteBillerId: string | number;
    Biller: BillingBiller;
};

export enum CompanySubscriptionPlanName {
    PRO = "Pro",
    PREMIUM = "Premium",
    LEGACY = "Legacy",
    DISCOVER = "Découverte",
}

export enum CompanySubscriptionPlanPaidStatus {
    TO_PAY = "to-pay",
    FREE = "free",
    PAID = "paid",
}

export type CompanySubscriptionPlan = {
    name: CompanySubscriptionPlanName;
    paidStatus: CompanySubscriptionPlanPaidStatus;
    price: number;
    endsAt: string;
    acls: string[];
    slug: string;
};

export type AuthCompany = {
    id: number;
    coreId: number;
    name: string;
    role: CompanyRole;
    nbrUsers: number;
    hasAccess: boolean;
    avatar: null | string;
    nbCollectors: number;
    createdAt: string;
    kyb: CompanyKybLevel | null;
    favoriteBillers?: FavoriteBiller[];
    status: CompanyStatus;
    blockReason: string | null;
    reference: string | null;
    plan?: CompanySubscriptionPlan;
    country: "civ" | "sen" | "ben" | "tgo";
};
