import { GoFurtherWithJulaya } from "./GoFurtherWithJulaya";
import { AdminDocumentsItem } from "./Item";
import { DocumentStatusKey, RoleDocuments } from "src/shared/models/Document";

import { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import { useAdminDocuments } from "src/modules/admin/documents/hooks";
import { getAdminDocumentsLoading } from "src/modules/admin/documents/selectors";
import { useAppSelector } from "src/store";

import { Accordion } from "src/shared/components/Accordion/Accordion";

import "./styles.scss";

export function AdminDocumentsPanel() {
    const { t } = useTranslation();
    const documents = useAdminDocuments();
    const loading = useAppSelector((state) => getAdminDocumentsLoading(state));

    const [isAccordionOpenned, setIsAccordionOpenned] = useState(false);
    const [isSecondAccordionOpenned, setIsSecondAccordionOpenned] = useState(false);

    const approvedAccordionRef = useRef<HTMLDivElement>(null);
    const certifiedAccordionRef = useRef<HTMLDivElement>(null);

    const renderStepAccordionSubtitle = (documentStatus: DocumentStatusKey) => {
        switch (documentStatus) {
            case "done":
                return "AdminDocumentsItem.documents-valides";
            case "waiting":
                return "AdminDocumentsItem.doc-in-progress";
            case "failed":
                return "Documents.refused";
            case "missing":
                return "AdminDocumentsItem.add-and-send-doc";
        }
    };

    const renderStepAccordionIcon = (documentStatus: DocumentStatusKey) => {
        switch (documentStatus) {
            case "done":
                return "check-circle";
            case "waiting":
                return "clock";
            case "failed":
                return "x-circle";
            case "missing":
                return "arrow-right-circle";
        }
    };

    const renderStepAccordionColor = (documentStatus: DocumentStatusKey) => {
        switch (documentStatus) {
            case "done":
                return "success";
            case "waiting":
                return "warning";
            case "failed":
                return "error";
            case "missing":
                return "lilas";
        }
    };

    const handleOpenAccordion = (accordion: string) => {
        if (accordion === "approved") {
            setIsAccordionOpenned(true);
        }
        if (accordion === "certified") {
            setIsSecondAccordionOpenned(true);
        }
    };

    useEffect(() => {
        if (isAccordionOpenned) {
            approvedAccordionRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [isAccordionOpenned]);

    useEffect(() => {
        if (isSecondAccordionOpenned) {
            certifiedAccordionRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [isSecondAccordionOpenned]);

    useEffect(() => {
        setIsAccordionOpenned(documents?.currentRole === "user");
        setIsSecondAccordionOpenned(documents?.currentRole === "kyb1");
    }, [documents]);

    const filterDocuments = (
        kyb2Documents: RoleDocuments["documents"],
        kyb1Documents: RoleDocuments["documents"]
    ): RoleDocuments["documents"] => {
        const kyb1DocumentSlugs = new Set(
            Object.values(kyb1Documents).flatMap((category: any) => Object.values(category).map((doc: any) => doc.slug))
        );
        return Object.entries(kyb2Documents).reduce((acc: any, [groupName, documentCategory]: any) => {
            const filteredCategory = Object.values(documentCategory).filter(
                (doc: any) => !kyb1DocumentSlugs.has(doc.slug)
            );
            if (filteredCategory.length > 0) {
                acc[groupName] = filteredCategory;
            }
            return acc;
        }, {});
    };

    const filteredKyb2Documents =
        documents && documents.kyb2 && documents.kyb1
            ? filterDocuments(documents.kyb2.documents, documents.kyb1.documents)
            : {};

    return (
        <>
            {loading && documents === null ? (
                <div className='loading-placeholder'>
                    <div className='placeholder' />
                    <div className='placeholder' />
                    <div className='placeholder' />
                    <div className='placeholder' />
                </div>
            ) : (
                <div className='admin-document-panel-container'>
                    {documents && (
                        <GoFurtherWithJulaya
                            currentRole={documents.currentRole}
                            handleOpenAccordion={handleOpenAccordion}
                        />
                    )}
                    {documents && documents.kyb1 && (
                        <Accordion
                            icon={renderStepAccordionIcon(documents.kyb1.status)}
                            iconColor={renderStepAccordionColor(documents.kyb1.status) + "-50"}
                            iconStatus={renderStepAccordionColor(documents.kyb1.status)}
                            title={t("KybIndicator.approved-status")}
                            subTitle={t<string>(renderStepAccordionSubtitle(documents.kyb1.status))}
                            color='white'
                            isOpened={isAccordionOpenned}
                        >
                            <div className='admin-documents-item-container' ref={approvedAccordionRef}>
                                {Object.entries(documents.kyb1.documents).map(([groupName, documentCategory]) =>
                                    Object.values(documentCategory).map((document) => (
                                        <AdminDocumentsItem
                                            key={document.id}
                                            document={{ ...document, group: groupName }}
                                        />
                                    ))
                                )}
                            </div>
                        </Accordion>
                    )}
                    {documents && documents.kyb2 && (
                        <Accordion
                            icon={renderStepAccordionIcon(documents.kyb2.status)}
                            iconColor={renderStepAccordionColor(documents.kyb2.status) + "-50"}
                            iconStatus={renderStepAccordionColor(documents.kyb2.status)}
                            title={t("KybIndicator.certified-status")}
                            subTitle={t<string>(renderStepAccordionSubtitle(documents.kyb2.status))}
                            color='white'
                            isOpened={isSecondAccordionOpenned}
                        >
                            <div className='admin-documents-item-container' ref={certifiedAccordionRef}>
                                {Object.entries(filteredKyb2Documents).map(([groupName, documentCategory]) =>
                                    Object.values(documentCategory).map((document) => (
                                        <AdminDocumentsItem
                                            key={document.id}
                                            document={{ ...document, group: groupName }}
                                        />
                                    ))
                                )}
                            </div>
                        </Accordion>
                    )}
                </div>
            )}
        </>
    );
}
