import { CreateUserDocumentParams, CreateUserParams } from "src/services/admin/types";

import { Dispatch, SetStateAction, createContext } from "react";

import { SelectOption } from "../components/SelectMultipleOptions/SelectMultipleOptions";

export type UserValuesType = Partial<CreateUserParams & CreateUserDocumentParams>;

export type CreateUserContextProps = {
    userValues: UserValuesType | undefined;
    setUserValues: Dispatch<SetStateAction<UserValuesType | undefined>>;
    attachToWallet?: boolean;
    initState: () => void;
    selectedPartners: SelectOption[];
    setSelectedPartners: Dispatch<SetStateAction<SelectOption[]>>;
};

export const CreateUserContext = createContext<CreateUserContextProps>({} as CreateUserContextProps);
