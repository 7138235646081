import classnames from "classnames";
import { EditUserContext } from "src/shared/context/editUser";
import { formatPhone } from "src/shared/utils/formatPhone";

import { useContext } from "react";

import { useTranslation } from "react-i18next";

import { getUserDetail } from "src/modules/admin/userDetail/selectors";
import { useAppSelector } from "src/store";

import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

export function AdminAccountDetailDrawerSectionsEmail() {
    const { editingSection } = useContext(EditUserContext);
    const { userDetail } = useAppSelector((state) => getUserDetail(state));
    const { t } = useTranslation();

    return (
        <div className={classnames("drawer-content-section users-details-drawer-phone", { disabled: editingSection })}>
            <div className='drawer-content-section-header'>
                <Typography className='fw-bold' message={t("Users.identifier")} />
                <Typography className='fw-bold color-lilas-500' message={t("CommonUse.non-modifiable")} />
            </div>
            <div className='drawer-content-section-body drawer-content-phone'>
                <Typography
                    className='fw-bold text-muted color-neutral'
                    message={t("AdminAccountsFormsProfile.adresse-mail")}
                />
                <Typography className='fw-bold text-muted' message={formatPhone(userDetail?.email)} />
            </div>
        </div>
    );
}
