import { UserModel } from "src/shared/models/User";
import { validateField } from "src/shared/utils/validateField";

import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { useDebounce } from "src/shared/hooks/useDebounce";

import { Input } from "src/shared/atoms/Inputs/Input";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

import "./styles.scss";

const genderOptionsRaw = (t: (key: string) => string): SelectOption[] => [
    { id: t("AdminAccountsFormsProfile.m"), label: t("CommonUse.mr") },
    { id: t("AdminAccountsFormsProfile.f"), label: t("CommonUse.mme") },
];

type ProfileKeys = "firstname" | "lastname" | "email" | "customReference";
type ProfileForm = Pick<UserModel, ProfileKeys>;
export type ProfileParams = ProfileForm;

type AdminAccountsFormsProfileProps = {
    detail?: UserModel | null;
    edition?: boolean;
    onChange: (values: ProfileParams) => void;
    createCollector?: boolean;
};

export const AdminAccountsFormsProfile = ({
    detail,
    edition,
    onChange,
    createCollector,
}: AdminAccountsFormsProfileProps) => {
    const { t } = useTranslation();

    const [fields, setFields] = useState<ProfileParams>({
        firstname: "",
        lastname: "",
        email: "",
        customReference: "",
    });
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isEmailTouched, setIsEmailTouched] = useState(false);

    const GenderOptions = useMemo(() => genderOptionsRaw(t), [t]);

    useEffect(() => {
        onChange(fields);
    }, [fields]);

    useEffect(() => {
        if (detail) {
            setFields({
                firstname: detail.firstname,
                lastname: detail.lastname,
                email: detail.email ?? "",
                customReference: detail.UserCompanies?.[0]?.customReference ?? "",
            });
        }
    }, [detail, GenderOptions]);

    const handleChangeField = (name: keyof ProfileParams, type: "text" | "email" | "common") => (value: string) => {
        setFields((prev) => ({ ...prev, [name]: value }));
        if (name !== "email") {
            const { isValid, errorKey } = validateField(type, value);
            setErrors((prev) => (isValid ? { ...prev, [name]: "" } : { ...prev, [name]: t<string>(errorKey!) }));
        }
    };

    const debouncedEmail = useDebounce(fields.email, 700);

    useEffect(() => {
        if (debouncedEmail && !validateField("email", debouncedEmail).isValid) {
            setErrors((prev) => ({ ...prev, email: t("CommonUse.invalid-email") }));
        } else {
            setErrors((prev) => {
                const { email, ...rest } = prev;
                return rest;
            });
        }
    }, [debouncedEmail, t]);

    const handleBlur = (name: string) => () => {
        if (name === "email") {
            setIsEmailTouched(true);

            if (fields.email && !validateField("email", fields.email).isValid) {
                setErrors((prev) => ({ ...prev, email: t("CommonUse.invalid-email") }));
            } else {
                setErrors((prev) => {
                    const { email, ...rest } = prev;
                    return rest;
                });
            }
        }
    };

    return (
        <div className='profile-form-container'>
            {edition ? null : (
                <>
                    <div className='d-flex flex-row gap-2'>
                        <Input
                            disabled={edition}
                            label={t<string>("CommonUse.firstnames")}
                            name='firstname'
                            value={fields.firstname}
                            placeholder={t<string>("CommonUse.firstnames")}
                            onChange={handleChangeField("firstname", "text")}
                            variant={errors.firstname ? "error" : undefined}
                            underMessage={errors.firstname}
                            className='w-100'
                        />
                        <Input
                            disabled={edition}
                            label={t<string>("CommonUse.lastname")}
                            name='lastname'
                            value={fields.lastname}
                            placeholder={t<string>("CommonUse.lastname")}
                            onChange={handleChangeField("lastname", "text")}
                            variant={errors.lastname ? "error" : undefined}
                            underMessage={errors.lastname}
                            className='w-100'
                        />
                    </div>
                    {!createCollector && (
                        <Input
                            disabled={edition}
                            inputIcon='envelope'
                            label={t<string>("AdminAccountsFormsProfile.adresse-mail")}
                            name='email'
                            value={fields.email}
                            placeholder={t("AdminAccountsFormsProfile.adresse-mail")}
                            onChange={handleChangeField("email", "email")}
                            onBlur={handleBlur("email")}
                            variant={errors.email || (isEmailTouched && !fields.email) ? "error" : "primary"}
                            underMessage={errors.email}
                        />
                    )}
                </>
            )}
            <Input
                label={t<string>("Users.user-reference") + " (" + t<string>("CommonUse.facultative") + ")"}
                name='customReference'
                value={fields.customReference}
                onChange={handleChangeField("customReference", "text")}
                placeholder={t<string>("Users.personalized-reference")}
                variant={errors.customReference ? "error" : undefined}
                underMessage={errors.customReference}
            />
        </div>
    );
};
