import "./App.scss";
import { BaseRouter } from "./routes/BaseRouter";
import { DEMO_WARNING, JULAYA_APP_DEVELOP_URL, JULAYA_APP_URL, REACT_APP_ENV } from "./services/utils/const";
import { DrawerWrapper } from "./wrapper/drawer";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useEffect, useState } from "react";

import "./i18n";
import { useTranslation } from "react-i18next";
import { Provider } from "react-redux";

import { store } from "./store";

import { StickyAlert } from "./shared/components/StickyAlert/StickyAlert";

export const App = () => {
    const { t } = useTranslation();
    const pathHasPayment = window.location.pathname.includes("payment");
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
    const location = useLocation();

    const pathname = location.pathname;
    const isResetForm = pathname.includes("/reset-password/");

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 768);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (isMobileView && !pathHasPayment && !isResetForm) {
            window.location.replace(REACT_APP_ENV === "live" ? JULAYA_APP_URL : JULAYA_APP_DEVELOP_URL);
        }
    }, [isMobileView, isResetForm]);

    return (
        <>
            <Provider store={store}>
                <div className='App'>
                    {DEMO_WARNING && (!isMobileView || pathHasPayment) && (
                        <StickyAlert label={t("CommonUse.demo-warning")} />
                    )}
                    <DrawerWrapper>
                        <BaseRouter />
                    </DrawerWrapper>
                </div>
            </Provider>
            <ToastContainer
                position='bottom-right'
                autoClose={5000}
                hideProgressBar={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='light'
            />
        </>
    );
};
