import { LoginCodeValidation } from "../CodeValidation";
import { LoginDisplays } from "../Panel";
import { LoginSuccess } from "../Success";
import { useNavigate } from "react-router-dom";
import { resendPhoneVerificationCode, sendPhoneVerificationCodeReceived } from "src/services/auth/operations";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";

const TIMER_DURATION_IN_SECONDES = 15;
let interval: ReturnType<typeof setInterval> | undefined;

type Props = {
    countryCode: string;
    phone: string;
    handleSetFormToDisplay: (switchName: LoginDisplays) => () => void;
};

export function RegisterPhoneCode({ phone, countryCode, handleSetFormToDisplay }: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [pinCode, setPinCode] = useState("");
    const [seconds, setSeconds] = useState(TIMER_DURATION_IN_SECONDES);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState<string | undefined>();

    const handleSendAnotherCode = () => {
        resendPhoneVerificationCode({ phone, countryCode });
        clearInterval(interval);
        setSeconds(TIMER_DURATION_IN_SECONDES);
    };

    const handleCheckCode = async () => {
        if (pinCode.length === 6) {
            setLoading(true);
            sendPhoneVerificationCodeReceived({ phone, countryCode, otp: pinCode })
                .then(() => setSuccess(true))
                .catch((error) => setError(error))
                .finally(() => setLoading(false));
        }
    };

    const handleRedirect = () => {
        navigate("/");
        handleSetFormToDisplay("login")();
    };

    return success ? (
        <LoginSuccess
            title={"UserCreateDrawerContainer.congratulation-phone-verified"}
            description={"LoginPanel.email-verified-success-description"}
            successAction={handleRedirect}
            successLabel={"UserCreateDrawerContainer.access-to-platform"}
        />
    ) : (
        <>
            <Typography
                variant='h3'
                message={t("UserCreateDrawerContainer.verify-phone-number")}
                className='fw-bold mb-1'
            />
            <Typography
                message={t("UserCreateDrawerContainer.verify-phone-number-description")}
                className='color-neutral-500 fw-bold'
            />
            <LoginCodeValidation setPinCode={setPinCode} />
            {error ? (
                <div className='d-flex align-items-center' data-testid='code-validation-error'>
                    <Icon name='x-circle-filled' className='mr-2' color='error' />
                    <Typography className='color-error-500' message={t<string>(error)} />
                </div>
            ) : null}
            <div className='d-flex mt-2'>
                <Button
                    disabled={loading}
                    variant='tertiary'
                    className='w-50 mr-2'
                    onClick={handleSetFormToDisplay("login")}
                    label={t("CommonUse.cancel")}
                />
                <Button
                    disabled={loading || pinCode.length < 6}
                    className='w-50'
                    onClick={handleCheckCode}
                    label={t("CommonUse.validate")}
                    testId='code-validation-continue-button'
                />
            </div>
            <div className='login-code-validation-sentence'>
                <Typography className='color-neutral-500 mr-1' message={t("LoginPasswordReset.no-code")} />
                {seconds > 0 && (
                    <Typography
                        className='color-lilas-900 underline'
                        message={t("LoginPasswordReset.reset-code-timer", { seconds })}
                    />
                )}
                {seconds === 0 && !loading && (
                    <div onClick={handleSendAnotherCode}>
                        <Typography
                            className='color-lilas-900 underline cursor-pointer'
                            message={t("LoginPasswordReset.reset-code")}
                        />
                    </div>
                )}
            </div>
        </>
    );
}
