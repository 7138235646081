import { ConnectedRouter } from "./ConnectedRouter";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { getAuthUser, getUseBaseRouter } from "src/modules/auth/selectors";
import { useIntercom } from "src/shared/hooks/useIntercom";
import { useAppSelector } from "src/store";

import { EmailCheckPage } from "./pages/EmailCheck";
import { LoginPage } from "./pages/Login";
import { PaymentPage } from "./pages/Payment";

export const BaseRouter = () => {
    const useBaseRouter = useAppSelector((state) => getUseBaseRouter(state));
    const location = useLocation();
    const authUser = useAppSelector((state) => getAuthUser(state));

    useIntercom();

    const isLoggedIn =
        !!authUser &&
        !useBaseRouter &&
        !location.pathname.includes("/email-validation/") &&
        !location.pathname.includes("/create-account/") &&
        !location.pathname.includes("/create-company/") &&
        !location.pathname.includes("/join/") &&
        !location.pathname.includes("/reset-password/") &&
        !location.pathname.includes("/add-company/") &&
        !location.pathname.includes("/payment/");

    return (
        <>
            {isLoggedIn ? (
                <ConnectedRouter />
            ) : (
                <Routes>
                    <Route path='/' element={<LoginPage />} />
                    <Route path='/payment/:reference' element={<PaymentPage />} />
                    <Route path='/email-validation/:token' element={<EmailCheckPage />} />
                    <Route path='/create-account/:token' element={<LoginPage />} />
                    <Route path='/reset-password/:token' element={<LoginPage />} />
                    <Route path='/join/:token' element={<LoginPage />} />
                    <Route path='/create-company/:token' element={<LoginPage />} />
                    <Route path='/add-company/:token' element={<LoginPage />} />
                    <Route path='*' element={<Navigate to='/' />} />
                </Routes>
            )}
        </>
    );
};
