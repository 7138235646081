import { LoginDisplays } from "../Panel";
import { CreateFormSteps } from "../Register/Panel";
import { RegisterPhone } from "../Register/Phone";
import { LoginSuccess } from "../Success";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RequestPostCreateUserFromInvitationProps } from "src/services/admin/operations";
import { sendCreateCompany, sendCreateCompanyAndUser } from "src/services/auth/operations";
import { companyCountryOptions } from "src/shared/const/countries";
import { sectorOptions } from "src/shared/const/sectors";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Input } from "src/shared/atoms/Inputs/Input";
import { InputPhoneValue } from "src/shared/atoms/Inputs/Phone/Phone";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";
import { SimpleSelect } from "src/shared/components/SimpleSelect/SimpleSelect";

const turnoverOptions: SelectOption[] = [
    {
        id: "0_50",
        label: "0 - 50 000 000 FCFA",
    },
    {
        id: "50_100",
        label: "50 000 000 - 100 000 000 FCFA",
    },
    {
        id: "100_200",
        label: "100 000 000 - 200 000 000 FCFA",
    },
    {
        id: "200_500",
        label: "200 000 000 - 500 000 000 FCFA",
    },
    {
        id: "500_1000",
        label: "500 000 000 - 1 000 000 000 FCFA",
    },
    {
        id: "1000_9999",
        label: "> 1 000 000 000 FCFA",
    },
];

const legalFieldOptions: SelectOption[] = [
    { label: "SA", id: "sa" },
    {
        label: "SAS",
        id: "sas",
    },
    {
        label: "SARL",
        id: "llc",
    },
    {
        label: "Société Civile",
        id: "property_management",
    },
    {
        label: "Société en commandité par action",
        id: "sca",
    },
    {
        label: "Société en commandité simple",
        id: "scs",
    },
    {
        label: "Société en nom collectif",
        id: "snc",
    },
    {
        label: "Groupement d'intérêts Economiques",
        id: "gde",
    },
    {
        label: "Société civile",
        id: "sc",
    },
    {
        label: "Coopérative/mutuelle",
        id: "coop",
    },
    {
        label: "Association / ONG",
        id: "ong",
    },
    {
        label: "Etat et organismes assimilés",
        id: "etat",
    },
    {
        label: "Ambassades / Consulat",
        id: "amb",
    },
    {
        label: "Institutions financières Internationales",
        id: "ifi",
    },
];

type Props = {
    phone?: InputPhoneValue;
    firstname?: string;
    lastname?: string;
    gender?: SelectOption | null;
    password?: string;
    dateOfBirth?: Date | null;
    handleSetPhone?: (value: InputPhoneValue) => void;
    handleSetFormToDisplay: (display: LoginDisplays) => () => void;
    setCreateFormToDisplay?: (display: CreateFormSteps) => void;
};

export function LoginCreateCompanyPanel({
    phone,
    dateOfBirth,
    firstname,
    lastname,
    gender,
    password,
    handleSetPhone,
    handleSetFormToDisplay,
    setCreateFormToDisplay,
}: Props) {
    const { t } = useTranslation();

    const { token = "" } = useParams<{ token: string }>();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [address, setAddress] = useState("");
    const [additionalInformations, setAdditionalInformations] = useState("");
    const [postCode, setPostCode] = useState("");
    const [shareCapital, setShareCapital] = useState("");
    const [city, setCity] = useState("");
    const [sector, setSector] = useState<SelectOption | null>(null);
    const [turnover, setTurnover] = useState<SelectOption | null>(null);
    const [legalForm, setLegalForm] = useState<SelectOption | null>(null);
    const [country, setCountry] = useState<SelectOption | null>(null);
    const [success, setSuccess] = useState(false);

    const [showPhoneValidation, setShowPhoneValidation] = useState(false);

    const location = useLocation();

    const isCreateCompany = location.pathname.includes("/create-company/");

    const handleChangeAddress = (value: string) => {
        setAddress(value);
    };

    const handleChangeAdditionalInformations = (value: string) => {
        setAdditionalInformations(value);
    };

    const handleChangePostCode = (value: string) => {
        setPostCode(value);
    };

    const handleChangeCity = (value: string) => {
        setCity(value);
    };

    const handleChangeShareCapital = (value: string) => {
        setShareCapital(value);
    };

    const handleChangeSector = (value: SelectOption) => {
        setSector(value.id === sector?.id ? null : value);
    };

    const handleChangeTurnover = (value: SelectOption) => {
        setTurnover(value.id === turnover?.id ? null : value);
    };

    const handleChangeLegalForm = (value: SelectOption) => {
        setLegalForm(value.id === legalForm?.id ? null : value);
    };

    const handleChangeCountry = (value: SelectOption) => {
        setCountry(value.id === country?.id ? null : value);
    };

    const handleSubmit = async (
        additionalData?: Partial<RequestPostCreateUserFromInvitationProps>
    ): Promise<boolean> => {
        if (
            address &&
            postCode &&
            firstname &&
            lastname &&
            password &&
            city &&
            gender &&
            dateOfBirth &&
            sector &&
            turnover &&
            legalForm &&
            country
        ) {
            setLoading(true);
            return await sendCreateCompanyAndUser({
                address: {
                    city,
                    postCode,
                    addressLine: address,
                },
                countryOfCitizenship: country.id,
                legalStatus: legalForm.id,
                firstname,
                lastname,
                gender: gender.id,
                password,
                dateOfBirth,
                revenue: turnover.id,
                sector: sector.id,
                shareCapital: parseInt(shareCapital),
                token,
                ...(additionalData ?? {}),
            })
                .then((res) => {
                    if (phone?.value && setCreateFormToDisplay) {
                        setCreateFormToDisplay("phoneVerification");
                    } else {
                        setSuccess(true);
                    }
                    return res;
                })
                .finally(() => setLoading(false));
        } else if (address && postCode && city && sector && turnover && legalForm && country) {
            setLoading(true);
            return await sendCreateCompany({
                address: {
                    city,
                    postCode,
                    addressLine: address,
                },
                countryOfCitizenship: country.id,
                legalStatus: legalForm.id,
                revenue: turnover.id,
                sector: sector.id,
                shareCapital: parseInt(shareCapital),
                token,
            })
                .then((res) => {
                    setSuccess(true);
                    return res;
                })
                .finally(() => setLoading(false));
        }
        return Promise.reject(false);
    };

    const handleRedirectOrSubmit = () => {
        if (isCreateCompany) {
            setShowPhoneValidation(true);
        } else {
            handleSubmit();
        }
    };

    const handleRedirect = () => {
        handleSetFormToDisplay("login")();
        navigate("/");
    };

    const disabled =
        address.trim().length === 0 ||
        postCode.trim().length === 0 ||
        city.trim().length === 0 ||
        shareCapital.trim().length === 0 ||
        !sector ||
        !turnover ||
        !legalForm ||
        !country ||
        loading;

    return success ? (
        <LoginSuccess
            title={"UserCreateDrawerContainer.create-account-success"}
            description={"UserCreateDrawerContainer.create-account-succes-description"}
            successAction={handleRedirect}
            successLabel={"LoginPasswordReset.go-back-to-login"}
        />
    ) : showPhoneValidation && setCreateFormToDisplay && phone && handleSetPhone ? (
        <RegisterPhone
            handleSetFormToDisplay={handleSetFormToDisplay}
            setCreateFormToDisplay={setCreateFormToDisplay}
            phone={phone}
            handleSetPhone={handleSetPhone}
            handleOnSubmit={handleSubmit}
        />
    ) : (
        <div className='d-flex flex-column gap-1'>
            <Typography variant={"h2"} message={t("Companies.your-company")} className='login-title-happy' />
            <Typography message={t("Companies.your-company-description")} className='color-neutral-500 fw-bold' />
            <SimpleSelect
                headerClassname='w-100 border-box'
                label={t<string>("Companies.sector")}
                placeholder={t<string>("Companies.sector-placeholder")}
                selected={sector}
                onSelectionChange={handleChangeSector}
                useRadio
                options={sectorOptions(t)}
            />
            <Input
                value={address}
                onChange={handleChangeAddress}
                className='w-100'
                label={t<string>("Companies.adress")}
                placeholder={t("Companies.adress-placeholder")}
            />
            <Input
                value={additionalInformations}
                onChange={handleChangeAdditionalInformations}
                className='w-100'
                label={t<string>("Companies.adress-additional-informations")}
                placeholder={t("Companies.adress-additional-informations-placeholder")}
            />
            <div className='d-flex flex-row gap-2'>
                <Input
                    value={postCode}
                    onChange={handleChangePostCode}
                    className='w-100'
                    label={t<string>("Companies.postal-code")}
                    placeholder={t("Companies.postal-code")}
                    type='number'
                />
                <Input
                    value={city}
                    onChange={handleChangeCity}
                    className='w-100'
                    label={t<string>("Companies.city")}
                    placeholder={t("Companies.city")}
                />
            </div>
            <SimpleSelect
                headerClassname='w-100 border-box'
                label={t<string>("CommonUse.country")}
                placeholder={t<string>("CommonUse.country")}
                selected={country}
                onSelectionChange={handleChangeCountry}
                useRadio
                options={companyCountryOptions}
            />
            <div className='d-flex flex-row gap-2'>
                <div className='w-100'>
                    <SimpleSelect
                        headerClassname='flex-1 w-100 border-box'
                        label={t<string>("Companies.legal-form")}
                        placeholder={t<string>("Companies.legal-form")}
                        selected={legalForm}
                        onSelectionChange={handleChangeLegalForm}
                        useRadio
                        options={legalFieldOptions}
                    />
                </div>
                <Input
                    value={shareCapital}
                    onChange={handleChangeShareCapital}
                    className='w-100'
                    label={t<string>("Capital social")}
                    placeholder={formatMoneyToString({ amount: 0, zeroValue: true })}
                    type='number'
                />
            </div>
            <SimpleSelect
                headerClassname='w-100 border-box'
                label={t<string>("Companies.turnover")}
                placeholder={t<string>("Companies.turnover-placeholder")}
                selected={turnover}
                onSelectionChange={handleChangeTurnover}
                useRadio
                options={turnoverOptions}
            />
            <Button
                className='w-100 mt-2'
                label={t("CommonUse.continue")}
                disabled={disabled}
                onClick={handleRedirectOrSubmit}
            />
        </div>
    );
}
