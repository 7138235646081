import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { CompanyRoleLabel } from "src/shared/models/UserCompany";

import { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUser, getAuthUserLoading, getAuthUserPreferenceSelectedCompany } from "src/modules/auth/selectors";
import { logout } from "src/modules/auth/thunkActions";
import { getAuthCompanies } from "src/modules/authCompanies/selectors";
import { useAppDispatch, useAppSelector } from "src/store";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Typography } from "src/shared/atoms/Typography/Typography";

export function NavbarMenu() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const selectRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();
    const location = useLocation();

    const authUser = useAppSelector((state) => getAuthUser(state));
    const companyName = useAppSelector((state) => getAuthUserPreferenceSelectedCompany(state)?.name ?? "");
    const companies = useAppSelector((state) => getAuthCompanies(state));
    const hasOneCompany = companies.length === 1 && companies[0].hasAccess && !!companies[0].role;

    const isCompanyPage = useMatch("/companies");

    const avatar = authUser?.avatar;
    const firstname = authUser?.firstname ?? "";
    const lastname = authUser?.lastname ?? "";
    const userName = `${firstname} ${lastname}`;

    const dispatch = useAppDispatch();
    const authUserLoading = useAppSelector(getAuthUserLoading);

    const handleLogout = () => {
        dispatch(logout());
    };

    const handleSwitchOpen = () => {
        setOpen((oldOpen) => !oldOpen);
    };

    const handleRedirectToCompanies = () => {
        navigate("/companies", { state: { noRedirection: true } });
        setOpen(false);
    };

    const handleRedirectToProfile = () => {
        navigate("/settings");
        setOpen(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
            setOpen(false);
        }
    };

    const handleOpenSupportPage = () => {
        window.open("http://helpcenter.julaya.co/", "_blank", "noopener");
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    const menuButton = ({
        onClick,
        message,
        iconName,
        active = false,
        disabled = false,
        color = "",
        className = "",
        testId,
    }: {
        onClick: () => void;
        message: string;
        iconName: IconListType;
        active?: boolean;
        color?: string;
        disabled?: boolean;
        className?: string;
        testId?: string;
    }) => {
        return (
            <div
                className={`navbar-button-item ${active ? "active" : ""} ${
                    disabled ? "disabled" : ""
                } ${color} ${className}`}
                data-testId={testId}
                onClick={disabled ? undefined : onClick}
            >
                <Icon name={iconName} />
                <Typography message={t(message)} className='fw-bold' />
            </div>
        );
    };

    return (
        <div className='navigation-menu-container' ref={selectRef}>
            <div className='navigation-menu-button' onClick={handleSwitchOpen} data-testid='navigation-menu-button'>
                <div className='navbar-user-container'>
                    <Avatar avatar={avatar || undefined} firstname={firstname} lastname={lastname} />
                    <div className='navbar-user-text'>
                        <Typography message={userName} className='fw-bold' />
                        {!isCompanyPage && <Typography message={companyName} />}
                    </div>
                </div>
                <div className='chevron-container'>
                    <Icon name='chevron-down' className={`chevron-down-open ${open ? "show" : ""}`} size='sm' />
                </div>
            </div>

            <div className={`navigation-menu ${open ? "show fadeInUp" : "hide"}`}>
                <div className='content-header'>
                    {authUser?.role && (
                        <div className='user-role-header'>
                            <Typography
                                className='color-primary fw-bold'
                                message={t(CompanyRoleLabel[authUser.role])}
                            />
                        </div>
                    )}
                    <div className='navbar-user-container ml-2 mt-2'>
                        <Avatar avatar={avatar || undefined} firstname={firstname} lastname={lastname} />
                        <div className='navbar-user-text'>
                            <Typography className='fw-bold' message={userName} />
                            {!isCompanyPage && <Typography message={companyName} />}
                        </div>
                    </div>
                </div>
                <div className='content-menu'>
                    <div className='menu-divider' />
                    {!isCompanyPage && companies.length ? (
                        <>
                            {!hasOneCompany &&
                                menuButton({
                                    onClick: handleRedirectToCompanies,
                                    message: "Companies.my-companies",
                                    iconName: "briefcase",
                                })}
                            {menuButton({
                                onClick: handleRedirectToProfile,
                                message: "CommonUse.account-settings",
                                iconName: "gear",
                                testId: "redirect-profile-button",
                                active: location.pathname === "/settings",
                            })}
                            <div className='menu-divider' />
                        </>
                    ) : null}
                    {menuButton({
                        onClick: handleOpenSupportPage,
                        message: "CommonUse.support",
                        iconName: "question-mark-circle",
                    })}
                    <div className='menu-divider' />
                    {menuButton({
                        disabled: authUserLoading,
                        onClick: handleLogout,
                        message: "CommonUse.disconnect",
                        iconName: "arrow-left-on-rectangle",
                        color: "color-error",
                    })}
                </div>
            </div>
        </div>
    );
}
