import { CreateTaskApiPayload, createTaskItems } from "src/services/taskItems/operations";
import { TaskListApiReturnItem } from "src/services/tasks/types";
import { CreateTaskItemContext } from "src/shared/context/createTaskItem";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useContext } from "react";

import { useTranslation } from "react-i18next";

import { fetchTaskToEdit } from "src/modules/tasks/thunkActions";
import { getTaskItemsFileUploading } from "src/modules/tasksItems/selectors";
import { fetchTaskItems } from "src/modules/tasksItems/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Skeleton } from "src/shared/atoms/Skeleton/Skeleton";
import { Typography } from "src/shared/atoms/Typography/Typography";

const skeletonArray = Array.from(Array(5).keys());

type Props = {
    task: TaskListApiReturnItem | undefined;
};

export function TasksEditRecap({ task }: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { taskItems, setTaskItems, setLoading } = useContext(CreateTaskItemContext);

    const taskItemsFileUploading = useAppSelector((state) => getTaskItemsFileUploading(state, { taskId: task?.id }));

    const fileUploading = Object.keys(taskItemsFileUploading ?? {}).length !== 0;

    const metadata = task?.metadata;

    const totalWithFees = parseInt(metadata?.sum ?? "0") + (metadata?.fees ?? 0);
    const wallet = task?.Wallet;

    const newBalance = (wallet?.balance ?? 0) - totalWithFees;
    const updatesToDo =
        Object.keys(taskItems ?? {}).length &&
        Object.values(taskItems ?? {}).every((item) => item.amount && item.externalReference);

    const handleCreateTasks = async () => {
        const taskId = task?.id;

        if (taskId && taskItems) {
            setLoading(true);
            await createTaskItems({
                taskId,
                tasksItems: Object.values(taskItems).map(
                    ({ externalFullname, externalReference = "", amount, serviceSlug, typeSlug, comment }) => ({
                        externalReference,
                        externalFullname,
                        serviceSlug,
                        amount: String(amount),
                        typeSlug,
                        comment,
                    })
                ) as CreateTaskApiPayload[],
            }).then(() => dispatch(fetchTaskToEdit({ taskId })));
            setTaskItems(undefined);
            setLoading(false);
            dispatch(
                fetchTaskItems({
                    taskId,
                })
            );
        }
    };

    return (
        <div className='edit-task-recap-container'>
            <div className='d-flex gap-2'>
                <Icon name='task' variant='squared' color='gradiant-lilas' />
                <div className='d-flex flex-column'>
                    <Typography className='fw-bold' message={t("SignatureTaskSummaryRecap.recapitulatif")} />
                    <Typography message={task?.title ?? "-"} />
                </div>
            </div>
            <div className='full-divider' />
            {fileUploading ? (
                skeletonArray.map((value) => <Skeleton variant='lg' key={`skeleton-${value}`} />)
            ) : (
                <>
                    <div className='d-flex justify-content-between'>
                        <Typography className='fw-bold color-neutral-500' message={t("CommonUse.description")} />
                        <Typography className='fw-bold' message={task?.description ?? "-"} />
                    </div>
                    <div className='d-flex justify-content-between'>
                        <Typography className='fw-bold color-neutral-500' message={t("Transactions.operation-on")} />
                        <Typography className='fw-bold' message={task?.Wallet?.label ?? "-"} />
                    </div>
                    <div className='d-flex justify-content-between'>
                        <Typography className='fw-bold color-neutral-500' message={t("Tasks.recipients")} />
                        <Typography className='fw-bold' message={task?.metadata?.total ?? "-"} />
                    </div>
                    <div className='d-flex justify-content-between'>
                        <Typography className='fw-bold color-neutral-500' message={t("CommonUse.amount")} />
                        <Typography
                            className='fw-bold'
                            message={formatMoneyToString({ amount: task?.metadata?.sum })}
                        />
                    </div>
                    <div className='d-flex justify-content-between'>
                        <Typography className='fw-bold color-neutral-500' message={t("Transactions.fees")} />
                        <Typography
                            className='fw-bold color-error-500'
                            message={formatMoneyToString({ amount: task?.metadata?.fees })}
                        />
                    </div>
                    <div className='d-flex justify-content-between'>
                        <Typography className='fw-bold color-neutral-500' message={t("Tasks.operator-fee")} />
                        <Typography
                            className='fw-bold color-error-500'
                            message={formatMoneyToString({ amount: task?.metadata?.opFees })}
                        />
                    </div>
                    <div className='full-divider' />
                    <div className='d-flex justify-content-between'>
                        <Typography className='fw-bold color-neutral-500' message={t("Tasks.total-amount")} />
                        <Typography className='fw-bold' message={formatMoneyToString({ amount: totalWithFees })} />
                    </div>
                    <div className='full-divider' />
                    <div className='d-flex justify-content-between'>
                        <Typography className='fw-bold color-neutral-500' message={t("Tasks.new-balance")} />
                        <Typography
                            className='fw-bold color-lilas-900'
                            message={formatMoneyToString({ amount: task?.metadata?.total ? newBalance : 0 })}
                        />
                    </div>
                    <div className='full-divider' />
                    <Button
                        className='w-100'
                        onClick={handleCreateTasks}
                        label={t("Transactions.check-transactions")}
                        disabled={!updatesToDo}
                    />
                </>
            )}
        </div>
    );
}
