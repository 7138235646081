import { ColumnItems } from "../../const";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { RegularTemplateFormData } from "src/shared/models/Export";

import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Select } from "src/shared/atoms/Select/Select";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

import "./styles.scss";

type ColumnSelectionProps = {
    handleColumnDataChange: (columnData: string[]) => void;
    template?: RegularTemplateFormData;
};

export const ColumnSelection = ({ handleColumnDataChange, template }: ColumnSelectionProps) => {
    const { t } = useTranslation();
    const columnItems = useMemo(() => ColumnItems(t), [t]);

    const [showBody, setShowBody] = useState(false);
    const [availableColumnItems, setAvailableColumnItems] = useState<SelectOption[]>(columnItems);
    const [selectedColumnItems, setSelectedColumnItems] = useState<SelectOption[]>([]);
    const [initialSelectedItems, setInitialSelectedItems] = useState<SelectOption[]>([]);

    useEffect(() => {
        setInitialSelectedItems(selectedColumnItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template, columnItems]);

    useEffect(() => {
        if (template?.columns) {
            if (template.columns.includes("all")) {
                setSelectedColumnItems(columnItems);
                setAvailableColumnItems([]);
            } else {
                const selectedItems = template.columns
                    .map((columnId) => columnItems.find((item) => item.id === columnId))
                    .filter((item): item is SelectOption => item !== undefined);

                setSelectedColumnItems(selectedItems);

                const availableItems = columnItems.filter((item) => !template?.columns?.includes(item.id));
                setAvailableColumnItems(availableItems);
            }
        } else {
            setSelectedColumnItems(columnItems);
            setAvailableColumnItems([]);
        }
    }, [template, columnItems]);

    const getFormData = () => {
        return selectedColumnItems.map((item) => item.id);
    };

    useEffect(() => {
        handleColumnDataChange(getFormData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedColumnItems]);

    const onDragEnd = (result: { source: any; destination: any }) => {
        const { source, destination } = result;

        if (!destination || source.index === destination.index) {
            return;
        }

        const newSelectedColumnItems = Array.from(selectedColumnItems);
        const [removed] = newSelectedColumnItems.splice(source.index, 1);
        newSelectedColumnItems.splice(destination.index, 0, removed);

        setSelectedColumnItems(newSelectedColumnItems);
    };

    const handleSetShowBody = () => {
        setShowBody(!showBody);
        setInitialSelectedItems(selectedColumnItems);
    };

    const handleAddColumn = (item: SelectOption) => () => {
        setSelectedColumnItems([...selectedColumnItems, item]);
        setAvailableColumnItems(availableColumnItems.filter((availableItem) => availableItem.id !== item.id));
    };

    const handleRemoveColumn = (columnItem: SelectOption) => () => {
        setSelectedColumnItems(selectedColumnItems.filter((item) => item.id !== columnItem.id));
        setAvailableColumnItems([...availableColumnItems, columnItem]);
    };

    const handleCancel = () => {
        setSelectedColumnItems(initialSelectedItems);
        setAvailableColumnItems(columnItems.filter((item) => !initialSelectedItems.map((i) => i.id).includes(item.id)));
        setShowBody(false);
    };

    return (
        <div className='export-form-column-selection'>
            <div className='label-container'>
                <Typography message={t("Export.export-file-columns")} className='fw-bold title' />
                <Typography message={" *"} className='fw-bold color-error' />
            </div>
            <div className={`export-form-column-selection-header ${showBody ? "show" : ""}`}>
                <Typography
                    className='color-lilas fw-bold'
                    message={`${selectedColumnItems.length} ${t("Export.selected-columns")}`}
                />
                {!showBody ? (
                    <Button
                        label={!showBody ? t("CommonUse.modify") : t("CommonUse.validate")}
                        onClick={handleSetShowBody}
                    />
                ) : (
                    <Typography message={t("Export.edition-inprogress")} className='fw-bold color-warning' />
                )}
            </div>
            {showBody && (
                <div className='export-form-column-selection-body'>
                    <div className='export-colomn-item-container'>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId='droppable'>
                                {(provided) => (
                                    <div
                                        className='export-colomn-item-container'
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {selectedColumnItems.map((item, index) => (
                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className='export-form-column-selection-items'
                                                    >
                                                        <div className='index-ellipsis'>
                                                            <div className='ellipsis-gap'>
                                                                <Icon name={"ellipsis-vertical"} />
                                                                <Icon name={"ellipsis-vertical"} />
                                                            </div>
                                                            <Typography
                                                                className='color-lilas fw-bold'
                                                                message={index < 9 ? "0" + (index + 1) : index + 1}
                                                            />
                                                            <Typography message={item.label} className='fw-bold' />
                                                        </div>
                                                        <div
                                                            onClick={handleRemoveColumn(item)}
                                                            className='cursor-pointer'
                                                        >
                                                            <Avatar
                                                                icon='thrash'
                                                                color='neutral'
                                                                backgroundColor='neutral-100'
                                                                size='sm'
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                    {availableColumnItems.length > 0 && (
                        <>
                            <div className='divider' />
                            <Select
                                content={{
                                    header: {
                                        title: t<string>("Export.add-columns"),
                                        dropdownIcon: "plus",
                                    },
                                    dropDownContent: {
                                        body: (
                                            <div className='select-export-form-column-selection'>
                                                {availableColumnItems.map((item) => (
                                                    <div
                                                        key={item.id}
                                                        onClick={handleAddColumn(item)}
                                                        className='select-export-form-column-selection-item'
                                                    >
                                                        <Typography message={item.label} className='fw-bold' />
                                                        <Avatar
                                                            icon='plus'
                                                            backgroundColor='neutral-900'
                                                            color='white'
                                                            size='sm'
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        ),
                                    },
                                }}
                            />
                        </>
                    )}
                    <div className='divider' />
                    <div className='button-container'>
                        <Button label={t("CommonUse.cancel")} variant='secondary' onClick={handleCancel} />
                        <Button label={t("CommonUse.apply")} onClick={handleSetShowBody} />
                    </div>
                </div>
            )}
        </div>
    );
};
