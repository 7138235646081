import { EditTaskInputCell } from "../InputCell";
import classNames from "classnames";
import { CreateTaskItemContext } from "src/shared/context/createTaskItem";

import { useContext } from "react";

import { useTranslation } from "react-i18next";

import { Typography } from "src/shared/atoms/Typography/Typography";

type Props = {
    uuid: string;
};

export function TasksItemEditFieldCreateComment({ uuid }: Props) {
    const { t } = useTranslation();

    const { taskItems, loading, setTaskItems } = useContext(CreateTaskItemContext);

    const taskItem = taskItems?.[uuid];

    const handleUpdateComment = (newComment: string) => {
        if (taskItem && newComment !== taskItem?.comment) {
            setTaskItems({ ...taskItems, [uuid]: { ...taskItem, comment: newComment } });
        }
    };

    const commentTrimmed = taskItem?.comment?.trim();

    return (
        <EditTaskInputCell
            disabled={loading}
            initialValue={taskItem?.comment ?? ""}
            onValidate={handleUpdateComment}
            externalReference={{ isExternalReference: true, isBankWire: taskItem?.typeSlug === "bank-transfer" }}
        >
            <Typography
                className={classNames("edit-task-table-cell-content", {
                    "color-neutral-400": commentTrimmed === "" || !commentTrimmed,
                })}
                message={commentTrimmed === "" || !commentTrimmed ? t("Tasks.comment-placeholder") : taskItem?.comment}
            />
        </EditTaskInputCell>
    );
}
