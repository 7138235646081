import classNames from "classnames";

import { ReactNode, useEffect, useRef, useState } from "react";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";

const determineTooltipPosition = (ref: React.RefObject<HTMLElement>): "bottom" | "top" | "left" | "right" => {
    if (!ref.current) return "bottom";

    const rect = ref.current.getBoundingClientRect();
    const { innerHeight, innerWidth } = window;

    const spaceTop = rect.top;
    const spaceBottom = innerHeight - rect.bottom;
    const spaceLeft = rect.left;
    const spaceRight = innerWidth - rect.right;

    const maxSpace = Math.max(spaceTop, spaceBottom, spaceLeft, spaceRight);

    if (maxSpace === spaceTop) return "top";
    if (maxSpace === spaceBottom) return "bottom";
    if (maxSpace === spaceLeft) return "left";
    return "right";
};

type TableCellContentProps = {
    content: ReactNode;
    type: string;
    status?: string;
    showIcon?: boolean;
    icon?: IconListType;
    alertIcon?: IconListType;
    toolTip?: { title: string; subtitle?: string };
    onClick?: () => void;
};

const useIsOverflowing = (ref: React.RefObject<HTMLElement>): boolean => {
    const [isOverflowing, setIsOverflowing] = useState(false);

    useEffect(() => {
        const checkOverflow = () => {
            if (ref.current) {
                setIsOverflowing(ref.current.scrollWidth > ref.current.clientWidth);
            }
        };

        checkOverflow();
        window.addEventListener("resize", checkOverflow);

        return () => {
            window.removeEventListener("resize", checkOverflow);
        };
    }, [ref]);

    return isOverflowing;
};

export const TableCellContent = ({
    content,
    type,
    showIcon = true,
    status,
    icon,
    alertIcon,
    toolTip,
    onClick,
}: TableCellContentProps) => {
    const cellRef = useRef<HTMLDivElement>(null);
    const isOverflowing = useIsOverflowing(cellRef);
    const [tooltipPosition, setTooltipPosition] = useState<"bottom" | "top" | "left" | "right">("right");

    useEffect(() => {
        setTooltipPosition(determineTooltipPosition(cellRef));
    }, []);

    const renderStatusIcon = (status?: string): IconListType => {
        switch (status) {
            case "success":
                return "check-circle-filled";
            case "warning":
                return "information-circle-filled";
            case "error":
                return "x-circle-filled";
            default: {
                return "placeholder";
            }
        }
    };

    return (
        <div className={classNames("td-content-container", { "on-click-cell": onClick })} onClick={onClick}>
            {toolTip ? (
                <ToolTip
                    tooltipClassname='selectable-table-status-tooltip'
                    content={
                        <Alert
                            icon={alertIcon ?? icon ?? renderStatusIcon(status)}
                            message={toolTip.title}
                            subMessage={toolTip.subtitle}
                            color={status as "success" | "warning" | "error"}
                        />
                    }
                    position={tooltipPosition}
                    activation={true}
                >
                    <div className={classNames("cell-row-item", type)} ref={cellRef}>
                        {content}
                    </div>
                    {status && <Icon name={icon ?? renderStatusIcon(status)} color={status} />}
                </ToolTip>
            ) : isOverflowing ? (
                <ToolTip position={tooltipPosition} content={content} activation={true}>
                    <div className={classNames(type, "cell-row-item")} ref={cellRef}>
                        {content}
                    </div>
                    {status && <Icon name={icon ?? renderStatusIcon(status)} color={status} />}
                </ToolTip>
            ) : (
                <>
                    <div className={classNames(type, "cell-row-item")} ref={cellRef}>
                        {content}
                    </div>
                    {status && showIcon && <Icon name={icon ?? renderStatusIcon(status)} color={status} />}
                </>
            )}
        </div>
    );
};
