import { NavbarMenu } from "./Menu";
import { NavLinksType, navLinks } from "./const";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import { CompanyKybLevel, CompanyRole } from "src/shared/models/UserCompany";

import { Fragment, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import {
    getAuthUser,
    getAuthUserPreferenceSelectedCompanyId,
    getSelectedCompanyCountryTag,
} from "src/modules/auth/selectors";
import { getActiveLoan, getLoanOffers } from "src/modules/loans/selectors";
import { useIsUserAuthorized } from "src/shared/hooks/useIsUserAuthorized";
import { useAppSelector } from "src/store";

import { Icon } from "src/shared/atoms/Icons/Icon";
import { Tag } from "src/shared/atoms/Tag/Tag";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type NavBarProps = {
    preSelectedOpen?: number;
};

export const NavBar = ({ preSelectedOpen }: NavBarProps) => {
    const selectedCompany = useAppSelector((state) => getAuthUserPreferenceSelectedCompanyId(state));
    const location = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showItem, setShowItem] = useState<number | null>(preSelectedOpen || null);
    const isCompanyPage = useMatch("/companies");
    const companyCountryTag = useAppSelector((state) => getSelectedCompanyCountryTag(state));

    const authUser = useAppSelector((state) => getAuthUser(state));
    const loanOffers = useAppSelector((state) => getLoanOffers(state));
    const activeLoan = useAppSelector((state) => getActiveLoan(state));

    useEffect(() => {
        const path = location.pathname;

        navLinks.forEach((navLink, index) => {
            const subLinkIndex = navLink.subLinks.findIndex((subLink) => {
                return subLink.link ? path.includes(subLink.link) : false;
            });
            if (subLinkIndex !== -1) {
                setShowItem(index);
            }
        });

        if (path.includes("/settings")) {
            setShowItem(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const authorizations = {
        [CompanyRole.ADMIN]: useIsUserAuthorized({ allowedRoles: [CompanyRole.ADMIN] }),
        [CompanyRole.ADMIN_ONLY]: useIsUserAuthorized({ allowedRoles: [CompanyRole.ADMIN_ONLY] }),
        [CompanyRole.USER]: useIsUserAuthorized({ allowedRoles: [CompanyRole.USER] }),
        [CompanyRole.READ]: useIsUserAuthorized({ allowedRoles: [CompanyRole.READ] }),
        [CompanyRole.COLLECTOR]: useIsUserAuthorized({ allowedRoles: [CompanyRole.COLLECTOR] }),
    };

    const isCurrentLink = (currentLink: string) => {
        if (currentLink === "/tasks" && location.pathname.includes("/history") && location.pathname.includes("/task")) {
            return true;
        }

        if (currentLink === "/billing/pay-bill" && location.pathname.includes("/billing/pay-bill-list")) {
            return true;
        }

        if (
            currentLink === "/dashboard" &&
            location.pathname.includes("/history") &&
            !location.pathname.includes("/task")
        ) {
            return true;
        }

        if (currentLink === "/tasks" && location.pathname.includes("/tasks")) {
            return true;
        }

        if (currentLink === "/admin/wallets" && location.pathname.includes("/admin/wallets")) {
            return true;
        }

        if (currentLink === "/loan/my-loans" && location.pathname.includes("transfert/loan")) {
            return true;
        }

        return location.pathname === currentLink;
    };

    const handleShowItem = (index: number) => {
        if (showItem !== index) {
            setShowItem(index);
            const firstSubLink = navLinks[index].subLinks.find((sublink) => {
                return sublink.roles.some((role) => authorizations[role]);
            });

            if (firstSubLink && firstSubLink.link) {
                navigate(firstSubLink.link);
            }
        }
    };

    const checkIsDisplayed = (accordion: NavLinksType) => {
        let isDisplayed = accordion.roles.some((role) => authorizations[role]);

        if (accordion.disallowedCountries) {
            isDisplayed =
                isDisplayed && companyCountryTag !== null && !accordion.disallowedCountries.includes(companyCountryTag);
        }

        if (accordion.name === "Loans.loans" && loanOffers.length === 0 && activeLoan === undefined) {
            isDisplayed = false;
        }

        return isDisplayed;
    };

    return (
        <div className='navbar-new'>
            <NavbarMenu />
            <div className='accordion-container'>
                {!isCompanyPage &&
                    selectedCompany &&
                    navLinks.map((accordion, index) => {
                        const isDisplayed = checkIsDisplayed(accordion);
                        return isDisplayed ? (
                            <div className='accordion' key={index}>
                                <div
                                    className={`header ${showItem === index ? "show" : "hide"} `}
                                    onClick={() => handleShowItem(index)}
                                    data-testid={"accordion-header-" + index}
                                >
                                    <div className='header-content'>
                                        <Icon name={accordion.icon} />
                                        <Typography className='accordion-name' message={t(accordion.name)} />
                                        {accordion.badge && (
                                            <div className='accordion-badge'>
                                                <Typography size='xs' message={t(accordion.badge)} />
                                            </div>
                                        )}
                                        {authUser &&
                                            authUser.Company?.kybRole === CompanyKybLevel.USER &&
                                            accordion.name === "CommonUse.administration" && (
                                                <Tag size='sm' color='warning' type='filled' icon='document' />
                                            )}
                                    </div>
                                    <Icon className={showItem === index ? "show" : ""} name={"chevron-down"} />
                                </div>
                                <div className={`content ${showItem === index ? "show" : "hide"}`}>
                                    <div className='content-padding'>
                                        <div className='list-container'>
                                            {accordion.subLinks.map(({ icon, name, component, roles, link }, index) => {
                                                const isDisplayed = roles.some((role) => authorizations[role]);

                                                return isDisplayed ? (
                                                    link ? (
                                                        <Link to={link} key={`${name} ${index}`}>
                                                            <div
                                                                className={`menu-button ${
                                                                    isCurrentLink(link) ? "active" : ""
                                                                }`}
                                                            >
                                                                <Icon name={icon} color={"black"} />
                                                                <Typography
                                                                    className='fw-bold color-black'
                                                                    size={"xs"}
                                                                    variant={"span"}
                                                                    message={t(name)}
                                                                />
                                                            </div>
                                                        </Link>
                                                    ) : component ? (
                                                        <Fragment key={name}>{component}</Fragment>
                                                    ) : null
                                                ) : null;
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null;
                    })}
            </div>
        </div>
    );
};
