import { CollectHelper } from "./Helper";
import { CollectSuccessModal } from "./SuccessModal";
import { HandleSubmitPropsPasswordValidation, PasswordValidationModal } from "src/shared/common/Password/Modal";
import { companyCollectorPartners } from "src/shared/const/partner";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAdminWalletsWithReferenceAsSelectOptions } from "src/modules/admin/adminWallets/selectors";
import { fetchAdminWallets } from "src/modules/admin/adminWallets/thunkActions";
import { getAuthUser, getSelectedCompanyCountryTag } from "src/modules/auth/selectors";
import {
    createOrUpdateAuthUserCollectorSettings,
    retrieveAuthCompanyInformations,
} from "src/modules/auth/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { InputPhone, InputPhoneValue } from "src/shared/atoms/Inputs/Phone/Phone";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { SelectMultipleOptions, SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";
import { SimpleSelect } from "src/shared/components/SimpleSelect/SimpleSelect";

import "./styles.scss";

export function CollectPanel() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [edit, setEdit] = useState(false);
    const [open, setOpen] = useState(false);
    const [phone, setPhone] = useState<InputPhoneValue>();
    const [selectedWallet, setSelectedWallet] = useState<SelectOption>();
    const [selectedPartners, setSelectedPartners] = useState<SelectOption[]>();

    const authUser = useAppSelector((state) => getAuthUser(state));
    const walletOptions = useAppSelector((state) => getAdminWalletsWithReferenceAsSelectOptions(state));
    const companyCountryTag = useAppSelector((state) => getSelectedCompanyCountryTag(state));

    const partnersAvailable = companyCollectorPartners?.[companyCountryTag ?? "CIV"];
    const collectorInfo = authUser?.collectorInfo;
    const creation = !collectorInfo?.phone && !collectorInfo?.wallet;

    const disabled = !phone?.prefix || !phone.value || !selectedWallet;

    useEffect(() => {
        dispatch(
            fetchAdminWallets({
                page: -1,
                archived: false,
            })
        );
    }, [dispatch]);

    useEffect(() => {
        dispatch(retrieveAuthCompanyInformations());
    }, [dispatch]);

    const handleSwitchEdit = () => {
        setEdit((value) => !value);
    };

    const handleCancel = () => {
        setEdit(false);
        setSelectedWallet(undefined);
    };

    const handleSwitchOpen = () => {
        setOpen((value) => !value);
    };

    const handleSelectWallet = (wallet: SelectOption) => {
        setSelectedWallet(wallet);
    };

    const handleChangePhone = (newPhone: InputPhoneValue) => {
        setPhone(newPhone);
    };

    const handleChangePartnerSelection = (partners: SelectOption[]) => {
        setSelectedPartners(partners);
    };

    const handleUpdate = async ({ password, twoFactor }: HandleSubmitPropsPasswordValidation) => {
        if (phone?.prefix && selectedWallet && phone.value && selectedWallet) {
            return await dispatch(
                createOrUpdateAuthUserCollectorSettings({
                    countryCode: phone.prefix ?? "",
                    phone: creation ? phone.value : collectorInfo?.phone ?? "",
                    walletId: parseInt(selectedWallet.id),
                    create: creation,
                    password,
                    services: selectedPartners?.map((partner) => partner.id) ?? [],
                    ...(twoFactor ? { otp: twoFactor } : {}),
                })
            )
                .unwrap()
                .then(() => {
                    setEdit(false);
                });
        }
        return;
    };

    useEffect(() => {
        if (edit) {
            const selectedWallet = walletOptions.find(
                (wallet) => wallet.id === String(authUser?.collectorInfo?.wallet?.id)
            );
            setPhone({
                prefix: collectorInfo?.countryCode ?? authUser?.countryCode,
                value: collectorInfo?.phone ?? authUser?.phone,
            });
            setSelectedWallet(selectedWallet);
            const services = partnersAvailable.filter((service) => collectorInfo?.services?.includes(service.id));
            setSelectedPartners(services);
        }
    }, [edit]);

    return (
        <>
            <div className='collect-container'>
                <div className='collect-settings-container'>
                    <div className='collect-settings-title'>
                        <div className='d-flex align-items-center'>
                            <Avatar icon='get-paid' />
                            <Typography className='ml-2 fw-bold' message={t("TopUp.collector-settings")} />
                        </div>
                        <Button
                            onClick={handleSwitchEdit}
                            color={edit ? "warning" : "lilas"}
                            variant='ghost'
                            label={t(edit ? "CommonUse.being-modified" : "TopUp.edit")}
                        />
                    </div>
                    <div className='collect-divider' />
                    {edit ? (
                        <div className='collect-settings-edit'>
                            <InputPhone
                                label={t<string>("CommonUse.phone-number")}
                                placeholder={t("Users.enter-phone-number")}
                                onChange={handleChangePhone}
                                disablePhonePrefix
                                disabled={!creation}
                                prefix={phone?.prefix ?? collectorInfo?.countryCode ?? authUser?.countryCode}
                                value={phone?.value ?? collectorInfo?.phone ?? authUser?.phone}
                            />
                            {creation && (
                                <Alert
                                    color='warning'
                                    icon='exclamation-triangle'
                                    message={t("TopUp.create-collector-phone-alert")}
                                />
                            )}
                            <SelectMultipleOptions
                                color='grey'
                                label={t<string>("Users.choose-partner")}
                                onSelectionChange={handleChangePartnerSelection}
                                showSelectedItems={true}
                                selected={selectedPartners}
                                items={partnersAvailable}
                                name={t<string>("Users.choose-partner-placeholder")}
                            />
                            <SimpleSelect
                                headerClassname='collect-select'
                                useRadio
                                scrollable
                                placeholder={t<string>("CommonUse.select-account")}
                                label={t<string>("TopUp.mobile-money-wallet-deposit")}
                                options={walletOptions}
                                selected={selectedWallet}
                                onSelectionChange={handleSelectWallet}
                            />
                            <div className='collect-divider' />

                            <div className='collect-edit-button'>
                                <Button onClick={handleCancel} variant='tertiary' label={t("CommonUse.cancel")} />
                                <Button
                                    disabled={disabled}
                                    onClick={handleSwitchOpen}
                                    label={t("CommonUse.validate")}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='collect-settings-info-container'>
                            <div className='d-flex'>
                                <div className='collect-settings-text'>
                                    <Typography
                                        className='fw-bold color-neutral-500'
                                        message={t("CommonUse.phone-number")}
                                    />
                                </div>
                                <Typography message={collectorInfo?.phone ?? "-"} />
                            </div>
                            <div className='d-flex'>
                                <div className='collect-settings-text'>
                                    <Typography
                                        className='fw-bold color-neutral-500'
                                        message={t("TopUp.deposit-wallet")}
                                    />
                                </div>
                                <Typography
                                    message={
                                        collectorInfo?.wallet
                                            ? `${collectorInfo.wallet.label} - ${collectorInfo.wallet.reference}`
                                            : "-"
                                    }
                                />
                            </div>
                            {!creation ? (
                                <Alert
                                    classname='mt-2'
                                    icon='check-circle'
                                    color='success'
                                    message={t("TopUp.account-ready-for-collect-title")}
                                    subMessage={t<string>("TopUp.account-ready-for-collect-description")}
                                />
                            ) : (
                                <Alert
                                    classname='mt-2'
                                    icon='exclamation-triangle'
                                    color='warning'
                                    message={t("TopUp.not-eligible-collect")}
                                    subMessage={t<string>("TopUp.not-eligible-collect-description")}
                                />
                            )}
                        </div>
                    )}
                </div>
                <CollectHelper />
            </div>
            <PasswordValidationModal
                successComponent={<CollectSuccessModal />}
                handleOnSubmit={handleUpdate}
                open={open}
                setOpen={handleSwitchOpen}
            />
        </>
    );
}
