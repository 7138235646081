import { UserCreateDrawerContainer } from "./CreateDrawer/Container";
import { UserDetailDrawerContainer } from "./DetailDrawer/Container";
import { AdminAccountsFilters } from "./Filters";
import { userRoles, userStatus } from "./const";
import classNames from "classnames";
import { UserFilterParams } from "src/services/admin/types";
import { PasswordValidationModal } from "src/shared/common/Password/Modal";
import { transactionServiceSlugImage } from "src/shared/const/transactions";
import { formatPhone } from "src/shared/utils/formatPhone";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getCreatedUser, getEditUser } from "src/modules/admin/userDetail/selectors";
import { handleChangeUserActiveStatus } from "src/modules/admin/userDetail/thunkActions";
import { getAdminAccountsList, getShowUnlinkedUserAlert } from "src/modules/admin/usersList/selectors";
import { setUserListPage, setUserListPageSize } from "src/modules/admin/usersList/slice";
import { fetchUsersList } from "src/modules/admin/usersList/thunkActions";
import { getSelectedCompanyCountryTag } from "src/modules/auth/selectors";
import { SubscriptionRestrictions, useSubscriptionRestrictions } from "src/shared/hooks/useSubscriptionRestrictions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Tag } from "src/shared/atoms/Tag/Tag";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Empty } from "src/shared/components/Empty/Empty";
import { PageHeader } from "src/shared/components/PageHeader/PageHeader";
import { SelectableTable, SelectableTableRow } from "src/shared/components/SelectableTable/SelectableTable";

import "./styles.scss";

const emptyListImg = require("src/shared/images/empty/empty-admin.svg").default;
const emptyArchivedImg = require("src/shared/images/empty/empty-user.svg").default;
const successImage = require("src/shared/images/user/success.png");

const defaultFilter: UserFilterParams = {
    page: 1,
    archived: false,
    query: "",
    role: [],
};

export const AdminUsersPanel = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { usersList, totalCount, loading, currentPage, pageSize } = useAppSelector((state) =>
        getAdminAccountsList(state)
    );
    const { error: editUserError } = useAppSelector((state) => getEditUser(state));
    const showUnlinkedUserAlert = useAppSelector((state) => getShowUnlinkedUserAlert(state));
    const {
        createdUser,
        loading: createUserLoading,
        error: createUserError,
    } = useAppSelector((state) => getCreatedUser(state));

    const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
    const [isOpenCreateDrawer, setIsOpenCreateDrawer] = useState(false);
    const [createCollector, setCreateCollector] = useState(false);
    const [params, setParams] = useState(defaultFilter);

    const [openPasswordValidationModal, setOpenPasswordValidationModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [selectedUsersToSwitchStatus, setSelectedUsersToSwitchStatus] = useState<number[]>([]);
    const [bulkAction, setBulkAction] = useState<"activate" | "inactive" | "delete" | null>(null);

    const companyCountryTag = useAppSelector(getSelectedCompanyCountryTag);
    const isCompanyRestrictedUser = useSubscriptionRestrictions(SubscriptionRestrictions.RESTRICTIONS_USER);

    useEffect(() => {
        fetchCurrentPage(currentPage);
        // eslint-disable-next-line
    }, [currentPage, params, pageSize]);

    useEffect(() => {
        if (createUserLoading === false && !createUserError && createdUser) {
            if (currentPage === 1) {
                fetchCurrentPage(currentPage);
                setSelectedUserId(createdUser?.id);
            } else {
                dispatch(setUserListPage({ currentPage: 1 }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createUserLoading]);

    const handleOpenDrawer = (id: number, status: string | undefined) => () => {
        if (status && status !== "archived") {
            setSelectedUserId(id);
        }
    };

    const handleCloseDrawer = () => {
        setSelectedUserId(null);
    };

    const fetchCurrentPage = (newCurrentPage: number) => {
        dispatch(
            fetchUsersList({
                ...params,
                page: newCurrentPage,
                pageSize,
            })
        );
    };

    const handleCloseCreateDrawer = () => {
        setIsOpenCreateDrawer(false);
        setCreateCollector(false);
    };

    const handleOpenCreateDrawer = () => {
        setIsOpenCreateDrawer(true);
    };

    const handleOpenCreateCollectorDrawer = () => {
        setIsOpenCreateDrawer(true);
        setCreateCollector(true);
    };

    const handlePageChange = (page: number) => {
        dispatch(setUserListPage({ currentPage: page }));
    };

    const handlePageSizeChange = (pageSize: number) => {
        dispatch(setUserListPageSize({ pageSize }));
    };

    const tableRows = usersList.map((account) => {
        const {
            id,
            avatar,
            firstname,
            lastname,
            role,
            phone,
            username,
            nbWallets,
            status,
            collectorInfo,
            customReference,
        } = account;

        return [
            {
                content: <Avatar avatar={avatar || undefined} firstname={firstname} lastname={lastname} />,
                type: "image",
                onClick: handleOpenDrawer(id, status),
            },
            {
                content: (
                    <div className='d-flex flex-column'>
                        <Typography className='fw-bold' message={phone ? formatPhone(phone) : username} />
                        <Typography className='fw-bold color-neutral-500' message={firstname + " " + lastname} />
                    </div>
                ),
                type: "text",
                onClick: handleOpenDrawer(id, status),
            },
            {
                content: <Typography className='fw-bold' message={userRoles[role]} />,
                text: "text",
                onClick: handleOpenDrawer(id, status),
            },
            {
                content: <Typography className='fw-bold' message={customReference || "-"} />,
                type: "text",
                onClick: handleOpenDrawer(id, status),
            },
            {
                content: collectorInfo?.services ? (
                    <div className='d-flex gap-2 justify-content-center'>
                        {collectorInfo.services.map((service) => (
                            <Avatar key={service} avatar={transactionServiceSlugImage[service]} size='sm' />
                        ))}
                    </div>
                ) : (
                    <Icon color='neutral-500' name='x-circle' size='sm' />
                ),
                type: "text",
                onClick: handleOpenDrawer(id, status),
            },
            {
                content: (
                    <div className='wallet-numbers'>
                        <Typography className='fw-bold mx-auto' message={role === "READ" ? "-" : nbWallets} />
                    </div>
                ),
                type: "text",
                onClick: handleOpenDrawer(id, status),
            },
            {
                content: (
                    <Tag
                        type='accent'
                        size='lg'
                        label={status && userStatus[status] ? userStatus[status].text : ""}
                        icon={status && userStatus[status] && userStatus[status].icon}
                        color={status && userStatus[status] && userStatus[status].color}
                    />
                ),
                type: "text",
                onClick: handleOpenDrawer(id, status),
            },
        ];
    });

    const handleSelectUsersToSwitchStatus = (action: "activate" | "inactive") => {
        const selectedUsers = selectedRows.map((index) => usersList[index]);
        const usersToSwitchStatus = selectedUsers
            .filter((user) => {
                if (action === "activate") {
                    return user.status === "inactive";
                }
                if (action === "inactive") {
                    return user.status === "active";
                }
                return false;
            })
            .map((user) => user.id);

        setSelectedUsersToSwitchStatus(usersToSwitchStatus);
    };

    const handleBulkAction = (action: "activate" | "inactive" | "delete") => {
        setBulkAction(action);
        if (action === "activate" || action === "inactive") {
            handleSelectUsersToSwitchStatus(action);
        }

        switch (action) {
            case "activate":
            case "inactive":
                handleOpenPasswordValidationModal();
                break;
            case "delete":
                const usersToDelete = selectedRows.map((index) => usersList[index].id);
                console.log(usersToDelete);
                break;
            default:
                break;
        }
    };

    const handleChangeUserStatus = async (password: string, otp: string | null) => {
        await dispatch(
            handleChangeUserActiveStatus({
                id: selectedUsersToSwitchStatus,
                data: {
                    password,
                    otp,
                },
            })
        ).then((res: any) => {
            if (!res.error) {
                setShowSuccess(true);
                setSelectedUsersToSwitchStatus([]);
            }
        });
    };

    const handleOpenPasswordValidationModal = () => {
        setShowSuccess(false);
        setOpenPasswordValidationModal(!openPasswordValidationModal);
    };

    const handleOnSuccess = () => {
        fetchCurrentPage(currentPage);
        setSelectedRows([]);
    };

    const handlePasswordValidationSubmit = async ({
        password,
        twoFactor,
    }: {
        password: string;
        twoFactor: string | null;
    }) => {
        await handleChangeUserStatus(password, twoFactor);
    };

    const isAnyInactiveSelected = selectedRows.some((index) => usersList[index].status === "inactive");
    const isAnyActiveSelected = selectedRows.some((index) => usersList[index].status === "active");

    return (
        <>
            <div className='admin-account-container'>
                <PageHeader title={t<string>("Users.list")}>
                    {showUnlinkedUserAlert ? (
                        <div className='mb-3'>
                            <Alert color='error' message={t("Users.unlinked-user-alert")} />
                        </div>
                    ) : null}

                    <div className='d-flex justify-content-between'>
                        <AdminAccountsFilters filters={params} setFilters={setParams} />
                        <div className='d-flex gap-2 ml-2'>
                            {companyCountryTag !== "TGO" && (
                                <Button
                                    disabled={isCompanyRestrictedUser}
                                    onClick={handleOpenCreateCollectorDrawer}
                                    variant='secondary'
                                    label={t("Users.add-a-collector")}
                                />
                            )}
                            <ToolTip
                                position='bottom'
                                content={
                                    <div className='tooltip-limited'>
                                        <Typography
                                            message={t("Subscription.not-accessible-feature")}
                                            className='fw-bold'
                                        />
                                        <Typography message={t("Subscription.unlock-unlimited-access")} />
                                    </div>
                                }
                                activation={isCompanyRestrictedUser}
                            >
                                <Button
                                    disabled={isCompanyRestrictedUser}
                                    onClick={handleOpenCreateDrawer}
                                    color='primary'
                                    label={t("UserCreateDrawerContainer.invite-a-user")}
                                />
                            </ToolTip>
                        </div>
                    </div>
                </PageHeader>

                <SelectableTable
                    selectable
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    selectedBar={{
                        itemName: t<string>("Users.list"),
                        buttonActions: {
                            content: {
                                header: {
                                    title: t<string>("CommonUse.multiple-action"),
                                    icon: "squares",
                                },
                                dropDownContent: {
                                    body: (
                                        <>
                                            <div
                                                className={classNames("selectable-bar-actions", {
                                                    disabled: !isAnyInactiveSelected,
                                                })}
                                                onClick={() =>
                                                    isAnyInactiveSelected ? handleBulkAction("activate") : undefined
                                                }
                                            >
                                                <Icon name='check-circle' />
                                                <Typography
                                                    className='fw-bold'
                                                    message={t("Users.activate-collector")}
                                                />
                                            </div>
                                            <div
                                                className={classNames("selectable-bar-actions", {
                                                    disabled: !isAnyActiveSelected,
                                                })}
                                                onClick={() =>
                                                    isAnyActiveSelected ? handleBulkAction("inactive") : undefined
                                                }
                                            >
                                                <Icon name='forbidden' />
                                                <Typography className='fw-bold' message={t("CommonUse.deactivate")} />
                                            </div>
                                            {/* <div
                                                className='selectable-bar-actions'
                                                onClick={() => handleBulkAction("delete")}
                                            >
                                                <Icon name='thrash' />
                                                <Typography message={t("CommonUse.delete")} />
                                            </div> */}
                                        </>
                                    ),
                                },
                            },
                        },
                    }}
                    isLoading={loading}
                    pagination={{
                        currentPage,
                        pageSize: pageSize,
                        totalCount,
                        onPageChange: handlePageChange,
                        onPageSizeChange: handlePageSizeChange,
                    }}
                    header={[
                        "",
                        t("Users.user"),
                        t("CommonUse.role"),
                        t("Users.user-reference"),
                        <Icon color='black' name='kiosk' />,
                        <Icon color='black' name='wallet' />,
                        t("CommonUse.statut"),
                    ]}
                    empty={
                        params !== defaultFilter ? (
                            <Empty
                                image={emptyArchivedImg}
                                title={t("Table.no-result-found")}
                                description={t<string>("Table.no-result-found-description")}
                            />
                        ) : (
                            <Empty
                                image={emptyListImg}
                                title={t("Table.welcome-to-julaya")}
                                description={t<string>("Table.welcome-to-julaya-description")}
                                button={
                                    <ToolTip
                                        position='right'
                                        content={
                                            <div className='tooltip-limited'>
                                                <Typography
                                                    message={t("Subscription.not-accessible-feature")}
                                                    className='fw-bold'
                                                />
                                                <Typography message={t("Subscription.unlock-unlimited-access")} />
                                            </div>
                                        }
                                        activation={isCompanyRestrictedUser}
                                    >
                                        <Button
                                            disabled={isCompanyRestrictedUser}
                                            onClick={handleOpenCreateDrawer}
                                            label={t("CommonUse.add", { entity: t("Users.a-user") })}
                                            icon='plus-circle'
                                        />
                                    </ToolTip>
                                }
                            />
                        )
                    }
                    rows={tableRows as SelectableTableRow[][]}
                />
            </div>
            <UserDetailDrawerContainer
                selectedUserId={selectedUserId}
                onCloseDrawer={handleCloseDrawer}
                params={params}
            />
            <UserCreateDrawerContainer
                isOpen={isOpenCreateDrawer}
                onCloseDrawer={handleCloseCreateDrawer}
                createCollector={createCollector}
            />
            <PasswordValidationModal
                header={{
                    title: bulkAction === "inactive" ? t("Users.deactivate-users") : t("Users.activate-user"),
                    icon: bulkAction === "inactive" ? "forbidden" : "check-circle",
                }}
                successContent={{
                    text: t<string>("PasswordModal.validated-demand"),
                    image: successImage,
                }}
                alert={{
                    message: t(
                        bulkAction === "inactive"
                            ? "Users.you-are-deactivating-x-users"
                            : "Users.you-are-activating-x-users",
                        {
                            count: selectedUsersToSwitchStatus.length,
                        }
                    ),
                    color: "warning",
                    icon: "exclamation-circle",
                }}
                setOpen={handleOpenPasswordValidationModal}
                error={editUserError}
                asyncFromParent
                succeeded={showSuccess}
                onSuccess={handleOnSuccess}
                open={!!openPasswordValidationModal}
                handleOnSubmit={handlePasswordValidationSubmit}
            />
        </>
    );
};
