import { API_URL, APP_VERSION, SECRET_TOKEN } from "./const";
import { callApiWithErrorHandling } from "./errorHandling";
import { MiddlewareRequestHeader } from "./types";
import { sha256 } from "js-sha256";
import { getInStorage } from "src/shared/utils/storage";

type FileUploaderProps = {
    file?: File;
    recto?: File;
    verso?: File;
    avatar?: File;
    type?: string;
    slug?: string;
    group?: string;
};

export async function fileUploader<T>({
    endpoint,
    params,
}: {
    endpoint: string;
    params: FileUploaderProps;
}): Promise<T> {
    const formData = new FormData();
    const headers: MiddlewareRequestHeader = {
        "x-secret-token": SECRET_TOKEN,
        "App-Version": APP_VERSION,
    };

    Object.entries(params).forEach(([key, value]) => formData.append(key, value));

    const accessToken = getInStorage("accessToken");
    const currentCompanyId = getInStorage("currentCompanyId");

    if (accessToken !== null && accessToken.userId && currentCompanyId) {
        headers.Authorization = `Bearer ${accessToken.id}`;
        headers["x-current-company"] = String(currentCompanyId);
        headers["x-signature"] = sha256.hmac(accessToken.id, "{}" + accessToken.userId + endpoint);

        try {
            let res = await callApiWithErrorHandling(fetch, `${API_URL}${endpoint}`, {
                method: "POST",
                body: formData,
                headers,
            });
            return await res.json();
        } catch (error) {
            return Promise.reject(error);
        }
    } else {
        throw new Error("Veuillez vous identifier pour continuer");
    }
}
